import React, { useState } from "react";
import { connect } from 'react-redux';
import axios from 'axios';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  FormGroup,
  Label,
  Row,
  Modal, ModalHeader, ModalBody, ModalFooter, Col
  
} from "reactstrap";

import { registerBooking } from './../../actions/booking';

import Calendar1 from "views/Form/Calendar1";
import { callError } from "actions/common";
import { callSuccess } from "actions/common";
import { SERVER_PATHS } from "actions/common";
import { axiosConfig1 } from "actions/common";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";


const locs ={
  1:'Kainji',
  2:'Jebba'
}
// core components

function SignUp(props) {
  const [fullnameFocus, setFullnameFocus] = React.useState(false);
  const [fullname, setFullname] = React.useState('');
  const [role, setRole] = React.useState('');
  const [roleFocus, setRoleFocus] = React.useState(false);
  const [phoneFocus, setPhoneFocus] = React.useState(false);
  const [phone, setPhone] = React.useState('');
  const [emailFocus, setEmailFocus] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [idtypeFocus, setIdtypeFocus] = React.useState(false);
  const [idtype, setIdtype] = React.useState('');
  const [idnumberFocus, setIdnumberFocus] = React.useState(false);
  const [idnumber, setIdnumber] = React.useState('');
  const [locationid, setlocationid] = React.useState(1);
  const [pst, setPst] = React.useState(false);
  const [dates, setDates] = React.useState([]);
  const [page, setpage] = useState(0)
  const [others, setothers] = useState([])


  const [fname, setfname] = useState('')
  const [fid, setfid] = useState(null)
  const [femail, setfemail] = useState(null)
  const [fphone, setfphone] = useState(null)
  const [foffice, setfoffice] = useState()
  const [fcategory, setfcategory] = useState()
  const [modal, setmodal] = useState(false)
  const [modal1, setmodal1] = useState(false)

  const toggleModal = ()=> setmodal(prev=>!prev)
  const toggleModal1 = ()=> setmodal1(prev=>!prev)

  const resetdata = () =>{
    setfid(null)
    setfname('')
    setfemail('')
    setfphone('')
    setfcategory('')
    setfoffice('')
  }
  
 const handleClose = () =>{
    setPhone('');
    setDates([]);
    setFullname('');
    setlocationid(1);
    setEmail('');
    setIdtype('');
    setIdnumber('');
 }
  const handleSubmit = () =>{
      let er = [];
      let i = fullname && fullname.length > 0 ? null : er.push(0);
      i = phone && phone.length > 0 ? null : er.push(0);
      i = email && email.length > 0 ? null : er.push(0);
      i = dates && dates.length > 0 ? null : er.push(0);
      i = idnumber && idnumber.length > 0 ? null : er.push(0);
      
      if(er.length == 0)
      {
        let fd = new FormData();
        fd.append('fullname', fullname);
        fd.append('phone', phone);
        fd.append('dates', JSON.stringify(dates));
        fd.append('email', email);
        fd.append('idtype', idtype);
        fd.append('idnumber', idnumber);
        fd.append('locationid', locationid);
        fd.append('location', locs[locationid]);
        fd.append('cat', 'insert_booking_single');
        fd.append('table', 'bookings')

        props.registerBooking(fd);
        handleClose();
        callSuccess('Thank you . Please check your email to confirm your request has been registered');
      }else{
        callError('Incomplete information')
      }
      setFullnameFocus(true);
      setFullname('');
      setFullnameFocus(false);
      setPhone('');
      setEmail('')
  }
  const handleSubmitGroup = () =>{
    let er = [];
    if(fullname && fullname.length > 0){}else{er.push('name')}
    if(phone && phone.length > 0){}else{er.push('Phone number')}
    if(email && email.length > 0){}else{er.push(' Email')}
    if(dates && dates.length > 0){}else{er.push('no dates selected')}
    if(idnumber && idnumber.length > 0){}else{er.push(0)}
    if(Array.isArray(Object.keys(others)) && Object.keys(others).length > 0){}else{er.push('no names recorded')}
    
    if(er.length == 0)
    {
      let fd = new FormData();
      fd.append('fullname', fullname);
      fd.append('phone', phone);
      fd.append('dates', JSON.stringify(dates));
      fd.append('email', email);
      fd.append('idtype', idtype);
      fd.append('idnumber', idnumber);
      fd.append('role', role);
      fd.append('locationid', locationid);
      fd.append('location', locs[locationid]);
      fd.append('groups', JSON.stringify(others));
      fd.append('cat', 'insertBooking');
      fd.append('table', 'bookings')

      axios.post(SERVER_PATHS, fd, axiosConfig1)
      .then(res=>{return true;})
      .then(res=>{
        handleClose();
        callSuccess('Thank you . Please check your email to confirm your request has been registered');
      })
      .catch(err=>{callError('Network/Server Error')})
    }else{
      callError(`Incomplete information: ${er.join()}`)
    }
    setFullnameFocus(true);
    setFullname('');
    setFullnameFocus(false);
    setPhone('');
    setEmail('')
  }
  const viewPersons = () =>toggleModal1()
  const addPerson = () =>{
    resetdata()
    toggleModal()
  }
  const savePerson = () =>{
    let f = {...others}
    let id = Math.floor(Math.random() * 12345567855)
    let rw = {}
    if(typeof fname == 'string' && 
      fname.length > 2 && 
      typeof fcategory == 'string' && 
      fcategory.length > 2  ){
      rw.id = id
      rw.name = fname
      rw.office = foffice
      rw.email = femail
      rw.phone = fphone
      rw.category = fcategory
      f[id] = rw 
      setothers(f)
      setmodal(false)
    }else{
      callError('No Name or Category')
    }
   
  }

  const updatePerson = () =>{
    let f = {...others}
    let id = fid
    let rw = {}
    if(typeof fname == 'string' && 
      fname.length > 2 && 
      typeof fcategory == 'string' && 
      fcategory.length > 2  ){
      rw.name = fname
      rw.office = foffice
      rw.email = femail
      rw.phone = fphone
      rw.category = fcategory
      f[id] = rw 
      setothers(...others, f)
    }else{
      callError('No Name or Category')
    }
  }

  const editPerson = (fid) =>{
    let oth = {...others}
    let id = fid.id
    if(oth.hasOwnProperty(id))
    {
      let rw = oth[id]
      let {id, name, email, phone, category, office} = rw || ''
      setfid(id)
      setfname(name)
      setfemail(email)
      setfphone(phone)
      setfcategory(category)
      setfoffice(office)
      setmodal(false)
      toggleModal()
    }else{
      callError(JSON.stringify(fid))
    }
  }

  const removePerson = () =>{
    let oth = others
    let id = fid
    if(oth.hasOwnProperty(id))
    {
      delete oth[id];
      setothers(oth)
    }else{ callError('Error')}
  }
  
  return (
    <>
     <Modal isOpen={modal} fade  style={{backgroundColor:'transparent !important'}} backdrop='static' keyboard={false}>
        <ModalHeader toggle={resetdata}><i className='fa fa-lock'></i> Add </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Col sm={12}>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text"><i className="fa fa-user"></i></div>
                    </div>
                    <Input 
                      type="text" 
                      name="fname" 
                      id="fname" 
                      placeholder='Employment ID' 
                      required
                      defaultValue={fname}
                      onChange={e=>setfname(e.target.value)} 
                    />
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text"><i className="fa fa-user"></i></div>
                    </div>
                    <select 
                      type="text" 
                      name="fcategory" 
                      id="fcategory" 
                      placeholder='Employment ID' 
                      required
                      defaultValue={fcategory}
                      onChange={e=>setfcategory(e.target.value)} 
                    >
                      <option></option>
                      <option>Executive</option>
                      <option>Top Management</option>
                      <option>Mid Management</option>
                      <option>Standard</option>
                      </select>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text"><i className="fa fa-user"></i></div>
                    </div>
                    <Input 
                      type="text" 
                      name="foffice" 
                      id="foffice" 
                      placeholder='Office/Role' 
                      required
                      defaultValue={foffice}
                      onChange={e=>setfoffice(e.target.value)} 
                    />
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text"><i className="fa fa-telephone"></i></div>
                    </div>
                    <Input 
                      type="text" 
                      name="fphone" 
                      id="fphone" 
                      placeholder='Phone Number' 
                      required
                      defaultValue={fphone}
                      onChange={e=>setfphone(e.target.value)} 
                    />
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text"><i className="fa fa-envelope"></i></div>
                    </div>
                    <Input 
                      type="text" 
                      name="femail" 
                      id="femail" 
                      placeholder='email Number' 
                      required
                      defaultValue={femail}
                      onChange={e=>setfemail(e.target.value)} 
                    />
                  </div>
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={resetdata}>Cancel</Button> {' '}
          {parseInt(fid) > 0 ?
          <Button color='success' onClick={updatePerson}>Update</Button>:
          <Button color='info' onClick={savePerson}>Save</Button>}
        </ModalFooter>
    </Modal>
    <Modal isOpen={modal1} fade  style={{backgroundColor:'transparent !important'}} backdrop='static' keyboard={false}>
        <ModalHeader toggle={resetdata}><i className='fa fa-lock'></i> Recorded </ModalHeader>
         <ModalBody>
           <table width="100%" style={{fontSize:"9px", fontWeight:'bolder'}}>
             <thead>
               <tr>
                 <th>SN</th>
                 <th>Fullname</th>
                 <th>Category</th>
                 <th>Phone</th>
                 <th>Email</th>
                 <th>Office</th>
                 <th>Action</th>
               </tr>
             </thead>
             <tbody>
               {
                Array.isArray(Object.keys(others)) ?
                Object.keys(others).map((props , indx)=>{
                   let rw = others[props]
                   let {id, name, office, category, email, phone} = rw || ''
                  return <tr key={props}>
                  <th style={{textAlign:'center', width:'10%'}}>{indx + 1}</th>
                  <th>{name}</th>
                  <th>{category}</th>
                  <th>{phone}</th>
                  <th>{email}</th>
                  <th>{office}</th>
                  <th style={{display:'flex', margin:'0px'}}>
                      <span className="btn btn-xs" onClick={()=>editPerson(rw)}>
                        <i className="fa fa-edit"></i></span>
                      <span className="btn btn-xs" onClick={()=>removePerson(rw)}>
                        <i className="fa fa-remove"></i>
                      </span>
                  </th>
                </tr>
                })
                : null
               }
             </tbody>
           </table>
         </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={()=>toggleModal1()}>Cancel</Button> {' '}
          <Button color='info' onClick={addPerson}>Add</Button>
        </ModalFooter>
    </Modal>

    {pst? <Calendar1   
        st={pst}
        mid={1}
        handleBooking ={(dat)=>setDates(dat)}
        handleClose={()=>setPst(false)}
    />:''}
      <div
      id="booking"
        className="section section-signup"
        style={{
          backgroundImage: "url(" + require("assets/img/bg11.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          minHeight: "700px",
        }}
      >
        <Container>
          <Row>
            <Card className="card-signup col-md-4 mx-auto" data-background-color="blue">
              <Form action="" className="form" method="">
                <CardHeader className="text-center">
                  <CardTitle className="title-up" tag="h3">
                   Guest Booking
                  </CardTitle>
                </CardHeader>
                
               
                <>
                  <CardBody style={{fontWeight:'bolder', marginTop:'1px', marginBottom:'1px', paddingTop:'1px', paddingBottom:'1px' }}>
                  <FormGroup>
                      <div class="col">
                        <select 
                        type="text" 
                        value={locationid} 
                        onChange={(e)=>setlocationid(e.target.value)} 
                        class="form-control" 
                        placeholder="locationid"
                        >
                            <option style={{color:'black'}} >Select Location</option>
                            <option style={{color:'black'}} value={2}>Jebba</option>
                            <option style={{color:'black'}}  value={1}>Kainji</option>
                            
                        </select>
                      </div>
                      </FormGroup>
                  <InputGroup
                      className={
                        "no-border" + (fullnameFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText
                        style={{fontWeight:'bold', color:'#fff', fontSize:'1rem'}}
                        
                        >
                          <i className="now-ui-icons text_caps-small"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Fullname ..."
                        style={{fontWeight:'bold', color:'#fff', fontSize:'1rem'}}
                        type="text"
                        value={fullname}
                        onFocus={() => setFullnameFocus(true)}
                        onBlur={() => setFullnameFocus(false)}
                        onChange={(e) => setFullname(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border" + (phoneFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons text_caps-small"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Phone Number..."
                        style={{fontWeight:'bold', color:'#fff', fontSize:'1rem'}}
                        type="text"
                        value={phone}
                        onFocus={() => setPhoneFocus(true)}
                        onBlur={() => setPhoneFocus(false)}
                        onChange={(e) => setPhone(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border" + (emailFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons ui-1_email-85"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email..."
                        style={{fontWeight:'bold', color:'#fff', fontSize:'1rem'}}
                        type='email'
                        value={email}
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                        onChange={(e) => setEmail(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border" + (idnumberFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons ui-1_email-85"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Staff ID"
                        style={{fontWeight:'bold', color:'#fff', fontSize:'1rem'}}
                        type="text"
                        value={idnumber}
                        onFocus={() => setIdnumberFocus(true)}
                        onBlur={() => setIdnumberFocus(false)}
                        onChange={(e) => setIdnumber(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border" + (idtypeFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons ui-1_email-85"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Organization..."
                        style={{fontWeight:'bold', color:'#fff', fontSize:'1rem'}}
                        type="text"
                        value={idtype}
                        onFocus={() => setIdtypeFocus(true)}
                        onBlur={() => setIdtypeFocus(false)}
                        onChange={(e) => setIdtype(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <FormGroup>
                      <InputGroup
                     className={
                       "no-border" + (phoneFocus ? " input-group-focus" : "")
                     }
                   >
                     <InputGroupAddon addonType="prepend">
                       <InputGroupText>
                         <i className="now-ui-icons text_caps-small"></i>
                       </InputGroupText>
                     </InputGroupAddon>
                     <Input
                       placeholder="Role/Office..."
                       style={{fontWeight:'bold', color:'#fff', fontSize:'1rem'}}
                       type="text"
                       value={role}
                       onFocus={() => setRoleFocus(true)}
                       onBlur={() => setRoleFocus(false)}
                       onChange={(e) => setRole(e.target.value)}
                     ></Input>
                    </InputGroup>
                    </FormGroup>

                  </CardBody>
                </>
                 <CardBody style={{ marginBottom:'1px',  paddingBottom:'1px' }}>
                    <h6 style={{display:'block', color:'#000', }}>
                      {dates.length === 1 ? new Date(dates).toDateString() : ''} 
                      {dates.length > 1 ? dates.map(prp=>{return new Date(prp).toDateString() + ' | '}):''}
                    </h6>
                    <FormGroup style={{display:'block'}}>
                      <Label style={{color:'#fff', fontWeight:'bolder'}} >Select Date</Label>
                      <p>
                      <DatePicker
                      
                        style={{
                          maxWidth:"340px",
                          minWidth:"320px",
                          display:'block',
                          padding:'5px',
                          height:'35px',
                          borderRadius:'15px'
                        }}
                        multiple
                        autoFocus
                        value={dates}
                        onChange={(e)=>setDates(e)}
                        plugins={
                          [
                            <DatePanel />
                          ]
                        }
                      />
                      </p>
                   </FormGroup>
                   <Button
                     className="btn-sm btn-danger btn-round btn-block"
                     color="info"
                     href="#pablo"
                     onClick={() =>handleSubmit()}
                     size="lg"
                   >
                   Click to Book
                   </Button>
                   <p style={{display:'block', padding:'auto'}}>OR</p>
                   <a
                     className="btn-sm btn-danger btn-round btn-block m-2"
                     color="info"
                     href={process.env.PUBLIC_URL+"/guestmultiple"}
                     
                     size="lg"
                   >
                   Book For Multiple Clients
                   </a>
                      
                 </CardBody>
                 
               
              </Form>
            </Card>
          </Row>
         
        </Container>
      </div>
    </>
  );
}


const mapStateToProps = (state, ownProps) => ({ 
  
})

export default connect(mapStateToProps, {registerBooking})(SignUp)

