export const USERCATEGORY_GET_MULTIPLE =  "USERCATEGORY_GET_MULTIPLE"
export const USERCATEGORY_GET_ONE =  "USERCATEGORY_GET_ONE"
export const USERCATEGORY_REGISTER_SUCCESS =  "USERCATEGORY_REGISTER_SUCCESS"
export const USERCATEGORY_REGISTER_FAIL =  "USERCATEGORY_REGISTER_FAIL"
export const USERCATEGORY_LOADING =  "USERCATEGORY_LOADING"
export const USERCATEGORY_LOADING_ERROR =  "USERCATEGORY_LOADING_ERROR"
export const USERCATEGORY_ACTIVATE_FAIL =  "USERCATEGORY_ACTIVATE_FAIL"
export const USERCATEGORY_ACTIVATE_SUCCESS =  "USERCATEGORY_ACTIVATE_SUCCESS"
export const USERCATEGORY_UPDATE_SUCCESS =  "USERCATEGORY_UPDATE_SUCCESS"
export const USERCATEGORY_UPDATE_FAIL =  "USERCATEGORY_UPDATE_FAIL"
export const USERCATEGORY_DELETE_SUCCESS =  "USERCATEGORY_DELETE_SUCCESS"
export const USERCATEGORY_DELETE_FAIL =  "USERCATEGORY_DELETE_FAIL"
export const USERCATEGORY_EDIT =  "USERCATEGORY_EDIT"