import React from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import { Link, Route } from "react-router-dom";
import ReactTooltip  from 'react-tooltip';
import { getUserLogout, changeLocation, updateUser } from "./../../actions/user";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavbarToggler,
  Nav,
  Container,
} from "reactstrap";

function ExamplesNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-light");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const logOut = () =>{
      props.getUserLogout();
  }
 
  const sidebarToggle = React.useRef();
  const toggle = () => {
    setIsOpen(!isOpen)
  };
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-light");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  };
  const clearNotification = () =>{
        let fd = new FormData();
        fd.append('dates', moment(new Date()).format('YYYY-MM-DD hh:mm:ss'));
        fd.append('id', props.user.user.id);
        fd.append('cat', 'update');
        fd.append('table', 'user_types');
        fd.append('rowzid', 5);
        props.updateUser(fd);
  }
    let dates = props.client.clients && props.client.clients.length > 9 ? props.client.clients : [{'id':0},{'id':0},{'id':0},{'id':0},{'id':0},{'id':0},{'id':0},{'id':0},{'id':0},{'id':0},{'id':0},{'id':0}] ;
    let datesuser = props.user.users && props.user.users.length > 2 ? props.user.users : [{'id':0},{'id':0},{'id':0},{'id':0},{'id':0},{'id':0},{'id':0},{'id':0},{'id':0},{'id':0},{'id':0},{'id':0}] ;  
    let sum_1_room = parseInt(dates[1].id);
    let sum_3_booking = parseInt(dates[3].id);
    let sum_5_comment = parseInt(dates[5].id);
    let sum_7_inventory = parseInt(dates[7].id);
    let sum_9_maintenance = parseInt(dates[9].id);
    let sum_0_user_types = parseInt(datesuser[0].id);
    let sum_1_user_trans = parseInt(datesuser[1].id);
    let sum_2_user_leave = parseInt(datesuser[2].id);
    let allNotification = sum_1_room + sum_3_booking + sum_5_comment + sum_7_inventory + sum_9_maintenance + sum_0_user_types + sum_1_user_trans + sum_2_user_leave;
    
    let {access} = props.user.user || {};
    let isAdmin = props.user.isAdmin;
    let acc = access !== undefined & access !== '' ? JSON.parse(access) : [[], [], [], [], [], []] ;

    if(!props.user.isAuthenticated)
  {
      logOut();
      return <Route to='/'/>;
  }
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " } color={props.backgroundColo} style={{color:'#00168b', backgroundColor:'#001f2b'}} expand="lg">
            <ReactTooltip id='noti' type='dark'>
                    <span>Clear notifications</span>
            </ReactTooltip>
            <ReactTooltip id='loca' type='dark'>
                    <span>Change Guest House</span>
            </ReactTooltip>
            <ReactTooltip id='prof' type='dark'>
                    <span>
                      Personal profile
                    </span>
            </ReactTooltip>
        <Container fluid>
        <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref={sidebarToggle}
                className="navbar-toggler"
                onClick={() => openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
           
          </div>
          
           
          <div className="navbar-translate">
            <NavbarBrand
              href="#"
              target="_blank"
              id="navbar-brand"
              style={{fontFamily:'Josefin sans', fontSize:'1.3em', textTransform:'capitalize'}}
            >
             Welcome {props.user.user.surname} !
            </NavbarBrand>  
            <NavbarToggler onClick={toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          </div>
          
          <Collapse
            className="justify-content-end"
            isOpen={isOpen}
            navbar
          >
            <Nav navbar>
              <form class="form-inline my-0 py-0 ml-auto">
                <div class="form-group no-border">
                  <input type="text" style={{height:'25px'}} class="form-control form-control-sm" placeholder="Search"/>
                </div>
               
              </form>
              <li class="nav-item ">
                <a class="nav-link" data-tip data-for='noti' onClick={()=>clearNotification()} >
                 <i className='now-ui-icons ui-1_bell-53 '></i><span className='sr-only'>User</span>
                 {parseInt(allNotification) > 0 ? 
                 <span 
                  style={{position:'relative'}} className='badge badge-pill badge-danger'>New</span>:''}
                </a>
                </li> 
                {isAdmin || ['0'].some(el=>acc[0].includes(el)) ?
              <li class="nav-item dropdown  dropleft">
                <a class="nav-link dropdown-toggle" data-tip data-for='loca' href="#" id="navbarDropdownMenuLink1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                 <i className='now-ui-icons business_globe text-light'></i><span className='sr-only'>Location</span>
                </a> 
                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink1">
                  <a class="dropdown-item text-dark" href="#" onClick={()=>props.changeLocation(1)}><i className='now-ui-icons business_globe'></i> Kainji</a>
                  <a class="dropdown-item text-dark" href="#" onClick={()=>props.changeLocation(2)}><i className='now-ui-icons business_globe'></i> Jebba</a>
                  <a class="dropdown-item text-dark" href="#" onClick={()=>props.changeLocation(3)}><i className='now-ui-icons business_globe'></i> Unified</a>
                </div>
              </li>:''}
             
               <li class="nav-item dropdown dropleft">
                <a class="nav-link dropdown-toggle" href="#" data-tip data-for='prof' id="navbarDropdownMenuLink1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                 <i className='now-ui-icons users_single-02 '></i><span className='sr-only'>User</span>
                </a>
                <div class="dropdown-menu text-dark" aria-labelledby="navbarDropdownMenuLink1">
                  <Link class="dropdown-item text-dark" to={`/account/userm`}><i className='now-ui-icons users_single-02'></i> Profile</Link>
                  <a class="dropdown-item text-dark" onClick={logOut}><i className='now-ui-icons users_single-02'></i> Logout</a>
                </div>
              </li>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({ 
  user: state.userReducer,
  client: state.clientReducer
})
  
export default connect(mapStateToProps, {getUserLogout, changeLocation, updateUser})(ExamplesNavbar)