import React from "react";
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import {getMaintenancetransactions, getMaintenancetransaction, registerMaintenancetransaction, updateMaintenancetransaction, deleteMaintenancetransaction } from './../../actions/maintenancetransaction';
import { getMaintenancecategorys } from './../../actions/maintenancecategory';
import { getMaintenancetypes } from './../../actions/maintenancetype';
import $ from "jquery";
import { alllocations,  } from "./../../actions/common";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Select  from 'react-select'
import FormMaintenanceTransaction from "views/Form/FormMaintenanceTransaction1";

const piority = {
    1:'Low',
    2:'Normal',
    3:'High',
    4:'Emergency',
    null:'All'
};
const piorityColor = {
    1:'secondary',
    2:'warning',
    3:'primary',
    4:'danger'
};
const stateColor = {
    0:'danger',
    1:'success'
};
const stater = [
    {'value':'0', 'label':'Not completed'},
    {'value':'1', 'label':'Completed'},
    {'value':'2', 'label':'Pending'},
    {'value':null, 'label':'All'},
];
class BottomCard extends React.Component {
   constructor(props){
       super(props);
       this.state ={
           id:null,
           cat:null,
            grp:null,
            statusx:null,
            statex:null,
            afid:false,
            tfid:false,
            cfid:false,
            mfid:false,
            ifid:false,
            mid:null,
            fid:false,
            data:{},
            subtopic:'All Categories',
            started:new Date(),
            ended:new Date(),
            daterange:'',
            isshown:{}

       }
   }

    componentDidMount(){
        let re = {
            data:JSON.stringify({}),
            cat:'maintenanceall',
            table:'maintenance_types'
        };
        
        //GET PROPS
        let id = this.props.id;
        let cat = this.props.cat && parseInt(this.props.cat) > 0 ? parseInt(this.props.cat) : 2;
        let dt = new Date();
	    let started  = new Date(dt.getFullYear(), dt.getMonth(), 1);
        let ended = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
        let daterange = ' '+moment(started).format('MMMM Do YYYY') + ' - ' + moment(ended).format('MMMM Do YYYY');
        
	    this.setState({
             id:id,
             cat:cat,
	         started: moment(started).format("YYYY-MM-DD"),
             ended: moment(ended).format("YYYY-MM-DD"),
             daterange:daterange
	    })
            
        
        //GET ALL CATEGORYS
        let res = {};
        let ctn = '';
        let params = {};
        if(parseInt(id) > 0)
        {
            if(parseInt(cat) === 1)
            {
                res = {
                    'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                    'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                    'locationid':this.props.user.location,
                    'categoryid':id
                };
                let catname = this.props.maintenancetypes.maintenancetypes && Array.isArray(this.props.maintenancetypes.maintenancetypes) && this.props.maintenancetypes.maintenancetypes.length > 0 ? this.props.maintenancecategorys.maintenancecategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''}
                ctn = catname !== undefined ? catname.name :'None';
                 params = {
                    data:JSON.stringify(res),
                    cat:'maintenancetransaction',
                    table:'maintenance_transactions'
                 }
            }
            else if(parseInt(cat) === 2){
                let aatname = this.props.maintenancetypes.maintenancetypes && Array.isArray(this.props.maintenancetypes.maintenancetypes) && this.props.maintenancetypes.maintenancetypes.length > 0 ? this.props.maintenancetypes.maintenancetypes.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''}
                let atnid = aatname !== undefined ? aatname.categoryid :null;
                let atnm = aatname !== undefined ? aatname.name :null;
                let catname = atnid !== null && parseInt(atnid) > 0 && this.props.maintenancecategorys.maintenancecategorys && Array.isArray(this.props.maintenancecategorys.maintenancecategorys) && this.props.maintenancecategorys.maintenancecategorys.length > 0 ? this.props.maintenancecategorys.maintenancecategorys.filter(rw=>parseInt(rw.id) === parseInt(atnid))[0] : {'name':''} 
                let ctnid = catname !== undefined ? catname.name :null;
                ctn = ctnid +' '+atnm;
                res = {
                    'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                    'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                    'locationid':this.props.user.location,
                    'maintenanceid':id
                };
                
                params = {
                    data:JSON.stringify(res),
                    cat:'maintenancetransaction',
                    table:'maintenance_transactions'
                    }
            }
        }else if(id = 'all'){
            res = {
                    'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                    'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                    'locationid':this.props.user.location
                };
            ctn = 'Maintenance Issues List';
            params = {
                data:JSON.stringify(res),
                cat:'maintenancetransaction',
                table:'maintenance_transactions'
            }
        }
        this.setState({subtopic:ctn});
        this.props.getMaintenancetransactions(params);
        $('#myTablex').DataTable().destroy();
        $('#myTablex').DataTable();
    }
    componentDidUpdate(prevProps, prevState){
        //GET PROPS
        if(prevProps.id !== this.props.id || prevProps.user.location !== this.props.user.location || prevState.starts !== this.state.starts || prevState.ends !== this.state.ends)
        {
         //GET PROPS
         let id = this.props.id;
         let cat = this.props.cat && parseInt(this.props.cat) > 0 ? parseInt(this.props.cat) : 2;
         let dt = new Date();
	     let started  = new Date(this.state.started);
         let ended = new Date(this.state.ended);
        
         let daterange = ' '+moment(started).format('MMMM Do YYYY') + ' - ' + moment(ended).format('MMMM Do YYYY');
         this.setState({
              id:id,
              cat:cat,
              started: moment(started).format("YYYY-MM-DD"),
              ended: moment(ended).format("YYYY-MM-DD"),
              daterange:daterange
         })
             
         
         //GET ALL CATEGORYS
         let res = {};
         let ctn = '';
         let params = {};
         if(parseInt(id) > 0)
         {
             if(parseInt(cat) === 1)
             {
             res = {
                     'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                     'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                     'locationid':this.props.user.location,
                     'maintenanceid':id
                 };
             let catname = this.props.maintenancetypes.maintenancetypes && Array.isArray(this.props.maintenancetypes.maintenancetypes) && this.props.maintenancetypes.maintenancetypes.length > 0 ? this.props.maintenancecategorys.maintenancecategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''}
             ctn = catname !== undefined ? catname.name :'None';
                  params = {
                     data:JSON.stringify(res),
                     cat:'maintenancetransaction',
                     table:'maintenance_transactions'
                  }
             }
             else{
                 let aatname = this.props.maintenancetypes.maintenancetypes && Array.isArray(this.props.maintenancetypes.maintenancetypes) && this.props.maintenancetypes.maintenancetypes.length > 0 ? this.props.maintenancecategorys.maintenancecategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''}
                 let atnid = aatname !== undefined ? aatname.categoryid :null;
                 let atnm = aatname !== undefined ? aatname.name :null;
                 let catname = atnid !== null && parseInt(atnid) > 0 && this.props.maintenancecategorys.maintenancecategorys && Array.isArray(this.props.maintenancecategorys.maintenancecategorys) && this.props.maintenancecategorys.maintenancecategorys.length > 0 ? this.props.maintenancecategorys.maintenancecategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''} 
                 let ctnid = catname !== undefined ? catname.name :null;
                 ctn = ctnid +' '+atnm;
                 res = {
                     'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                     'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                     'locationid':this.props.user.location,
                     'maintenanceid':id
                 };
                 
                 params = {
                     data:JSON.stringify(res),
                     cat:'maintenancetransaction',
                     table:'maintenance_transactions'
                     }
             }
         }else if(id = 'all')
         {
             res = {
                     'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                     'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                     'locationid':this.props.user.location
                 };
             ctn = 'Maintenance Transaction List';
             params = {
                 data:JSON.stringify(res),
                 cat:'maintenancetransaction',
                 table:'maintenance_transactions'
             }
         }
         this.setState({subtopic:ctn});
         this.props.getMaintenancetransactions(params);
         $('#myTablex').DataTable();
         
        }
            

    }
    componentWillUnmount(){
        $('#myTablex').DataTable().destroy();
    }
    control = (id, func, uss) =>{
        Swal.fire({
            title: 'Confirm/Approve Entry',
            type: 'question',
            html: '<select id="txts1"  class="swal2-input form-control"><option value="1">Resolved</option><option value="2">Ignore</option></select>',
            customClass: 'swal2-overflow',
            
          }).then(function(result) {
                  let num = $('#txts1').val();
                  let fd = new FormData();
                  fd.append('id', id);
                  fd.append('is_completed', num);
                  fd.append('admin', uss.user.surname +" "+uss.user.firstname);
                  fd.append('date_completed', moment(new Date()).format("YYYY-MM-DD hh:mm:ss"));
                  fd.append('table', 'maintenance_transactions');
                  fd.append('cat', 'update');
                  fd.append('rowzid', uss.user.id);
                  fd.append('locateid', num =1 ? 'Approved':'Unapproved' + ' leave');
                  func(fd);
              
          });    
    }
    retState = (dt, dt1) =>{
        this.setState({starts:new Date(dt), ends:new Date(dt1)});
    }
    //EDIT MAINTENANCE
    editForm = (id, dat) =>{
        this.props.getMaintenancetransaction(id);
        this.setState({cfid:true, mid:id});
    }
    //DELETE MAINTENANCE
    deleteForm = id =>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You will not be able restore",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
        
            if (result.value) {
                let fd = new FormData();
                fd.append('id', id);
                fd.append('is_delete', 1);
                fd.append('cat', 'update');
                fd.append('table', 'maintenance_transactions');
                this.props.updateMaintenancetransaction(fd);
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            }
          })
        
    }
    //ACTIVATE MAINTENANCE
    activateForm = (id, ac) =>{
        let act = ac === 0 ? 1 : 0;
        let fd = new FormData();
        fd.append('id', id);
        fd.append('is_active', act);
        fd.append('cat', 'update');
        fd.append('table', 'maintenance_transactions');
        this.props.updateMaintenancetransaction(fd);
    }
   
    loadShown = (id, cat) =>{
          let ar = {};
          if(cat === 0)
          {
              ar[id] = true;
          }else{
              ar[id] = false;
          }
          this.setState({isshown:ar});
    }
    lunchDate = (func, func1, locs) =>{
        Swal.fire({
            title: 'Pick a date from and to:',
            type: 'question',
            html: '<input id="datepicker" type="date"  class="swal2-input"><br/><input id="datepicker1" type="date"  class="swal2-input">',
            customClass: 'swal2-overflow',
            
          }).then(function(result) {
              if(result.value)
              {
                  let v = $('#datepicker').val();
                  let v1 = $('#datepicker1').val();
                  let params = {
                    data:JSON.stringify(
                    {
                        'starts':moment(new Date(v)).format('YYYY-MM-DD'), 
                        'ends':moment(new Date(v1)).format('YYYY-MM-DD'), 
                        'locationid':locs
                    }),
                    cat:'maintenancetransaction',
                    table:'maintenance_transaction'
                }
                func(params);
                func1(v, v1);
              }
          });
    }
    changeStatex = (e) =>{
        this.setState({statex:e.value})
    }
    changeStatusx = (e) =>{
        this.setState({statusx:e.value})
    }
    render() {
        let { cfid, mid, isshown, subtopic, daterange, statusx, statex } = this.state  || '';
        let datax = this.props.maintenancetransactions.maintenancetransactions;
        let data = datax;
        if(statex !== null )
        {
            data = datax && Array.isArray(datax) ? datax.filter(rw=>rw !== null && rw !== undefined && rw.is_completed === statex):[];
        }
        if(statusx !== null && parseInt(statusx) > 0)
        {
            data = datax && Array.isArray(datax) ? datax.filter(rw=>rw !== null && rw !== undefined && parseInt(rw.status) === parseInt(statusx)):[];
        }
        
        let tabl = data && Array.isArray(data) ? data.filter(rw=>rw !== null).map((prop, ind)=>{
            return <tr 
                    key={ind}
                        style={{padding:'1px', margin:'0px'}}
                        onMouseEnter={()=>this.loadShown(prop.id, 0)}
                        onMouseLeave={()=>this.loadShown(prop.id, 1)}
                        >
                        <td className='text-center'>{prop.id.padStart(7, '0')}</td>
                        <td>
                        {prop.is_completed == 1 ? 
                            <span className={`badge badge-${stateColor[prop.is_completed]}`}><b>{moment(prop.date_completed).format('DD-MM-YYYY')}</b></span>:
                            <span className={`badge badge-warning`}><b>Pending...</b></span>}
                        </td>
                        <td>
                            <small><b>{moment(prop.transaction_date).format('DD-MM-YYYY')}</b></small><br/>
                            {prop.is_completed === 1? <small><span className={`badge badge-${stateColor[prop.is_completed]}`}><b>{moment(prop.date_completed).format('DD-MM-YYYY')}</b></span></small>:
                            <small><span className={`badge badge-${stateColor[prop.status]}`}><b>Pending...</b></span></small>}
                        </td>
                        <td style={{textAlign:'left'}}>
                            {prop.maintenancename}
                            { prop.id in isshown && isshown[prop.id] === true ? 
                                <span className='reportcontrol pull-right'>
                                    <a  onClick={()=>this.control(prop.id, this.props.updateMaintenancetransaction, this.props.user)} title='Block/Unblock Issue' href='#'>{<i className= {parseInt(prop.is_active) === 0 ? "fa fa-thumbs-up" : "fa fa-thumbs-down"}></i>}</a>
                                    <a onClick={()=>this.editForm(prop.id, prop)} title='Edit' href='#'><i className='fa fa-edit'></i></a>
                                    <a onClick={()=>this.deleteForm(prop.id, prop)} title='Delete' href='#'><i className='now-ui-icons ui-1_simple-remove'></i></a>
                                </span>
                            : ''}
                        </td>
                        <td>
                            <span className={`text-${piorityColor[parseInt(prop.status)]}`}><b>{piority[parseInt(prop.status)]}</b></span>
                        </td>
                        <td style={{textAlign:'left'}}>
                            <b>{prop.username}<br/>{prop.admin}</b>
                        </td>
                    </tr>
                }): '';
        
        let optionstatus = [];
        Object.keys(piority).forEach(pm=>{
            let ar = {};
            ar['value'] = pm;
            ar['label'] = piority[pm];
            return optionstatus.push(ar);
        })
        const customStyles = {
            option: (provided, state) => ({
              ...provided,
              borderBottom: '1px dotted green',
              color: state.isSelected ? 'yellow' : 'black',
              backgroundColor: state.isSelected ? 'green' : 'white'
            }),
            control: (provided) => ({
              ...provided,
              marginTop: "5%",
            })
          }
        return (
            <>
            {cfid === true ? 
            <FormMaintenanceTransaction
                st={cfid}
                mid={mid}
                handleClose={()=>this.setState({mid:null, cfid:false})}
            />:''}
              <div className="content">
                  <div className='card'>
                  <div className='card-header tablecardheader'>
                  
                  <Container xs='12'>
                          <Row xs='12'>
                              <Col md='6' sm='12'>
                              <h4 className='cardtitl'>{subtopic}</h4>
                              <span>{daterange}</span>
                              </Col >
                          </Row>
                              
                            <Row>
                              <Col xs='3'>
                                <Select
                                        value={statusx}
                                        styles = { customStyles }
                                        onChange={this.changeStatusx}
                                        options={optionstatus}
                                        autoFocus={true}
                                        placeholder='Select Piority'
                                        />
                                </Col>
                                <Col xs='3'>
                                <Select
                                        value={statex}
                                        styles = { customStyles }
                                        onChange={this.changeStatex}
                                        options={stater}
                                        autoFocus={true}
                                        placeholder='Select State'
                                        size='sm'
                                        />
                                </Col>
                                <Col>
                              <a href='#' className='btn btn-outline-info ' onClick={()=>this.editForm(null, {})}><i className='now-ui-icons ui-1_simple-add'></i> Maintenance Request Form</a>
                              <Link to={`/account/maintenances/${this.state.id}`}><button className='btn btn-outline-primary '><i className='fa fa-backward'></i> Back</button></Link>
                              <a class="btn btn-outline-info " onClick={()=>this.lunchDate(this.props.getMaintenancetransactions, this.retState, this.props.user.location)} href="#">
                                <i className="now-ui-icons ui-1_calendar-60"></i></a>
                                </Col>
                              </Row>
                             
                              
                      </Container>
                  </div>
                  <div className='card-body table-responsive'>
                    <table ref={el=>this.el = el} className='table-bordered display wrap table-striped mytables table-condensed table-hover'  id='myTablesxz' width='100%' >
                        <thead>
                            <tr>
                                <th style={{maxWidth:'50px'}}>TID</th>
                                <th>State</th>
                                <th><small>Recorded Date: <br/>Resolved Date:</small> </th>
                                <th>Issues</th>
                                <th>Piority</th>
                                <th>Recorded By:</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tabl}
                        </tbody>
                    </table>
                  </div>
                  </div>
              </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({ 
    user:state.userReducer,
    maintenancecategorys:state.maintenancecategoryReducer,
    maintenancetypes:state.maintenancetypeReducer,
    maintenancetransactions:state.maintenancetransactionReducer
  })
  
export default connect(mapStateToProps, 
    { 
        getMaintenancetransactions,
        getMaintenancetransaction,
        updateMaintenancetransaction,
        registerMaintenancetransaction,
        deleteMaintenancetransaction,
        getMaintenancecategorys,
        getMaintenancetypes
    })(BottomCard)
