export const ROOMCATEGORY_GET_MULTIPLE =  "ROOMCATEGORY_GET_MULTIPLE"
export const ROOMCATEGORY_GET_ONE =  "ROOMCATEGORY_GET_ONE"
export const ROOMCATEGORY_REGISTER_SUCCESS =  "ROOMCATEGORY_REGISTER_SUCCESS"
export const ROOMCATEGORY_REGISTER_FAIL =  "ROOMCATEGORY_REGISTER_FAIL"
export const ROOMCATEGORY_LOADING =  "ROOMCATEGORY_LOADING"
export const ROOMCATEGORY_LOADING_ERROR =  "ROOMCATEGORY_LOADING_ERROR"
export const ROOMCATEGORY_ACTIVATE_FAIL =  "ROOMCATEGORY_ACTIVATE_FAIL"
export const ROOMCATEGORY_ACTIVATE_SUCCESS =  "ROOMCATEGORY_ACTIVATE_SUCCESS"
export const ROOMCATEGORY_UPDATE_SUCCESS =  "ROOMCATEGORY_UPDATE_SUCCESS"
export const ROOMCATEGORY_UPDATE_FAIL =  "ROOMCATEGORY_UPDATE_FAIL"
export const ROOMCATEGORY_DELETE_SUCCESS =  "ROOMCATEGORY_DELETE_SUCCESS"
export const ROOMCATEGORY_DELETE_FAIL =  "ROOMCATEGORY_DELETE_FAIL"
export const ROOMCATEGORY_EDIT =  "ROOMCATEGORY_EDIT"