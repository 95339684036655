import React, {useState, useEffect} from "react";
import { useSelector } from 'react-redux';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Route, Switch } from "react-router-dom";

// core components
import GuestHeaderPage from "components/Headers/GuestHeaderPage";
import Footer from "components/Footer/Footer.jsx";
import routes from "routes.js";
import GuestNavBar from "components/Navbars/GuestNavBar1";
import Index from "views/GuestPages/Index";
import { Container } from "reactstrap";

var ps;
export default function GuestAdmin(props){

    let mainPanel = React.createRef();
    const [backgroundColor, setbackgroundColor] = useState('blue')
    const [user, setuser] = useState({})
    const [group, setgroup] = useState(null)

    const  userstaffs = useSelector(state=>state.userstaffReducestate)
    const  userstudents = useSelector(state=>state.userstudentReducestate)

    useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current);
            document.body.classList.toggle("perfect-scrollbar-on");
          }
      
          let user = {};
          let backgroundColor ='green';
          let group = null;
          
          setuser(user)
          setbackgroundColor(backgroundColor);
    
      return () => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
            document.body.classList.toggle("perfect-scrollbar-on");
          }
      }
    }, [])

    useEffect((e) => {
        // if (e.history.action === "PUSH") {
        //     mainPanel.current.scrollTop = 0;
        //     document.scrollingElement.scrollTop = 0;
        // }
    }, [mainPanel])

    const handleColorClick = color => {
        setbackgroundColor(color);
      };
    return (
        <div className="wrapperD">
        <div className="main-panels" ref={mainPanel}>
            <GuestNavBar {...props} user={user}  />
                <Container>
                    <Index />
                </Container>              
             <Footer fluid />
        </div>
      </div>
    )
  }




 
  
 
  







