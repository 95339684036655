import React, {useState, useEffect} from "react";
import { useSelector } from 'react-redux';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Route, Switch, useLocation, useParams } from "react-router-dom";

// core components
import GuestHeaderPage from "components/Headers/GuestHeaderPage";
import Footer from "components/Footer/Footer.jsx";
import routes from "routes.js";
import GuestNavBar from "components/Navbars/GuestNavBar1";
import Index from "views/GuestPages/Index";
import { Container } from "reactstrap";
import GuestApplied from "views/GuestPages/GuestAppliedMultiple";

var ps;
export default function GuestAdmin(props){

    let mainPanel = React.createRef();
    const params = useParams()
    const hist = useLocation()
    let {id} = params
    const [backgroundColor, setbackgroundColor] = useState('blue')
    const [user, setuser] = useState({})
    console.log(user)

    useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current);
            document.body.classList.toggle("perfect-scrollbar-on");
          }
      
          let user = {};
          let backgroundColor ='green';
          let group = null;
          
          setuser(user)
          setbackgroundColor(backgroundColor);
    
      return () => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
            document.body.classList.toggle("perfect-scrollbar-on");
          }
      }
    }, [])

   
    return (
        <div className="wrapper">
        <div className="main-panels" ref={mainPanel}>
               <GuestNavBar {...props} user={user}  />
                <Container  style={{position:'relative', marginTop:'8rem'}}>
                    <GuestApplied params={id} {...user} hist={hist}  />
                </Container>              
             <Footer fluid />
        </div>
      </div>
    )
  }




 
  
 
  







