import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import {getRoomtransactionbooking, getRoomtransaction, registerRoomtransaction, updateRoomtransaction, deleteRoomtransaction } from '../../actions/roomtransaction';


import $ from "jquery";
import axios from "axios";
import moment from "moment";
import { callError, API_PATHS, axiosConfig1 } from "actions/common";
import FormRooms from "views/Form/FormRooms";
import { SERVER_URL } from "actions/common";
import { callSuccess } from "actions/common";
import Axios from "axios";
import { axiosConfig } from "actions/common";
let path = API_PATHS;



   
const  BottomBooking = (props)=> {

     const [id, setid] = useState(null)
     const [cat, setcat] = useState(null)
     const [grp, setgrp] = useState(null)
     const [afid, setafid] = useState(false)
     const [tfid, settfid] = useState(false)
     const [cfid, setcfid] = useState(false)
     const [mfid, setmfid] = useState(false)
     const [ifid, setifid] = useState(false)
     const [mid, setmid] = useState(null)
     const [fid, setfid] = useState(false)
     //const [data, setdata] = useState({})
     const [subtopic, setsubtopic] = useState("All Categories")
     const [started, setstarted] = useState("")
     const [ended, setended] = useState("")
     const [daterange, setdaterange] = useState("")
     const [isshown, setisshown] = useState({})
     const [roomid, setroomid] = useState(null)
     const [bookingid, setbookingid] = useState(null)
     const [row, setrow] = useState({})
     const [modal, setmodal] = useState(false)
  
 useEffect(() => load(), [])
 

  const lunchDate = (func, func1, locs) =>{
      Swal.fire({
          title: 'Pick a date from and to:',
          type: 'question',
          html: '<input id="datepicker" type="date"  class="swal2-input"><br/><input id="datepicker1" type="date"  class="swal2-input">',
          customClass: 'swal2-overflow',
          
        }).then(function(result) {
            if(result.value)
            {
                let v = $('#datepicker').val();
                let v1 = $('#datepicker1').val();
                let params = {
                  data:JSON.stringify(
                  {
                      'starts':moment(new Date(v)).format('YYYY-MM-DD'), 
                      'ends':moment(new Date(v1)).format('YYYY-MM-DD'), 
                      'locationid':locs
                  }),
                  cat:'roomtransactionalpha',
                  table:'room_transaction'
              }
              func(params);
              func1(v, v1);
            }
        });
  }
  const retState = (dt, dt1) =>{
      setstarted(new Date(dt)); 
      setended(new Date(dt1));
  }

  const send = (rw)=>{
    let fd = new FormData()
    fd.append('cat', 'send_booking_room')
    fd.append("id", rw.id)
    fd.append('table', 'bookings')
    fd.append('token', '')

    Axios.post(SERVER_URL+"/index.php", fd, axiosConfig1)
    .then(res=>{
        callSuccess('Mail Sent')
    })
    .catch(err=>{
        callError('Mail Failed')
    })
    
}


 
  const handleSubmit = (roomid) =>{
      const {id, surname} = props.user.user || ''
  
    if(roomid > 0 )
    {
      let fd = new FormData();
      fd.append('roomid', roomid);
      fd.append('bookingid', row.id);
      fd.append('userid', id);
      fd.append('username', surname);
      fd.append('transaction_date', new Date());
      fd.append('table', 'bookings')
      fd.append('cat', 'set_room');
      
      Axios.post(SERVER_URL+'/index.php', fd, axiosConfig1)
      .then(res=>{callSuccess('Done'); return true})
      .then(res=>load())
      .catch(err=>callError('Failed'))
      callSuccess('Thank you. Please check your email to confirm your request has been registered');
    }else{
      callError('Incomplete information')
    }
    
    }
    const load = ()=>{
        
        let params = {
            data:JSON.stringify({
                is_approved:1
            }),
            cat:'bookings4',
            table:'bookings',
            token:''
        }
        props.getRoomtransactionbooking(params)
    }

    let data = props.roomtransactions.roomtransactionbooking;
    let tabl = data && Array.isArray(data) ? data.filter(rw=>rw !== null ).map((prop, ind)=>{
        let {dates, date_created, fullname, email, phone, category, location, office, trackid} = prop || ''
        if(prop !== null || prop !== undefined){
            let dts = dates && dates !='' && dates !== 'Null' ? JSON.parse(dates) : [];
        return <tr 
                    key={ind}
                    style={{padding:'1px', margin:'0px'}}
                    >
                    <td className='text-left'  >{date_created}</td>
                    <td className='text-left' >{fullname}</td>
                    <td style={{fontSize:'9px'}}>{phone}<br/>{email}</td>
                    <td>{location}</td>
                    <td>{office}<br/>{category}</td>
                    <td style={{textAlign:'left'}}>
                        {
                            Array.isArray(dts) ?
                            dts.map((prp, inds)=>{
                                return moment(prp).format('DD MMMM YYYY') + ','
                            }): null
                        }
                    </td>
                    <td className='text-left'  >
                        {trackid > 0 ?
                        <button type="button" onClick={()=>send(prop)} class="btn btn-info btn-sm" >
                            <i className='fa fa-envelope'></i> Send Email
                        </button>:
                         <button type="button" onClick={()=>addRoom(prop)} class="btn btn-primary btn-sm" >
                         <i className='fa fa-gear'></i> Set Rooms
                        </button>
                        }
                    </td>
                   
                </tr>}
    }): '';

    const addRoom = (rw)=>{
        setrow(rw)
        setmodal(false)
        setmodal(prev=>!prev)
    }


      return (
        <>
        <FormRooms row={row} st={modal} submitRoom={(e)=>handleSubmit(e)} />
        <div className="content">
            <div className='card'>
            <div className='card-header tablecardheader'>
                <h5>Booking Request</h5><br/>
                {subtopic}
                <a class="btn btn-outline-info btn-sm pull-right" onClick={()=>lunchDate(props.getRoomtransactionbooking, retState, props.user.location)} href="#">
                            <i className="now-ui-icons ui-1_calendar-60"></i>
                </a>
            </div>
            <div className='card-body table-responsive'>
                <table ref={el=>el = el} className='table-bordered display wrap table-striped mytables table-condensed table-hover'  id='myTableZ' width='100%' >
                    <thead>
                        <tr>
                            <th>SN</th>
                            <th>Fullname</th>
                            <th>Contact</th>
                            <th>Location</th>
                            <th>Office</th>
                            <th>Category</th>
                            <th width='50px'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tabl}
                    </tbody>
                </table>
            </div>
            </div>
        </div>
    </>
      )
    }
    const mapStateToProps = (state, ownProps) => ({ 
        user:state.userReducer,
        roomcategorys:state.roomcategoryReducer,
        roomtransactions:state.roomtransactionReducer
      })
      
    export default connect(mapStateToProps, 
        { 
            getRoomtransactionbooking,
            getRoomtransaction,
            updateRoomtransaction,
            registerRoomtransaction,
            deleteRoomtransaction
        })(BottomBooking)
    
