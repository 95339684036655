
import { callError, axiosConfig, SERVER_URL } from 'actions/common'
import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import DataTable from 'react-data-table-component';
import { Button, Container } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBed, faEnvelope, faPencil, faRecycle, faTrash, faUserPen, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import AddGuest from './AddGuest';
import AddGuestCategory from './AddGuestCategory';
import { callSuccess } from 'actions/common';
import { axiosConfig1 } from 'actions/common';
import { tableCustomStyles } from 'variables/styles';

const Add = <FontAwesomeIcon icon={faUserPlus} />
const Trash = <FontAwesomeIcon icon={faTrash} />
const Edit = <FontAwesomeIcon icon={faUserPen} />
const Assign = <FontAwesomeIcon icon={faBed} />
const Mail = <FontAwesomeIcon icon={faEnvelope} />
const Reload = <FontAwesomeIcon icon={faRecycle} />


export default function GuestApplied(props) {

    const {staffid} = props || ''

    const [data, setdata] = useState([])
    const [loading, setloading] = useState(false)
    const [loadingMail, setloadingMail] = useState(false)
    const [row, setrow] = useState({})
    const [isEdit, setisEdit] = useState(false)
    const [modal, setmodal] = useState(false)
    const [isEditCategory, setisEditCategory] = useState(false)
    const [modalCategory, setmodalCategory] = useState(false)

    useEffect(() => load(), [])

    const load = ()=>{
        setloading(true)
        let params = {
            data:JSON.stringify({
                is_approved:1
            }),
            cat:'bookings2',
            table:'bookings',
            token:''
        }
        Axios.get(SERVER_URL+"/index.php", {params}, axiosConfig)
        .then(res=>{
            setdata(res.data)
            setloading(false)
        })
        .catch(err=>{
            setloading(false)
            callError('Please check your network.')
        })
        
    }
    const recind = (rw) =>{
       let {id} = rw || null
      if(id > 0)
      {
        let fd = new FormData();
        fd.append('is_approved', 0);
        fd.append('table', 'bookings')

        if(id > 0){
          fd.append('id', id);
          fd.append('cat', 'update');
        }
        
        Axios.post(SERVER_URL+'/index.php', fd, axiosConfig1)
        .then(res=>{callSuccess('Done'); return true})
        .then(res=>props.load())
        .catch(err=>callError('Failed'))
        callSuccess('Thank you. ');
      }else{
        callError('Select a category')
      }
      
    }
    const send = (rw)=>{
      
        setloadingMail(true)
        let fd = new FormData()
        fd.append('cat', 'send_booking_approval')
        fd.append("id", rw.id)
        fd.append('table', 'bookings')
        fd.append('token', '')

        Axios.post(SERVER_URL+"/index.php", fd, axiosConfig1)
        .then(res=>{
            setloadingMail(false)
            callSuccess('Mail Sent')
        })
        .catch(err=>{
            setloadingMail(false)
            callError('Mail Failed')
        })
        
    }
  
    const columns = [
        {
            name: 'Date',
            selector: row => row.date_created,
        },
        {
            name: 'fullname',
            selector: row => row.fullname,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Phone',
            selector: row => row.phone,
        },
        {
            name: 'Location',
            selector: row => row.location,
            sort:true
        },
        {
            name: 'Action',
            width:'10%',
            grow:true,
            cell: (row) =>(
                <>
                    <span onClick={() => send(row)} className='btn btn-sm  btn-primary'>{Mail}</span>
                    <span onClick={() => recind(row)} className='btn btn-sm  btn-primary'>{Edit}</span>
                </>
            ),
        },
        
    ];

    const actionsMemo = React.useMemo(() =>{
        return <>
        <Button className="btn btn-sm" onClick={()=>load()}>{Reload}</Button>
        </>
        }
    , []);


    
  return (
      <>
        {modal ?
        <AddGuest 
            st={modal}
            data={row}
            staffid={staffid}
            isEdit={isEdit}
            setModal={()=>setmodal(prev=>!prev)}
            load={()=>load()}
        />:null}
        <AddGuestCategory 
            st={modalCategory}
            data={row}
            isEdit={isEditCategory}
            load={()=>load()}
        />
        {!loading ?
        <DataTable 
            title="Approved Request"  
            columns={columns} 
            data={data.filter(rw=>rw.is_approved == 1)}  
            actions={actionsMemo}
            loading={loading} 
            sortable
            dense
            filterable
            customStyles={tableCustomStyles}
        />:
        <Container>
            <div className='row'>
                <div className='col'>
                        loading..
                </div>
            </div>
        </Container>
        }
    </>
  )
}
