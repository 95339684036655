import React from "react";
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import {getUsers, getUser, registerUser, updateUser, deleteUser } from './../../actions/user';
import {getUserjobtransactions, getUserjobtransaction, registerUserjobtransaction, updateUserjobtransaction, deleteUserjobtransaction } from './../../actions/userjobtransaction';
import {getUserleavetransactions, getUserleavetransaction, registerUserleavetransaction, updateUserleavetransaction, deleteUserleavetransaction } from './../../actions/userleavetransaction';
import FormUserAccess from "views/Form/FormUserAccess";
import FormUserJob from "views/Form/FormUserJob";
import FormUserLeave from "views/Form/FormUserLeave";
import { access } from './../../actions/common';
import { Container } from "reactstrap";
import FormUser from "views/Form/FormUserm";
import $ from 'jquery';
import { Link } from "react-router-dom";
const accesss = access;

class BottomCard extends React.Component {
   constructor(props){
       super(props);
       this.state ={
           id:null,
           cat:null,
            grp:null,
            jfid:false,
            lfid:false,
            afid:false,
            tfid:false,
            cfid:false,
            mfid:false,
            ifid:false,
            mid:null,
            fid:false,
            data:{},
            subtopic:'All Categories',
            started:'',
            ended:'',
            daterange:'',
            isshown:{}

       }
   }

    componentDidMount()
    {
            let params1 = {
                data:JSON.stringify({'staffid':this.props.users.user.id}),
                cat:'categoryuserjob',
                table:'user_job_transactions'
              }
            this.props.getUserjobtransactions(params1);
            let params2 = {
                data:JSON.stringify({'staffid':this.props.users.user.id}),
                cat:'categoryuserleave',
                table:'user_leave_transactions'
              }
            this.props.getUserleavetransactions(params2);
    }
    changePassword = (func, id) =>{
        Swal.fire({
            title: 'Change Password',
            text: 'Input new password or leave blank to automatically generate password.',
            type: 'question',
            html: 'Input password or leave blank to automatically generate password.<br/><input id="passwd" type="text"  class="swal2-input">',
            customClass: 'swal2-overflow',
          }).then(function(result) {
              if(result.value)
              {
                  let v = $('#passwd').val();
                  let fd = new FormData();
                  fd.append('passwd', v);
                  fd.append('id', id);
                  fd.append('cat', 'passwd');
                  fd.append('table', 'user_types');
                  func(fd);
              }
          });
    }
    //EDIT USER
    editData = (id, dat) =>{
        this.props.getUser(id);
        this.setState({fid:true, mid:id});
    }
    editJob = (id, dat) =>{
        this.props.getUserjobtransaction(id);
        this.setState({jfid:true, mid:id});
    }
    editLeave = (id, dat) =>{
        this.props.getUserleavetransaction(id);
        this.setState({lfid:true, mid:id});
    }
    editAccess = (id, data) =>{
        this.props.getUser(id);
        this.setState({afid:true, mid:id, data:data});
    }
    
   
    removeJob = id =>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You will not be able restore",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
        
            if (result.value) {
                let fd = new FormData();
                fd.append('id', id);
                fd.append('cat', 'delete');
                fd.append('table', 'user_job_transactions');
                this.props.deleteUserjobtransaction(fd, id);
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            }
          })
        
    }
    removeLeave = id =>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You will not be able restore",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
        
            if (result.value) {
                let fd = new FormData();
                fd.append('id', id);
                fd.append('cat', 'delete');
                fd.append('table', 'user_leave_transactions');
                this.props.deleteUserleavetransaction(fd, id);
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            }
          })
        
    }
    controlLeave = (id, num, info, func, uss) =>{
        Swal.fire({
            title: info,
            type: 'question',
            html: '<input id="txts" type="textarea"  class="swal2-input form-control">',
            customClass: 'swal2-overflow',
            
          }).then(function(result) {
              
                  let v = $('#txts').val();
                  let fd = new FormData();
                  fd.append('id', id);
                  fd.append('is_approved', num);
                  fd.append('notes', v);
                  fd.append('admin', uss.user.surname +" "+uss.user.firstname);
                  fd.append('dated_admin', moment(new Date()).format("YYYY-MM-DD hh:mm:ss"));
                  fd.append('table', 'user_leave_transactions');
                  fd.append('cat', 'update');
                  fd.append('rowzid', uss.user.id);
                  fd.append('locateid', info + ' leave');
                  func(fd);
              
          });    
    }
    render() {
        let { fid, afid, jfid, lfid, mid } = this.state  || '';
        let { 
            id,
            employment_no,  
            title, 
            surname, 
            firstname, 
            middlename, 
            gender,
            dob, 
            doe, 
            address,
            email,
            phone1,
            phone2,
            categoryname,
            is_active,
            access
         } = this.props.users.user || '';
        let fullname = title+" "+surname+" "+firstname+" "+middlename;

        let ackr = access !== undefined & access !== '' ? JSON.parse(access):[[], [], [], [], [], []];
        let uj = this.props.userjobtransactions.userjobtransactions;
        let ujob = uj && uj !== undefined && Array.isArray(uj) && uj.length > 1 ? uj : [];
        let ul = this.props.userleavetransactions.userleavetransactions;
        let uleave = ul && ul !== undefined && Array.isArray(ul) && ul.length > 0 ? ul : [];
         
        return (
            <>
            {jfid === true ? 
            <FormUserJob
                st={jfid}
                mid={mid}
                staffid={id}
                data={this.state.data}
                handleClose={()=>this.setState({mid:null, jfid:false})}
            />:''}
            {lfid === true ? 
            <FormUserLeave
                st={lfid}
                mid={mid}
                staffid={id}
                data={this.state.data}
                handleClose={()=>this.setState({mid:null, lfid:false})}
            />:''}
            {afid === true ? 
            <FormUserAccess
                st={afid}
                mid={mid}
                data={this.state.data}
                handleClose={()=>this.setState({mid:null, afid:false})}
            />:''}
            {fid === true ? 
            <FormUser
                st={fid}
                mid={mid}
                data={this.state.data}
                handleClose={()=>this.setState({mid:null, fid:false})}
            />:''}
              <div className="content">
                  <div className='card'>
                  <div className='card-header tablecardheader'>
                    <div className='col-sm-8'>
                      <h4><i className='fa fa-user'></i>{' '}{fullname}</h4>
                      </div>
                    
                  </div>
                  <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#home">Bio Data</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#menu1">Job Roles</a>
                    </li> 
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#menu3">Leave</a>
                    </li> 
                 </ul>
                 <div class="tab-content">
                    <div class="tab-pane active container" id="home">
                        <div className='card-body table-responsive'>
                        <button onClick={()=>this.editData(id, this.props.users.user)}className='btn btn-sm btn-outline-default col-xs-12'><i className='fa fa-edit'></i> Edit Bio Data</button>
                            <table ref={el=>this.el = el} className='table-bordered display wrap  mytables table-condensed '  id='myTable' width='100%' >
                                
                            <tbody>
                            <tr>
                                    <th className='tagheader' width='30%'><i className='fa fa-key'></i>  Staff ID</th>
            <td className='tagdata'>{employment_no}</td>
                                </tr>
                                <tr>
                                    <th className='tagheader' width='30%'><i className='fa fa-user'></i>  Fullname</th>
                                        <td className='tagdata'>{fullname}</td>
                                </tr>
                                <tr>
                                    <th className='tagheader' width='30%'><i className='fa fa-calendar'></i>  Date of Birth</th>
                                    <td className='tagdata'>{moment(dob).format('DD MMMM YYYY')} </td>
                                </tr>
                                <tr>
                                    <th className='tagheader' width='30%'><i className='fa fa-calendar-o'></i>  Date Employed</th>
                                    <td className='tagdata'>{moment(doe).format('DD MMMM YYYY')}</td>
                                </tr>
                                <tr>
                                    <th className='tagheader' width='30%'><i className='fa fa-venus-mars'></i> Gender</th>
                <td className='tagdata'>{gender === 1 ? 'Male' :'Female'}</td>
                                </tr>
                                <tr>
                                    <th className='tagheader' width='30%'><i className='fa fa-phone'></i>  Mobile phone</th>
                <td className='tagdata'><a href={`tel:${phone1}`}>{phone1}</a> <a href={`tel:${phone2}`}>{phone2}</a></td>
                                </tr>
                                <tr>
                                    <th className='tagheader' width='30%'><i className='fa fa-envelope'></i> Email</th>
                                    <td className='tagdata'><a href={`mailto:${email}`}>{email}</a></td>
                                </tr>
                                <tr>
                                    <th className='tagheader' width='30%'><i className='fa fa-map-marker'></i> Address</th>
                                    <td className='tagdata'>{address}</td>
                                </tr>
                                <tr>
                                    <th className='tagheader' width='30%'><i className='fa fa-thumbtack'></i> Department</th>
                                    <td className='tagdata'>{categoryname}</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div class="tab-pane container" id="menu1">
                        <div className='card-body table-responsive'>
                         <table ref={el=>this.el = el} className='table-bordered display wrap  mytables table-condensed '  id='myTable' width='100%' >
                                <tbody>
                                    {Object.keys(accesss).map((prop, ind)=>{
                                        let lev = accesss[prop];
                                        return <tr key={ind}>
                                            <th className='tagheader' width='30%'><i className='fa fa-key'></i>  {lev.name}</th>
                                            <td className='tagdata'>
                                                {
                                                    Object.keys(lev.data).map((prp, ins)=>{
                                                        let ppp = lev.data[prp];
                                                        if(Array.isArray(ackr) && parseInt(prop) in ackr && Array.isArray(ackr[prop]) && parseInt(prp) in ackr[prop] ){
                                                        return <span key={ins} className={Array.isArray(ackr) && parseInt(prop) in ackr && Array.isArray(ackr[prop]) && prp in ackr[prop] ? 'text-success' : 'text-danger'}>{ppp}{' | '}</span>;
                                                        }
                                                    })
                                                }
                                            </td>
                                        </tr>
                                     })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="tab-pane container" id="menu3">
                        <div className='card-body table-responsive'>
                        <button onClick={()=>this.editLeave(null, this.props.users.user)}  className='btn btn-sm btn-outline-info col-xs-12'><i className='fa fa-plus'></i> Leave Request</button>
                        <table ref={el=>this.el = el} className='table-bordered display wrap  mytables table-condensed '  id='myTable' width='100%' >
                                <thead>
                                    <tr>
                                        <th>APPLIED ON.</th>
                                        <th>DURATION</th>
                                        <th>REASON</th>
                                        <th>STATUS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                 {uleave.map((prop, ind)=>{
                                 return <tr key={ind}>
                                            <td>{moment(prop.date_created).format('DD MM YYYY hh:mm:ss')}</td>
                                            <td>{moment(prop.started).format('DD MM YYYY hh:mm:ss')}{" "}{moment(prop.ended).format('DD MM YYYY hh:mm:ss')}</td>
                                            <td>
                                                <p>{prop.reason}</p>
                                                <p>
                                 {parseInt(prop.is_appoved) === 0 ? <span className='badge badge-warning'>{`Pending`}</span>:""}
                                 {parseInt(prop.is_appoved) === 1 ? <><span className='badge badge-success'>{`Approved by:${prop.admin} dated ${moment(prop.dated_admin).format('DD MMMM YYYY ')}`}</span><span>{prop.notes}</span></>:""}
                                 {parseInt(prop.is_appoved) === 2 ? <><span className='badge badge-danger'>{`Unapproved by:${prop.admin} dated ${moment(prop.dated_admin).format('DD MMMM YYYY')}`}</span><span>{prop.notes}</span></>:""}
                                                </p>
                                            </td> 
                                            <td>
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-toggle="dropdown">
                                                    <i className='fa fa-gear'></i> Actions
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="#" onClick={()=>this.editLeave(prop.id, prop)}><i className='fa fa-edit'></i> Edit</a>
                                                    <a class="dropdown-item" href="#" onClick={()=>this.removeLeave(prop.id)}><i className='fa fa-remove'></i> Delete</a>
                                                </div>
                                                </div>
                                                
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='card-footer '>
                        <Container className='align-items-center'>
                            <button onClick={()=>this.changePassword(this.props.updateUser, id)} className='btn btn-sm btn-outline-info col-xs-12 p-300'>Reset Password</button>
                            <Link to={`/account/userlog/${id * 111222333444555}`} className='btn btn-sm btn-outline-info col-xs-12'><i className='fa fa-list'></i> Veiw Log</Link>                          
                          </Container>
                    </div>
                </div>
                    
                    
                </div>

                
              </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({ 
    users:state.userReducer,
    usercategorys:state.usercategoryReducer,
    userjobtransactions:state.userjobtransactionReducer,
    userleavetransactions:state.userleavetransactionReducer,
  })
  
export default connect(mapStateToProps, 
    { 
        getUsers,
        getUser,
        updateUser,
        registerUser,
        deleteUser,
        getUserjobtransaction,
        getUserjobtransactions,
        updateUserjobtransaction,
        registerUserjobtransaction,
        deleteUserjobtransaction,
        getUserleavetransaction,
        getUserleavetransactions,
        updateUserleavetransaction,
        registerUserleavetransaction,
        deleteUserleavetransaction
    })(BottomCard)
