export const ROOMINVENTORY_GET_MULTIPLE =  "ROOMINVENTORY_GET_MULTIPLE"
export const ROOMINVENTORY_GET_ONE =  "ROOMINVENTORY_GET_ONE"
export const ROOMINVENTORY_REGISTER_SUCCESS =  "ROOMINVENTORY_REGISTER_SUCCESS"
export const ROOMINVENTORY_REGISTER_FAIL =  "ROOMINVENTORY_REGISTER_FAIL"
export const ROOMINVENTORY_LOADING =  "ROOMINVENTORY_LOADING"
export const ROOMINVENTORY_LOADING_ERROR =  "ROOMINVENTORY_LOADING_ERROR"
export const ROOMINVENTORY_ACTIVATE_FAIL =  "ROOMINVENTORY_ACTIVATE_FAIL"
export const ROOMINVENTORY_ACTIVATE_SUCCESS =  "ROOMINVENTORY_ACTIVATE_SUCCESS"
export const ROOMINVENTORY_UPDATE_SUCCESS =  "ROOMINVENTORY_UPDATE_SUCCESS"
export const ROOMINVENTORY_UPDATE_FAIL =  "ROOMINVENTORY_UPDATE_FAIL"
export const ROOMINVENTORY_DELETE_SUCCESS =  "ROOMINVENTORY_DELETE_SUCCESS"
export const ROOMINVENTORY_DELETE_FAIL =  "ROOMINVENTORY_DELETE_FAIL"
export const ROOMINVENTORY_EDIT =  "ROOMINVENTORY_EDIT"