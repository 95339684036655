import React from "react";
import { connect } from 'react-redux';
import {Tabs, TabList, TabPanel, Tab} from 'react-tabs'
import RoomWidget from "./Room/RoomWidget";
import RoomAnalysis from "./Room/RoomAnalysis";
import MaintenanceWidget from "./Maintenance/MaintenanceWidget";
import MaintenanceTable from "./Maintenance/MaintenanceTable";
import UserWidget from "./User/UserWidget";
import 'react-tabs/style/react-tabs.css';
class BottomCard extends React.Component {
    render() {
        let {access} = this.props.user.user || {};
        let isAdmin = this.props.user.isAdmin;
        let acc = access !== undefined & access !== '' ? JSON.parse(access) : [[], [], [], [], [], []] ;

        return (
            <>
              <div class="content">
              <Tabs>
                  
                <TabList style={{backgroundColor:'#001f2b', fontFamily:'Poiret One', fontWeight:'bolder', color:'#00b8ff', fontSize:'1.2rem'}}>
                {isAdmin || ['0', '4'].some(el=>acc[0].includes(el)) ?
                   <> <Tab><i className='fa fa-table'></i> <span className='sr-only'>Maintenance</span></Tab>
                    </>:''}
                {isAdmin || ['0', '3'].some(el=>acc[0].includes(el)) ?
                    <><Tab><i className='fa fa-bed '></i> <span className='sr-only'>Houskeeping</span></Tab>
                    <Tab><i className='fa fa-area-chart'></i> <span className='sr-only'>Bookings</span></Tab></>:''}
                {isAdmin || ['0', '5'].some(el=>acc[0].includes(el)) ?
                    <Tab><i className='fa fa-user-circle'></i> <span className='sr-only'>Employee</span></Tab>:''}  
                </TabList>
                {isAdmin || ['0', '4'].some(el=>acc[0].includes(el)) ?
                <><TabPanel><MaintenanceTable /><MaintenanceWidget /></TabPanel></> :''}
                {isAdmin || ['0', '3'].some(el=>acc[0].includes(el)) ?
                <><TabPanel><RoomWidget /></TabPanel>
                <TabPanel><RoomAnalysis /></TabPanel></> :''} 
                
                {isAdmin || ['0', '5'].some(el=>acc[0].includes(el)) ?
                <TabPanel><UserWidget /></TabPanel>  :''}
                
                  
             </Tabs>
             {['0', '5', '4', '3'].some(el=>!acc[0].includes(el)) ?
             <h1>StreSERT Integrated Limited</h1>:''}
              </div>
            </>
        )
    }
}
const mapStateToProps = (state, ownProps) => ({ 
    user: state.userReducer
  })
    
export default connect(mapStateToProps, {})(BottomCard)