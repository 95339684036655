export const ROOMTYPE_GET_MULTIPLE =  "ROOMTYPE_GET_MULTIPLE"
export const ROOMTYPE_GET_MULTIPLE_DATA =  "ROOMTYPE_GET_MULTIPLE_DATA"
export const ROOMTYPE_GET_MULTIPLE_ANALYSIS =  "ROOMTYPE_GET_MULTIPLE_ANALYSIS"
export const ROOMTYPE_GET_ONE =  "ROOMTYPE_GET_ONE"
export const ROOMTYPE_REGISTER_SUCCESS =  "ROOMTYPE_REGISTER_SUCCESS"
export const ROOMTYPE_REGISTER_FAIL =  "ROOMTYPE_REGISTER_FAIL"
export const ROOMTYPE_LOADING =  "ROOMTYPE_LOADING"
export const ROOMTYPE_LOADING_ERROR =  "ROOMTYPE_LOADING_ERROR"
export const ROOMTYPE_ACTIVATE_FAIL =  "ROOMTYPE_ACTIVATE_FAIL"
export const ROOMTYPE_ACTIVATE_SUCCESS =  "ROOMTYPE_ACTIVATE_SUCCESS"
export const ROOMTYPE_UPDATE_SUCCESS =  "ROOMTYPE_UPDATE_SUCCESS"
export const ROOMTYPE_UPDATE_FAIL =  "ROOMTYPE_UPDATE_FAIL"
export const ROOMTYPE_DELETE_SUCCESS =  "ROOMTYPE_DELETE_SUCCESS"
export const ROOMTYPE_DELETE_FAIL =  "ROOMTYPE_DELETE_FAIL"
export const ROOMTYPE_EDIT =  "ROOMTYPE_EDIT"