import { SERVER_URL } from 'actions/common';
import { callSuccess } from 'actions/common';
import { axiosConfig1 } from 'actions/common';
import { callError } from 'actions/common';
import Axios from 'axios';
import React,{useState, useEffect} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup,  Input, Col } from 'reactstrap';

const locs ={
    1:'Executive',
    2:'Top Managment',
    3:'Middle Management',
    4:'Standard',

}

export default function AddGuest(props) {

    const {isEdit, data:row, st} = props || ''
    const {fullname} = row || 'Error'
  
    const [modal, setModal] = useState(false);
    const [id, setid] = useState(null)
    const [categoryid, setcategoryid] = React.useState(1);

    useEffect(()=>{
        setModal(st);
        if(isEdit){
            let {id, categoryid, category} = row || ''
            setid(id)
            setcategoryid(categoryid)
        }else{
            setid(null)
            setcategoryid(null)
        }

    }, [isEdit, st])


    const resetdata= async() =>{
        handleClose();
    }
    const handleClose = ()=>{
        setModal(false)
        setcategoryid('');
    }

    // const toggle = () => setModal(!modal);
  
  
    const handleSubmit = () =>{
       
        if(categoryid > 0)
        {
          let fd = new FormData();
          fd.append('categoryid', categoryid);
          fd.append('is_approved', 1);
          fd.append('category', locs[categoryid]);
          fd.append('table', 'bookings')

          if(id > 0){
            fd.append('id', id);
            fd.append('cat', 'update');
          }
          
          Axios.post(SERVER_URL+'/index.php', fd, axiosConfig1)
          .then(res=>{callSuccess('Done'); return true})
          .then(res=>props.load())
          .catch(err=>callError('Failed'))
          handleClose();
          callSuccess('Thank you. Please check your email to confirm your request has been registered');
        }else{
          callError('Select a category')
        }
        
    }


  return (
     <Modal isOpen={modal} fade  style={{backgroundColor:'transparent !important'}} backdrop='static' keyboard={false}>
        <ModalHeader toggle={resetdata}><i className='fa fa-bed m-2'></i>
         Set Room Category <b style={{textTransform:'uppercase'}}>{fullname}</b> </ModalHeader>
        <ModalBody>
        <Form>
        <div class="row">
            <div class="col">
              <select 
              type="text" 
              value={categoryid} 
              onChange={(e)=>setcategoryid(e.target.value)} 
              class="form-control" 
              placeholder="categoryid"
              >
                  <option></option>
                  {
                    Array.isArray(Object.keys(locs)) ?
                      Object.keys(locs).map(ele=>{
                        return  <option value={ele}>{locs[ele]}</option>
                      }):
                    null
                  }
              </select>
            </div>
        </div>
        </Form>
        </ModalBody>
        <ModalFooter>
          
          <Button color="warning" onClick={resetdata}>Cancel</Button> {' '}
          <Button color='info' onClick={handleSubmit}>Save</Button>
        </ModalFooter>
      </Modal>
  )
}
