const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        paddingLeft: '0 4px',
        justifyContent: 'center',
        backgroundColor: '#666666',
        color:'#ffffff'
      },
    },
    rows: {
        style: {
          fontSize: '14px',
          paddingLeft: '0 1px',
          padding: '0px',
          color:'#000000'
        },
      },
      cells: {
        style: {
          fontSize: '14px',
          fontWeight:'bold',
          padding: '0px',
          color:'#000000'
        },
      },
  }
  export { tableCustomStyles };