export const MAINTENANCECATEGORY_GET_MULTIPLE =  "MAINTENANCECATEGORY_GET_MULTIPLE"
export const MAINTENANCECATEGORY_GET_ONE =  "MAINTENANCECATEGORY_GET_ONE"
export const MAINTENANCECATEGORY_REGISTER_SUCCESS =  "MAINTENANCECATEGORY_REGISTER_SUCCESS"
export const MAINTENANCECATEGORY_REGISTER_FAIL =  "MAINTENANCECATEGORY_REGISTER_FAIL"
export const MAINTENANCECATEGORY_LOADING =  "MAINTENANCECATEGORY_LOADING"
export const MAINTENANCECATEGORY_LOADING_ERROR =  "MAINTENANCECATEGORY_LOADING_ERROR"
export const MAINTENANCECATEGORY_ACTIVATE_FAIL =  "MAINTENANCECATEGORY_ACTIVATE_FAIL"
export const MAINTENANCECATEGORY_ACTIVATE_SUCCESS =  "MAINTENANCECATEGORY_ACTIVATE_SUCCESS"
export const MAINTENANCECATEGORY_UPDATE_SUCCESS =  "MAINTENANCECATEGORY_UPDATE_SUCCESS"
export const MAINTENANCECATEGORY_UPDATE_FAIL =  "MAINTENANCECATEGORY_UPDATE_FAIL"
export const MAINTENANCECATEGORY_DELETE_SUCCESS =  "MAINTENANCECATEGORY_DELETE_SUCCESS"
export const MAINTENANCECATEGORY_DELETE_FAIL =  "MAINTENANCECATEGORY_DELETE_FAIL"
export const MAINTENANCECATEGORY_EDIT =  "MAINTENANCECATEGORY_EDIT"