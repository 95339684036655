import React from "react";
import {connect} from "react-redux";
import moment from "moment";
import Container from "reactstrap/lib/Container";
import HomeTopCard from "./HomeTopCard";
import InventoryBottomCard from "./Inventory/BottomCategory";
import FormInventory from './Form/FormInventory';
import FormInventoryCategory from './Form/FormInventoryCategory';
import FormInventoryTransaction from './Form/FormInventoryTransaction';

class InventoryPage extends React.Component {
	 constructor(props){
        super(props);
        this.state ={
        addbooking : false, 
  			numbooking : null, 
  			bookingroomid : null, 
  			bookingroomdata : {}, 
  			bookingroomdate : new Date(), 
  			defaulttoday:new Date(),
        defaultstarted:null,
        defaultended:null,
  			choicestarted : new Date(),
  			choiceended : new Date(),
  			inventoryid: null,
        inventoryname:'',
        addinventory:false,
      	addinventorycategory:false,
      	addinventorytransaction:false,
      	numinventory:null,
      	numinventorycategory:null,
      	numinventorytransaction:null,
      	categoryid:null,
        categoryname:''
        }
     }

     componentDidMount()
     {
	      let dt = new Date();
	      let firstday = new Date(dt.getFullYear(), dt.getMonth(), 1);
	      let lastday = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
	      this.setState({
	         defaultstarted: moment(firstday).format("YYYY-MM-DD"),
	         defaultended: moment(lastday).format("YYYY-MM-DD")
	      })
     }
    


	render(){
		let { 
				choicestarted,
				choiceended,
				defaultstarted,
    		defaultended,
    		inventoryid,
        inventoryname,
        addinventory,
      	addinventorycategory,
      	addinventorytransaction,
      	numinventory,
      	numinventorycategory,
      	numinventorytransaction,
      	categoryid,
    		categoryname

			 } = this.state || '';


		return(
			<>
			{addinventory ? <FormInventory st={addinventory} st1={numinventory} handleClose={()=>this.setState({addinventory:false})} />:''}
            {addinventorycategory ? <FormInventoryCategory st={addinventorycategory} st1={numinventorycategory} handleClose={()=>this.setState({addinventorycategory:false})} />:''}
            {addinventorytransaction ? <FormInventoryTransaction st={addinventorytransaction} st1={numinventorytransaction} handleClose={()=>this.setState({addinventorytransaction:false})} /> : ''}
			<Container style={{position:'relative'}}>
                <>
                <div style={{minHeight:'50px'}}>
                </div>
                  <HomeTopCard  
                      id={0}
                      title={'Inventory '}
                      icon={'fa fa-list'}
                      handleBooking={(roomid, roomdata, roomdate, rowid)=>this.setState({
                          addbooking:true,
                          numbooking:rowid,
                          bookingroomid:roomid,
                          bookingroomdata:roomdata,
                          bookingroomdate:roomdate
                      })}
                      setDate={(start, end)=>this.setState({choicestarted:start, choiceended:end})}
                      />
                  <InventoryBottomCard 
                      id={1}
                      categoryid={categoryid}
                      categoryname={categoryname}
                      inventoryid={inventoryid}
                      inventoryname={inventoryname}
                      choicestarted={choicestarted}
                      choiceended={choiceended}
                      defaultstarted={defaultstarted}
                      defaultended={defaultended}
                      inventoryAdd={(rid)=>this.setState({addinventory:true, numinventory:rid})}
                      inventoryTransactionAdd={(rid)=>this.setState({addinventorytransaction:true, numinventorytransaction:rid})}
                      />
                </>
             </Container>
             </>

		)
	}

}
const mapStateToProps = (state, ownprop) =>({
  

})
export default connect(mapStateToProps, {})(InventoryPage);