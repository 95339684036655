export const INVENTORYTRANSACTION_GET_MULTIPLE =  "INVENTORYTRANSACTION_GET_MULTIPLE"
export const INVENTORYTRANSACTION_GET_ONE =  "INVENTORYTRANSACTION_GET_ONE"
export const INVENTORYTRANSACTION_REGISTER_SUCCESS =  "INVENTORYTRANSACTION_REGISTER_SUCCESS"
export const INVENTORYTRANSACTION_REGISTER_FAIL =  "INVENTORYTRANSACTION_REGISTER_FAIL"
export const INVENTORYTRANSACTION_LOADING =  "INVENTORYTRANSACTION_LOADING"
export const INVENTORYTRANSACTION_LOADING_ERROR =  "INVENTORYTRANSACTION_LOADING_ERROR"
export const INVENTORYTRANSACTION_ACTIVATE_FAIL =  "INVENTORYTRANSACTION_ACTIVATE_FAIL"
export const INVENTORYTRANSACTION_ACTIVATE_SUCCESS =  "INVENTORYTRANSACTION_ACTIVATE_SUCCESS"
export const INVENTORYTRANSACTION_UPDATE_SUCCESS =  "INVENTORYTRANSACTION_UPDATE_SUCCESS"
export const INVENTORYTRANSACTION_UPDATE_FAIL =  "INVENTORYTRANSACTION_UPDATE_FAIL"
export const INVENTORYTRANSACTION_DELETE_SUCCESS =  "INVENTORYTRANSACTION_DELETE_SUCCESS"
export const INVENTORYTRANSACTION_DELETE_FAIL =  "INVENTORYTRANSACTION_DELETE_FAIL"
export const INVENTORYTRANSACTION_SUMMARY =  "INVENTORYTRANSACTION_SUMMARY"