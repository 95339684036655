import React, {useState, useEffect} from "react";
import { useSelector } from 'react-redux';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import Footer from "components/Footer/Footer.jsx";
import GuestNavBar from "components/Navbars/GuestNavBar1";
import { Container } from "reactstrap";
import IndexMultiple from "views/GuestPages/IndexMultiple";
import { validatejson } from "actions/common";

var ps;
export default function GuestAdmin(props){

    let mainPanel = React.createRef();
    const [backgroundColor, setbackgroundColor] = useState('blue')
    const [user, setuser] = useState({})
    console.log(user)
    console.log(localStorage.getItem('auths'))
    useEffect(() => {
      if(localStorage.getItem('auths')  == 1)
      {
          let f = localStorage.getItem('userx') || '{}'; 
          f = validatejson(f) ? JSON.parse(f) : {}
          if(Array.isArray(Object.keys(f)) && f.hasOwnProperty('id'))
          {setuser(f)}
          
      }
    },[])

    useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current);
            document.body.classList.toggle("perfect-scrollbar-on");
          }
          let backgroundColor ='green';
          setbackgroundColor(backgroundColor);   
      return () => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
            document.body.classList.toggle("perfect-scrollbar-on");
          }
      }
    }, [])

    
   
    return (
        <div className="wrapper">
        <div className="main-panels" ref={mainPanel}>
               <GuestNavBar {...props} user={user}  />
                <Container  style={{position:'relative', marginTop:'5rem'}}>
                    <IndexMultiple {...user} />
                </Container>              
             <Footer fluid />
        </div>
      </div>
    )
  }




 
  
 
  







