export const MAINTENANCETRANSACTION_GET_MULTIPLE =  "MAINTENANCETRANSACTION_GET_MULTIPLE"
export const MAINTENANCETRANSACTION_GET_ONE =  "MAINTENANCETRANSACTION_GET_ONE"
export const MAINTENANCETRANSACTION_REGISTER_SUCCESS =  "MAINTENANCETRANSACTION_REGISTER_SUCCESS"
export const MAINTENANCETRANSACTION_REGISTER_FAIL =  "MAINTENANCETRANSACTION_REGISTER_FAIL"
export const MAINTENANCETRANSACTION_LOADING =  "MAINTENANCETRANSACTION_LOADING"
export const MAINTENANCETRANSACTION_LOADING_ERROR =  "MAINTENANCETRANSACTION_LOADING_ERROR"
export const MAINTENANCETRANSACTION_ACTIVATE_FAIL =  "MAINTENANCETRANSACTION_ACTIVATE_FAIL"
export const MAINTENANCETRANSACTION_ACTIVATE_SUCCESS =  "MAINTENANCETRANSACTION_ACTIVATE_SUCCESS"
export const MAINTENANCETRANSACTION_UPDATE_SUCCESS =  "MAINTENANCETRANSACTION_UPDATE_SUCCESS"
export const MAINTENANCETRANSACTION_UPDATE_FAIL =  "MAINTENANCETRANSACTION_UPDATE_FAIL"
export const MAINTENANCETRANSACTION_DELETE_SUCCESS =  "MAINTENANCETRANSACTION_DELETE_SUCCESS"
export const MAINTENANCETRANSACTION_DELETE_FAIL =  "MAINTENANCETRANSACTION_DELETE_FAIL"
export const MAINTENANCETRANSACTION_SUMMARY =  "MAINTENANCETRANSACTION_SUMMARY"