import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import { deleteRoomtransaction, registerRoomtransaction, updateRoomtransaction, getRoomtransactions, getRoomtransaction } from '../../actions/roomtransaction';
import { getRoomdata } from "./../../actions/roomtype";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Container } from 'reactstrap';
import $ from 'jquery';
import {alllocations} from 'actions/common';
import FormBooking from './FormBooking';
 
const lockz = alllocations;
const Modals = (props) => {
  
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState('');
  const [date, setDate] = useState(new Date());
  const [data, setData] = useState({});
  const [grp, setGrp] = useState({});
  const [isshown, setIsshown] = useState({});

  const [addbooking, setAddbooking] = useState(false);
  const [numbooking, setNumbooking] = useState(null);
  const [bookingroomid, setBookingroomid] = useState(null);
  const [bookingroomdate, setBookingroomdate] = useState(new Date());
  const [bookingroomdata, setBookingroomdata] = useState([]);

  const resetdata= async() =>{
    props.handleClose();
  }

  const toggle = () => setModal(!modal);
 
  useEffect(() => {
    setModal(props.st);
    setTitle(props.title);
    setDate(props.date);
    setData(props.data);
    setGrp(props.grp);
},[props.st, props.date, props.data, props.title]);

const loadShown = (id, cat) =>{
    let ar = {};
    if(cat === 0)
    {
        ar[id] = true;
    }else{
        ar[id] = false;
    }
    setIsshown(ar);
}
const roomBook =(id, data)=>{
   setAddbooking(true);
   setNumbooking(null);
   setBookingroomid(id);
   setBookingroomdate(date);
   setBookingroomdata(data);
}
const roomEdit =(id, data)=>{
  props.getRoomtransaction(id);
  setAddbooking(true);
  setNumbooking(data.roomid);
  setBookingroomid(id);
  setBookingroomdate(date);
  setBookingroomdata(data);
}
const roomCancel =(id, data, func)=>{
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this! You lose the record",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then(async (result) => {
    if (result.value) {
      let fd = new FormData();
          fd.append('id', id);
          fd.append('cat', 'delete');
          fd.append('table', 'room_transactions');
          fd.append('rowzid', props.user.user.id);
          fd.append('locateid', 'record deleted');
          await func(fd);
          await roomReload();
      Swal.fire(
        'Deleted!',
        'Booking has been deleted.',
        'success'
      )
    }
    
  })
}
const roomLodge =(id, data, func)=>{
  Swal.fire({
    title: 'When did the Guest arrive ?',
    type: 'question',
    html: '<input id="datepicker" type="datetime-local"  class="swal2-input form-control">',
    customClass: 'swal2-overflow',
    
  }).then(async function(result) {
      if(result.value){
          let v = $('#datepicker').val();
          let fd = new FormData();
          fd.append('id', id);
          fd.append('is_lodged', 1);
          fd.append('endbook', moment(new Date(v)).format('YYYY-MM-DD hh:mm:ss'));
          fd.append('cat', 'update');
          fd.append('table', 'room_transactions');
          fd.append('rowzid', props.user.user.id);
          fd.append('locateid', 'Guest Logged in');
          func(fd);
          await roomReload();
      }
  });    
}
const roomLeft =(id, data, func)=>{
  Swal.fire({
    title: 'When did the Guest leave ?',
    type: 'question',
    html: '<input id="datepicker" type="datetime-local"  class="swal2-input form-control">',
    customClass: 'swal2-overflow',
    
  }).then(async function(result) {
      if(result.value){
          let v = $('#datepicker').val();
          let fd = new FormData();
          fd.append('id', id);
          fd.append('is_vacated', 1);
          fd.append('endbook', moment(new Date(v)).format('YYYY-MM-DD hh:mm:ss'));
          fd.append('cat', 'update');
          fd.append('table', 'room_transactions');
          fd.append('rowzid', props.user.user.id);
          fd.append('locateid', 'Guest vacated');
          await func(fd);
          await roomReload();
      }
  });    
}
const convertdays = (time) =>{
        let ys = parseInt(time)/( 60 * 60 * 30 * 24 * 12);
        let y = Math.floor(ys);
        let mts = (ys - y) * 12;
        let mt = Math.floor(mts);
        let ds = (mts - mt) * 30;
        let d = Math.floor(ds);
        let hs = (ds - d) * 24;
        let h  = Math.floor(hs);
        let ms = (hs - h) * 60;
        let m = Math.floor(ms);
        let ss = (ms - m) * 60;
        let s = Math.floor(ss);

        let dates = y > 0 ? y > 1 ? y + 'yrs ' :  y + 'yr  ' : '';
        dates += mt > 0 ? mt > 1 ? mt + 'mths ' :  mt + 'mth' : '';
        dates += d > 0 ? d > 1 ? d + 'days ' :  d + 'day' : '';
        dates += h > 0 ? h > 1 ? h + 'hrs ' :  h + 'hr ' : '';
        dates += m > 0 ? m > 1 ? m + 'mins ' :  m + 'min ' : '';
        dates += s > 0 ? s > 1 ? s + 'secs' :  s + 'sec' : '';

        return dates;
}
const roomReload = () =>{
  let p = {'locationid':props.user.location, 'currentdate':moment(new Date(date)).format('YYYY-MM-DD')};
  let params = {
            data:JSON.stringify(p),
            cat:'roomsta',
            table:'room_types'
      }
  props.getRoomdata(params);
}

let tabl ='';
if(grp === 1)
{
     tabl = data && Array.isArray(data) && data.length > 0 ? data.map((prop, ind)=>{
     if(prop !== null)
     {
         return <tr 
                id={ind} 
                style={{padding:'1px', margin:'0px'}}
                onMouseEnter={()=>loadShown(prop.id, 0)}
                onMouseLeave={()=>loadShown(prop.id, 1)}
                >
                <td style={{padding:'1px', margin:'0px'}}>{lockz[prop.locationid]}</td>
                <td style={{padding:'1px', margin:'0px'}}>{prop.categoryname}</td>
                <td style={{padding:'1px', margin:'0px'}}>{prop.name}</td>
                <td style={{padding:'1px', margin:'0px', textTransform:'capitalize'}}>{prop.fullname}</td>
                <td style={{padding:'1px', margin:'0px'}}>{prop.phone}</td>
                <td style={{padding:'1px', margin:'0px'}}>{convertdays(prop.duration)}</td>
                <td style={{padding:'1px', margin:'0px'}}><a href="#" onClick={()=>roomLeft(prop.id, prop, props.updateRoomtransaction)}>Vacate</a> | <a href="#" onClick={()=>roomEdit(prop.id, prop.roomid, prop)}>Edit</a> | <a href="#" onClick={()=>roomCancel(prop.id, prop, props.deleteRoomtransaction)}>Cancel</a></td>
            </tr>
      }
    }):''
}
if(grp === 2)
{
     tabl = data && Array.isArray(data) && data.length > 0 ? data.map((prop, ind)=>{
     if(prop !== null)
     {
         return <tr 
                id={ind} 
                style={{padding:'1px', margin:'0px'}}
                onMouseEnter={()=>loadShown(prop.id, 0)}
                onMouseLeave={()=>loadShown(prop.id, 1)}
                >
                <td style={{padding:'1px', margin:'0px'}}>{lockz[prop.locationid]}</td>
                <td style={{padding:'1px', margin:'0px'}}>{prop.categoryname}</td>
                <td style={{padding:'1px', margin:'0px'}}>{prop.name}</td>
                <td style={{padding:'1px', margin:'0px', textTransform:'capitalize'}}>{prop.fullname}</td>
                <td style={{padding:'1px', margin:'0px'}}>{prop.phone}</td>
                <td style={{padding:'1px', margin:'0px'}}>{moment(prop.transaction_date).format('DD/MM/YYYY hh:mm:ss')}</td>
                <td style={{padding:'1px', margin:'0px'}}><a href="#" onClick={()=>roomLodge(prop.id, prop, props.updateRoomtransaction)}>Arrived</a> | <a href="#" onClick={()=>roomEdit(prop.id, prop)}>Edit</a> | <a href="#" onClick={()=>roomCancel(prop.id, prop, props.deleteRoomtransaction)}>Cancel</a></td>
            </tr>
      }
    }):''
}
if(grp === 3)
{
     tabl = data && Array.isArray(data) && data.length > 0 ? data.map((prop, ind)=>{
     if(prop !== null)
     {
         return <tr 
                id={ind} 
                style={{padding:'1px', margin:'0px'}}
                onMouseEnter={()=>loadShown(prop.id, 0)}
                onMouseLeave={()=>loadShown(prop.id, 1)}
                >
                <td style={{padding:'1px', margin:'0px'}}>{lockz[prop.locationid]}</td>
                <td style={{padding:'1px', margin:'0px'}}>{prop.categoryname}</td>
                <td style={{padding:'1px', margin:'0px'}}>{prop.name}</td>
                <td style={{padding:'1px', margin:'0px'}}><a href="#" onClick={()=>roomBook(prop.id, prop)}>BOOK</a></td>
            </tr>
      }
    }):''
}
if(grp === 4)
{
     tabl = data && Array.isArray(data) && data.length > 0 ? data.map((prop, ind)=>{
     if(prop !== null)
     {
         return <tr 
                id={ind} 
                style={{padding:'1px', margin:'0px'}}
                onMouseEnter={()=>loadShown(prop.id, 0)}
                onMouseLeave={()=>loadShown(prop.id, 1)}
                >
                <td style={{padding:'1px', margin:'0px'}}>{lockz[prop.locationid]}</td>
                <td style={{padding:'1px', margin:'0px'}}>{prop.categoryname}</td>
                <td style={{padding:'1px', margin:'0px'}}>{prop.name}</td>
                <td style={{padding:'1px', margin:'0px', textTransform:'capitalize'}}>{prop.fullname}</td>
                <td style={{padding:'1px', margin:'0px'}}>{prop.phone}</td>
                <td style={{padding:'1px', margin:'0px'}}>{convertdays(prop.duration)}</td>
            </tr>
      }
    }):''
}
  return (
    <div>
     {addbooking ? 
      <FormBooking 
          st={addbooking} 
          mid={numbooking} 
          roomid={bookingroomid} 
          roomdate={bookingroomdate}
          roomdata={bookingroomdata} 
          handleClose={()=>setAddbooking(false)}
        />:''}
      <Modal 
      id='mainbody'  
      isOpen={modal} 
      size='lg' 
      toggle={toggle} backdrop='static' keyboard={false}>
        <ModalHeader toggle={resetdata}><i className='fa fa-wrench'></i> {title}{' '}<small>{moment(date).format('DD MMMM YYYY')}</small></ModalHeader>
        <ModalBody >
        <Container>
        <table width='100%' className=" table table-bordered table-striped" id="myTable">
            <thead>
            {grp == 1 ?
                <tr style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>LOCATION</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>HOUSE</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>ROOM</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>GUEST NAME</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>PHONE</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>RES. TIME</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>ACTION</th>
                </tr>
              :''}
            {grp == 2 ?
                <tr style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>LOCATION</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>HOUSE</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>ROOM</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>GUEST NAME</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>PHONE</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>BOOKED</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>ACTION</th>
                </tr>
              :''}
            {grp == 3 ?
                <tr style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>LOCATION</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>HOUSE</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>ROOM</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>ACTION</th>
                </tr>
            : ''}
            {grp == 4 ?
                <tr style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>LOCATION</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>HOUSE</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>ROOM</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>GUEST NAME</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>GUEST NO.</th>
                </tr>
             :'' }
            {grp == 5 || grp == 6 ?
                <tr style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>LOCATION</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>HOUSE</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>ROOM</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>DESCRIPTION</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>STATE</th>
                </tr>
                :''}
               </thead>
            <tbody style={{fontSize:'0.8em', lineHeight:'100%', fontFamily: 'Tahoma', padding:'1px'}}>
            {tabl}
            </tbody>
            

        </table>
        </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={resetdata}>Cancel</Button>
          
        </ModalFooter>
      </Modal>
    </div>
  );
}
const mapStateToProps = (state, ownProps) => ({ 
    user:state.userReducer,
    maintenancecategorys:state.maintenancecategoryReducer,
    maintenancetypes:state.maintenancetypeReducer,
    maintenancetransactions:state.maintenancetransactionReducer.maintenancetransactions
  })
  
export default connect(mapStateToProps, { 
   deleteRoomtransaction, 
   registerRoomtransaction, 
   updateRoomtransaction, 
   getRoomtransactions, 
   getRoomtransaction, 
   getRoomdata 
  })(Modals)
