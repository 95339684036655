import { SERVER_URL } from 'actions/common';
import { callSuccess } from 'actions/common';
import { axiosConfig1 } from 'actions/common';
import { callError } from 'actions/common';
import Axios from 'axios';
import React,{useState, useEffect} from 'react'
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { Button, Card, CardHeader, CardBody, CardFooter, Form, FormGroup,  Input, Col, Label } from 'reactstrap';
import Calendar1 from 'views/Form/Calendar1';

const locs ={
    1:'Kainji',
    2:'Jebba'
  }

export default function AddGuest(props) {

    const {isEdit, data:row, st, staffid} = props || ''
  
    const [modal, setModal] = useState(false);
    const [id, setid] = useState(null)
    const [fullname, setfullname] = React.useState('bbbbbbbbb');
    const [role, setrole] = React.useState('');
    const [office, setoffice] = React.useState('');
    const [phone, setphone] = React.useState('000000000');
    const [organisation, setorganisation] = React.useState('Mainstream Energy Solution Ltd.');
    const [email, setemail] = React.useState('uuuuuuuuii');
    const [idtype, setIdtype] = React.useState('');
    const [idnumber, setIdnumber] = React.useState('');
    const [locationid, setlocationid] = React.useState(1);
    const [dates, setdates] = React.useState([]);
    const [pst, setPst] = useState(false)

    useEffect(()=>{
        setModal(st);
        if(isEdit){
            let {id, fullname, locationid, phone, email, idtype, dates, role} = row || ''
            setid(id)
            setfullname(fullname)
            setlocationid(locationid)
            setorganisation(idtype)
            setphone(phone)
            setemail(email)
            setrole(role)
            setdates(JSON.parse(dates))
        }else{
            setid(null)
            setorganisation("")
            setfullname("")
            setlocationid(1)
            setphone("")
            setemail("")
            setrole("")
            setdates([])
        }

    }, [isEdit, st])


    const resetdata= async() =>{
        handleClose();
    }
    const handleClose = ()=>{
        props.setModal(prev=>!prev)
        setfullname('');
        setphone('');
        setemail('')
    }

    // const toggle = () => setModal(!modal);
  
  
    const handleSubmit = () =>{
        let er = [];
        let i = fullname && fullname.length > 0 ? null : er.push(0);
        //i = phone && phone.length > 0 ? null : er.push(0);
        //i = email && email.length > 0 ? null : er.push(0);
       i = dates && dates.length > 0 ? null : er.push(0);
       // i = idnumber && idnumber.length > 0 ? null : er.push(0);
        
        if(er.length == 0 && staffid > 0)
        {
          let fd = new FormData();
          fd.append('fullname', fullname);
          fd.append('phone', phone);
          fd.append('dates', JSON.stringify(dates));
          fd.append('email', email);
          fd.append('idtype', organisation);
          fd.append('role', office);
          fd.append('idnumber', idnumber);
          fd.append('locationid', locationid);
          fd.append('location', locs[locationid]);
          fd.append('table', 'bookings')

          if(id > 0){
            fd.append('id', id);
            fd.append('cat', 'update');
          }else{
            fd.append('staffid', staffid);
            //fd.append('trackid', staffid);
            fd.append('cat', 'insert');
          }
          
          
          Axios.post(SERVER_URL+'/index.php', fd, axiosConfig1)
          .then(res=>{callSuccess('Done'); return true})
          .then(res=>props.load())
          .catch(err=>callError('Failed'))
          handleClose();
          callSuccess('Thank you. Please check your email to confirm your request has been registered');
        }else{
          callError('Incomplete information')
        }
        
    }


  return (
      <>
       
      <div style={{display:'flex', justifyContent:'center'}}>
      <Card  className="col-md-6 col-sm-8 col-xs-12" style={{zIndex:100, color:'#000000', fontWeight:'bold'}} isOpen={modal} fade   backdrop='static' keyboard={false}>
        <CardHeader toggle={resetdata}><i className='fa fa-lock'></i> Booking</CardHeader>
        <CardBody>
        <Form>
        <div className="row" style={{padding:'3px'}}>
            <Label style={{color:'#000000'}} className="col-md-4">FULLNAME</Label>
            <div className="col-md-8">
              <Input style={{color:'black'}} type="text" value={fullname} onChange={(e)=>setfullname(e.target.value)} className="form-control" placeholder="Fullname"/>
            </div>
        </div>
        <div className="row" style={{padding:'3px'}}>
        <Label style={{color:'#000000'}} className="col-md-4">Organisation</Label>
            <div className="col-md-8">
              <Input type="text" value={organisation} onChange={(e)=>setorganisation(e.target.value)} className="form-control" placeholder="organisation"/>
            </div>
        </div>
        <div className="row" style={{padding:'3px'}}>
            <Label style={{color:'#000000'}} className="col-md-4">Office</Label>
            <div className="col-md-8">
              <Input type="text" value={office} onChange={(e)=>setoffice(e.target.value)} className="form-control" placeholder="office"/>
            </div>
        </div>
        <div className="row" style={{padding:'3px'}}>
            <Label style={{color:'#000000'}} className="col-md-4">Email</Label>
            <div className="col-md-8">
              <Input type="text" value={email} onChange={(e)=>setemail(e.target.value)} className="form-control" placeholder="Email"/>
            </div>
        </div>
        <div className="row" style={{padding:'3px'}}>
            <Label style={{color:'#000000'}} className="col-md-4">Phone</Label>
            <div className="col-md-8">
              <Input type="text" value={phone} onChange={(e)=>setphone(e.target.value)} className="form-control" placeholder="Phone Number"/>
            </div>
        </div>
        <h6>
                      { Array.isArray(dates) && dates.length === 1 ? new Date(dates).toDateString() : ''} 
                      {Array.isArray(dates) &&  dates.length > 1 ? dates.map(prp=>{return new Date(prp).toDateString() + ' | '}):''}
                  </h6>
                  <div className='row' style={{display:'flex'}}>
                  <Label style={{color:'#000000'}} className="col-md-4">Select Date(s)</Label>
                  <p className="col-md-8">
                            <DatePicker
                            
                              style={{
                                maxWidth:"340px",
                                minWidth:"320px",
                                display:'block',
                                padding:'5px',
                                height:'35px',
                                borderRadius:'15px'
                              }}
                              multiple
                              autoFocus
                              zIndex={150}
                              value={dates}
                              onChange={(e)=>setdates(e)}
                              
                            />
                            </p>
                  </div>
                   
       
        <div className="row">
        <Label style={{color:'#000000'}} className="col-md-4">Location</Label>
            <div className="col-md-8">
              <select 
              type="text" 
              value={locationid} 
              onChange={(e)=>setlocationid(e.target.value)} 
              className="form-control" 
              placeholder="locationid"
              >
                  <option></option>
                  <option value={2}>Jebba</option>
                  <option value={1}>Kainji</option>
                  
              </select>
            </div>
        </div>
        </Form>
        </CardBody>
        <CardFooter>
          
          <Button color="warning" onClick={resetdata}>Close</Button> {' '}
          <Button color='info' onClick={handleSubmit}>Save</Button>
        </CardFooter>
      </Card>
      </div>
      </>
  )
}
