export const INVENTORYCATEGORY_GET_MULTIPLE =  "INVENTORYCATEGORY_GET_MULTIPLE"
export const INVENTORYCATEGORY_GET_ONE =  "INVENTORYCATEGORY_GET_ONE"
export const INVENTORYCATEGORY_REGISTER_SUCCESS =  "INVENTORYCATEGORY_REGISTER_SUCCESS"
export const INVENTORYCATEGORY_REGISTER_FAIL =  "INVENTORYCATEGORY_REGISTER_FAIL"
export const INVENTORYCATEGORY_LOADING =  "INVENTORYCATEGORY_LOADING"
export const INVENTORYCATEGORY_LOADING_ERROR =  "INVENTORYCATEGORY_LOADING_ERROR"
export const INVENTORYCATEGORY_ACTIVATE_FAIL =  "INVENTORYCATEGORY_ACTIVATE_FAIL"
export const INVENTORYCATEGORY_ACTIVATE_SUCCESS =  "INVENTORYCATEGORY_ACTIVATE_SUCCESS"
export const INVENTORYCATEGORY_UPDATE_SUCCESS =  "INVENTORYCATEGORY_UPDATE_SUCCESS"
export const INVENTORYCATEGORY_UPDATE_FAIL =  "INVENTORYCATEGORY_UPDATE_FAIL"
export const INVENTORYCATEGORY_DELETE_SUCCESS =  "INVENTORYCATEGORY_DELETE_SUCCESS"
export const INVENTORYCATEGORY_DELETE_FAIL =  "INVENTORYCATEGORY_DELETE_FAIL"
export const INVENTORYCATEGORY_EDIT =  "INVENTORYCATEGORY_EDIT"