import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { updateBooking } from "./../actions/booking";
import ReactRateComponent from'react-rate-component';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";
import { callSuccess } from "actions/common";

// core components

const SignUp = (props) => {
 
  const [rating, setRating] = React.useState(3);
  const [comment, setComment] = React.useState('');
  const [commentFocus, setCommentFocus] = React.useState(false);
  const [islog, setIslog] = React.useState(false);

  
  
  const changeRating=(newRating)=>{
    setRating(newRating);
  }
  const onSubmit = e => {
      e.preventDefault();
      let ids = parseInt(props.map)/111222333444555;
      if(ids)
      {
       let fd = new FormData();
       fd.append('id', ids);
       fd.append('rating', rating);
       fd.append('comment', comment);
       fd.append('cat' , 'update');
       fd.append('table' , 'room_comments');
       props.updateBooking(fd);
       callSuccess('Thank You.')
       
      }
      setIslog(true)
      
     
  };
  
if(islog){
    return <Route  to='/home'/>;
}
 
  return (
    <>
      <div 
        className="section section-signup"
        style={{
          backgroundImage: "url(" + process.env.REACT_APP_BG + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          minHeight: "700px"
        }}
      >
         <h3 className='text-light m-auto' style={{textAlign:'center'}}>MESL Guest Houses</h3>
         <h3 className='text-light m-auto' style={{textAlign:'center', fontFamily:'Poiret One'}}>Kainji & Jebba</h3>
          <Container>
            <Col className="ml-auto mr-auto" md="5">
              <Card className="card-login card-plain">
                <Form action="" className="form" method="" onSubmit={onSubmit}>
                  <CardHeader className="text-center">
                  <h5 className='text-light'> <b>Guest</b> Feedback <b>Form</b></h5>
                  <h6 className='text-light'>Rate Us</h6>
                  </CardHeader>
                  <CardBody style={{ marginTop:5 }}>
                  <ReactRateComponent
                        defaultValue={rating}
                        showCount={false}
                        onChange={changeRating}
                        size='70'
                    />
                    <h6 className='text-light' style={{fontFamily:''}}>Any Complaint</h6>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (commentFocus ? " input-group-focus" : "")
                      }
                    >
                      
                      <Input
                        placeholder="Help us serve you better.."
                        type="textarea"
                        name="comment"
                        style={{color:'white', fontWeight:'revert'}}
                        defaultValue={comment}
                        onChange={(e)=>setComment(e.target.value)}
                        onFocus={() => setCommentFocus(true)}
                        onBlur={() => setCommentFocus(true)}
                      ></Input>
                    </InputGroup>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      block
                      className="btn"
                      color="info"
                      type="submit"
                      size="lg"
                    >
                      Submit
                    </Button>
                    <div className="pull-left">
                    <div className="logo-container" style={{ marginBottom:5 }}>
                        <br/>
                      <img
                        alt="..."
                        src={require("assets/img/logo.png")}
                        height='20px'
                      ></img>
                      <span style={{color:'yellow'}}>Powered By {process.env.REACT_APP_WEBSITE_NAME}</span>
                    </div>
                    </div>
                  </CardFooter>
                </Form>
              </Card>
              
            </Col>
          </Container>
        
      </div>
    </>
  );
}
const mapStateToProps=(state, ownProps)=>({
  map: ownProps.match.params.map,
  user:state.userReducer.user
})
export default connect(mapStateToProps,{updateBooking})(SignUp);
