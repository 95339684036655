import axios from 'axios';
import {
    USERJOBTRANSACTION_GET_ONE,
    USERJOBTRANSACTION_GET_MULTIPLE,
    USERJOBTRANSACTION_REGISTER_SUCCESS,
    USERJOBTRANSACTION_REGISTER_FAIL,
    USERJOBTRANSACTION_LOADING,
    USERJOBTRANSACTION_LOADING_ERROR,
    USERJOBTRANSACTION_UPDATE_SUCCESS,
    USERJOBTRANSACTION_UPDATE_FAIL,
    USERJOBTRANSACTION_DELETE_SUCCESS,
    USERJOBTRANSACTION_DELETE_FAIL,
    USERJOBTRANSACTION_EDIT,
} from "./../types/userjobtransaction";
import { MAIN_TOKEN, SERVER_PATHS, axiosConfig1, axiosConfig } from './common';

let TABLE_NAME = 'user_job_transactions';
const path = SERVER_PATHS + '/staff.php';;

let params = {
    data:{},
    cat:'all',
    table:TABLE_NAME,
    token:MAIN_TOKEN
  }
//GET ALL USERJOBTRANSACTION 
export const getUserjobtransactions = params => (dispatch, getState) => {
    //SET PAGE LOADING
    params.token = MAIN_TOKEN;
    dispatch({type : USERJOBTRANSACTION_LOADING});
        axios.get(path, {params}, axiosConfig)
            .then(res => {                                                                                                                                                                                                                                        
                dispatch({
                    type: USERJOBTRANSACTION_GET_MULTIPLE,
                    payload: res.data
                })
            })
            .catch(err => {
                dispatch({
                    type : USERJOBTRANSACTION_LOADING_ERROR,
                    payload:err
                })
            })
};
//GET SINGLE USERJOBTRANSACTION 
export const getUserjobtransaction = id => (dispatch, getState) => {
    //SET PAGE LOADING
    dispatch(
        {
        type : USERJOBTRANSACTION_GET_ONE,
        payload: id
    });  
};
//USERJOBTRANSACTION DELETE
export const deleteUserjobtransaction = (dat, id) => (dispatch, getState) =>{
    
   
    axios.post(path, dat, axiosConfig)
        .then(res => {
            dispatch({
                type: USERJOBTRANSACTION_DELETE_SUCCESS,
                payload: id
            })
        })
        .catch(err => {
            dispatch({
                type : USERJOBTRANSACTION_DELETE_FAIL,
                payload : err
            })
        })
        
}
//USERJOBTRANSACTION REGISTER
export const registerUserjobtransaction = data => dispatch => {
   
    axios.post(path, data, axiosConfig1)
        .then(res => {
            dispatch({
                type: USERJOBTRANSACTION_REGISTER_SUCCESS,
                payload: res.data.data
            })
        })
        .catch(err => {
            dispatch({
                type : USERJOBTRANSACTION_REGISTER_FAIL,
                payload: err
            })
        })
};
 //USERJOBTRANSACTION UPDATE
export const updateUserjobtransaction = (data) => (dispatch, getState) => {
    //body
    axios.post(path, data, axiosConfig1)
        .then(res => {
            dispatch({
                type: USERJOBTRANSACTION_UPDATE_SUCCESS,
                payload: res.data.data
            })
        })
        .catch(err => {
            dispatch({
                type : USERJOBTRANSACTION_UPDATE_FAIL,
                payload: err
            })
        })
};
