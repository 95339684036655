export const INVENTORYTYPE_GET_MULTIPLE =  "INVENTORYTYPE_GET_MULTIPLE"
export const INVENTORYTYPE_GET_ONE =  "INVENTORYTYPE_GET_ONE"
export const INVENTORYTYPE_REGISTER_SUCCESS =  "INVENTORYTYPE_REGISTER_SUCCESS"
export const INVENTORYTYPE_REGISTER_FAIL =  "INVENTORYTYPE_REGISTER_FAIL"
export const INVENTORYTYPE_LOADING =  "INVENTORYTYPE_LOADING"
export const INVENTORYTYPE_LOADING_ERROR =  "INVENTORYTYPE_LOADING_ERROR"
export const INVENTORYTYPE_ACTIVATE_FAIL =  "INVENTORYTYPE_ACTIVATE_FAIL"
export const INVENTORYTYPE_ACTIVATE_SUCCESS =  "INVENTORYTYPE_ACTIVATE_SUCCESS"
export const INVENTORYTYPE_UPDATE_SUCCESS =  "INVENTORYTYPE_UPDATE_SUCCESS"
export const INVENTORYTYPE_UPDATE_FAIL =  "INVENTORYTYPE_UPDATE_FAIL"
export const INVENTORYTYPE_DELETE_SUCCESS =  "INVENTORYTYPE_DELETE_SUCCESS"
export const INVENTORYTYPE_DELETE_FAIL =  "INVENTORYTYPE_DELETE_FAIL"
export const INVENTORYTYPE_EDIT =  "INVENTORYTYPE_EDIT"