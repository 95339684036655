import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { registerUsertype, updateUsertype } from '../../actions/usertype';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, FormText,  Input, Col, Label, Container } from 'reactstrap';
import { callError, access } from 'actions/common';


const Modals = (props) => {
  
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(null);
  const [accessx, setAccessx] = useState([[], [], [], [], [], [], []]);

  const resetdata= async() =>{
    toggle();
    setId('');
    props.handleClose();
}

  const toggle = () => setModal(!modal);
  
  useEffect(() => {
    setModal(props.st);
    if(parseInt(props.mid) > 0 )
    {
      setId(parseInt(props.mid));
      let d = props.data.access !== '' && props.data.access !== null && props.data.access !== undefined ? JSON.parse(props.data.access):[[],[],[],[],[],[],[]];
      setAccessx(d);
    }

},[props.mid]);

  const handleSubmit = (e) =>{
        e.preventDefault();
      
        let fd = new FormData();
        let accc = accessx
        let ac= JSON.stringify(accc);
        fd.append('access', ac);
        fd.append('table', 'user_types');
        fd.append('rowzid', props.user.user.id);
        fd.append('locateid', 'access');

        if(id && id > 0)
        {
          fd.append('id', id);
          fd.append('cat', 'update');
          props.updateUsertype(fd);
        }
        resetdata();
  }
  
  const handleChanger = (e, prp, pp) =>{
    let ch = e.target.checked;
    let acc = [...accessx];
    if(ch === true)
    {
      if(Array.isArray(acc[prp]))
      {
        acc[prp].push(pp);
        setAccessx(acc);
      }

    }
    else{
      if(Array.isArray(acc[prp.toString()]))
      {
        let re = acc[prp.toString()];
        let rr = re.filter(rw=>parseInt(rw)  !== parseInt(pp)); 
        acc[prp.toString()] = rr;
        setAccessx(acc);
      }
    }
}


  
 
  let editColor = 'primary';


  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false}>
        <ModalHeader toggle={resetdata}><i className='fa fa-user'></i> Staff Data</ModalHeader>
        <ModalBody>
        <Form>
<div class="accordion" id="accordionExample">
  {Object.keys(access).map((prp, ind)=>{
    let pr = access[prp];
    return <div key={ind} class="card z-depth-0 bordered">
    <div class="card-header my-0 py-0" id={`heading${prp}`}>
      <h5 class="mb-0">
        <button class="btn btn-link my-0 py-1" type="button" data-toggle="collapse" data-target={`#collapse${prp}`}
          aria-expanded="true" aria-controls="collapseOnea">
          {pr.name}
        </button>
      </h5>
    </div>
    <div id={`collapse${prp}`} class="collapse" aria-labelledby="headingOne"
      data-parent="#accordionExample">
      <div class="card-body">
          <Container style={{backgroundColor:'#cff'}}>
        <table width='100%'>
            {
              Object.keys(pr.data).map((pp, ii)=>{
              let pr1 = pr.data[pp];
              return <tr key={`yc${ii}`}>
                    <th>{pr1}</th>
                      <td>
                        <input type='checkbox' defaultChecked={Array.isArray(accessx[prp]) && accessx[prp].includes(pp) ? 'checked' : ''} value={pp} num={prp} onClick={(e)=>handleChanger(e, prp, pp)} />
                      </td>
                    </tr>
            })}
        </table>
        </Container>
      </div>
    </div>
  </div>
  })}
 
</div>
        </Form>
        </ModalBody>
        <ModalFooter>
          <Button color={editColor} onClick={handleSubmit}>Submit</Button>{' '}
          <Button color="secondary" onClick={resetdata}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
const mapStateToProps = (state, ownProps) => ({ 
    user:state.userReducer,
    usercategorys:state.usercategoryReducer,
    usertypes:state.usertypeReducer
  })
  
export default connect(mapStateToProps, { registerUsertype, updateUsertype })(Modals)
