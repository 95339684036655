
import { callError, axiosConfig, axiosConfig1, SERVER_URL } from 'actions/common'
import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBed, faEnvelope, faPencil, faRecycle, faTrash, faUserPen, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { callSuccess } from 'actions/common';
import { redirect, useNavigate } from 'react-router-dom';
import { Alert, Button, Card, CardBody, Form } from 'reactstrap';
import { validatejson } from 'actions/common';
import { object } from 'prop-types';

const Add = <FontAwesomeIcon icon={faUserPlus} />
const Trash = <FontAwesomeIcon icon={faTrash} />
const Edit = <FontAwesomeIcon icon={faUserPen} />
const Assign = <FontAwesomeIcon icon={faBed} />
const Mail = <FontAwesomeIcon icon={faEnvelope} />
const Reload = <FontAwesomeIcon icon={faRecycle} />




export default function GuestApplied(props) {

    const nav = useNavigate()

    const [page, setpage] = useState(0)
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [femail, setfemail] = useState('')
    const [fullname, setfullname] = useState('')
    const [fphone, setfphone] = useState('')
    const [datas, setdatas] = useState({})

    const [registerLoading, setregisterLoading] = useState(false)
    const [loginLoading, setloginLoading] = useState(false)

    useEffect(() => {
        if(localStorage.getItem('auths')  == 1)
        {
            let f = localStorage.getItem('userx') || '{}'; 
            f = validatejson(f) ? JSON.parse(f) : {}
            if(Array.isArray(Object.keys(f)) && f.hasOwnProperty('id')){nav(`./${f.id}`, {state:f})}
            else{nav(process.env.PUBLIC_URL+"/guestmultiple")}
        }
    }, [])
    
    

    const logOut = ()=>{
        localStorage.removeItem('userx');
        localStorage.removeItem('auths');
        nav(process.env.PUBLIC_URL+"/guestmultiple")
    }
    
   
    const registerButton = ()=>{
       
        setregisterLoading(true)
        const fd = new FormData()
        fd.append('email', femail)
        fd.append('fullname', fullname)
        fd.append('phone', fphone)
        fd.append('cat', 'registerx')
        Axios.post(SERVER_URL+"/index.php", fd, axiosConfig1)
        .then(res=>{
            setdatas(res.data.data)
            setregisterLoading(false)
            callSuccess()
            setpage(0)
        })
        .catch(err=>{
            setregisterLoading(false)
            callError('Please check your network.')
        })  
    }

    const loginButton = ()=>{
        
        setloginLoading(true)
        const fd = new FormData()
        fd.append('email', email)
        fd.append('code', password)
        fd.append('cat', 'loginx')
        Axios.post(SERVER_URL+"/index.php", fd, axiosConfig1)
        .then(res=>{
            callSuccess()
            let d = res.data.data
            localStorage.setItem('auths', JSON.stringify(1));
            localStorage.setItem('userx', JSON.stringify(d));
            setloginLoading(false)
            nav(`./${d.id}`,{state:{val:d}} )
        })
        .catch(err=>{
            setloginLoading(false)
            callError('Please check your network.')
        })  
    }
   
  
    
  return (
      <>
      {page == 0 ?
        <div className="container" style={{margin:'auto'}}>
            <div className="row justify-content-center " style={{height:'100vh', fontSize:'1rem', fontWeight:'bolder',  margin:'auto'}}>
            <div className="col-md-6 col-sm-8 col-xs-12 " style={{margin:'auto'}}>
                <div className="card h-100" style={{border:"2px solid blue"}}>
                <div className="card-header text-center h2">
                    Login
                </div>
                <div className="card-body ">
                   {Array.isArray(Object.keys(datas)) && Object.keys(datas).length > 0 ? <Alert>
                        {`Your Login Code is ${datas.code}. Also check your mail.`}
                    </Alert>:null}
                    {!loginLoading ?
                    <Form  >
                    <div className="mb-3">
                        <label for="femail" className="form-label">Email</label>
                        <input type="email" value={email} onChange={(e)=>setemail(e.target.value)} className="form-control" id="femail" name="femail" required />
                    </div>
                    <div className="mb-3">
                        <label for="fphone" className="form-label">Code</label>
                        <input type="" value={password} onChange={(e)=>setpassword(e.target.value)} className="form-control" id="fphone" name="fphone" required />
                    </div>
                    <div className="d-grid gap-2">
                        <button type="button" onClick={()=>loginButton()} className="btn btn-primary">Login</button>
                    </div>
                    <div className="d-grid gap-2" onClick={()=>setpage(1)}>
                        <a type="button"> No Account ? Click to Register here</a>
                    </div>
                    </Form>:
                    <Card>
                        <CardBody>
                            <h3>Processing .. Login</h3>
                        </CardBody>
                    </Card> }
                </div>
                </div>
            </div>
            </div>
        </div>:
        <div className="container" style={{margin:'auto'}}>
            <div className="row justify-content-center " style={{height:'100vh', fontSize:'1rem', fontWeight:'bolder',  margin:'auto'}}>
            <div className="col-md-6 col-sm-8 col-xs-12 " style={{margin:'auto'}}>
                <div className="card h-100" style={{border:"2px solid blue"}}>
                <div className="card-header text-center h2">
                    Register
                </div>
                <div className="card-body ">
                {!registerLoading ? <Form >
                    <div className="mb-3">
                        <label for="Fullname" className="form-label">Fullname</label>
                        <input type="text" value={fullname} onChange={(e)=>setfullname(e.target.value)} className="form-control" id="Fullname" name="Fullname" required />
                    </div>
                    <div className="mb-3">
                        <label for="femail" className="form-label">Email</label>
                        <input type="email" value={femail} onChange={(e)=>setfemail(e.target.value)} className="form-control" id="femail" name="femail" required />
                    </div>
                    <div className="mb-3">
                        <label for="fphone" className="form-label">Phone</label>
                        <input type="" value={fphone} onChange={(e)=>setfphone(e.target.value)} className="form-control" id="fphone" name="fphone" required />
                    </div>
                    <div className="d-grid gap-2">
                        <Button type="button" onClick={()=>registerButton()} className="btn btn-primary">Register</Button>
                    </div>
                    <div className="d-grid gap-2" onClick={()=>setpage(0)}>
                        <a type="button"> Have an Account ? Click to Login</a>
                    </div>
                </Form>:
                <Card>
                    <CardBody>
                        <h3>Processing</h3>
                    </CardBody>
                </Card> }
                </div>
                </div>
            </div>
            </div>
        </div>}
    </>
  )
}
