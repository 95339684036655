import {
    USERJOB_GET_MULTIPLE,
    USERJOB_GET_ONE,
    USERJOB_REGISTER_SUCCESS,
    USERJOB_REGISTER_FAIL,
    USERJOB_LOADING,
    USERJOB_LOADING_ERROR,
    USERJOB_ACTIVATE_FAIL,
    USERJOB_ACTIVATE_SUCCESS,
    USERJOB_UPDATE_SUCCESS,
    USERJOB_UPDATE_FAIL,
    USERJOB_DELETE_SUCCESS,
    USERJOB_DELETE_FAIL,
    USERJOB_EDIT
} from "../types/userjob";

let userjobStore = JSON.parse(localStorage.getItem('userjob'))

const initialState = {
    isLoading: false,
    userjobs: userjobStore ? userjobStore : [],
    userjob:{},
    msg: null,
    isEdit:-1,
    ref:null,
}

const changeState = (aluu, actid) =>
{
    let newUserjob = [...aluu];
    newUserjob.forEach(alu => {
        if(alu.id == actid.id){
            alu.is_active = actid.is_active
        }
    });
    return newUserjob;
}


export default function(state = initialState, action){
    switch (action.type) {
        case USERJOB_EDIT:
            return {
                ...state,
                isEdit : action.payload
        };
        case USERJOB_LOADING:
            return {
                ...state,
                isLoading : true
            };
        case USERJOB_GET_MULTIPLE:
            localStorage.setItem('userjob', JSON.stringify(action.payload));
            return {
                ...state,
                userjobs : action.payload,
                msg:'DONE!!!'
            };
        case USERJOB_GET_ONE:
            let all = [...state.userjobs];
            let ses = all.filter(row=>parseInt(row.id) === parseInt(action.payload))[0];
            console.log(ses, action.payload );
            return {
                ...state,
                userjob : ses,
                MSG:"DONE!!!"
            };
        case USERJOB_REGISTER_SUCCESS:
            localStorage.setItem('userjob', JSON.stringify([...state.userjobs, action.payload]));
            return {
                ...state,
                userjobs: [...state.userjobs, action.payload],
                msg:action.msg
            }; 
        case USERJOB_ACTIVATE_SUCCESS:
            let ac = changeState(state.userjobs, action.payload);
            localStorage.setItem('userjob', JSON.stringify(ac));
            return{
                ...state,
                msg:'DONE!!!',
                userjobs: ac
            }
        case USERJOB_DELETE_SUCCESS:
            let rem = state.userjobs.filter(cat => cat.id != action.payload);
            localStorage.setItem('userjob', JSON.stringify(rem));
            return{
                ...state,
                msg:'DONE!!!',
                userjobs: rem
            }
        case USERJOB_UPDATE_SUCCESS:
            const findInd = state.userjobs.findIndex(cat => cat.id == action.payload.id);
            let newState = [...state.userjobs];
            newState[findInd] = action.payload;
            localStorage.setItem('userjob', JSON.stringify(newState));
            return {
                ...state,
                ...action.payload,
                userjobs : newState,
                userjob:action.payload
            }; 
        case USERJOB_LOADING_ERROR:
        case USERJOB_ACTIVATE_FAIL:
        case USERJOB_REGISTER_FAIL:
        case USERJOB_DELETE_FAIL:
        case USERJOB_UPDATE_FAIL:

            return {
                ...state,
                isLoading: false,
                msg: action.msg
            };
        default:
            return state;
    }

}