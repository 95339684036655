import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Provider }from 'react-redux';
import store from "./store";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v1.2.0";

import "assets/css/dash.css";
import "assets/css/demo.css";
import "assets/datatables.css"; 
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import "assets/css/mine.css";
import 'animate.css/animate.css'

import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import AdminLayout from "layouts/Admin.jsx";
import AccountLayout from "layouts/Account.jsx";
import GuesttAdminLayout from "layouts/GuestAdmin.js";
import GuesttAdminMultiple from "layouts/GuestAdminMultiple.js";
import GuesttAdminMultipleLayout from "layouts/GuestAdminMultipleLayout.js";

import InventoryLayout from "views/InventoryPage.js";
import InventorysLayout from "views/Inventory/InventoryPages.js";
import InventoryHistoryLayout from "views/Inventory/InventoryHistory.js";
import InventoryReportLayout from "views/Inventory/InventoryReport.js";

import MaintenanceLayout from "views/MaintenancePage.js";
import MaintenancesLayout from "views/Maintenance/MaintenancePages.js";
import MaintenanceHistoryLayout from "views/Maintenance/MaintenanceHistory.js";
import MaintenanceReportLayout from "views/Maintenance/MaintenanceReport.js";

import GuestsLayout from "views/Guest/GuestPages.js";
import GuestComment from "views/Guest/GuestComment.js";
import GuestBooking from "views/Guest/GuestBooking.js";

import RoomLayout from "views/RoomPage.js";
import RoomsLayout from "views/Room/RoomPages.js";
import RoomHistoryLayout from "views/Room/RoomHistory.js";
import RoomReportLayout from "views/Room/RoomReport.js";

import UserPage from "views/UserPage.js";
import UserPages from "views/User/UserPages.js";
import UserProfile from "views/User/UserProfile.js";
import UserUser from "views/User/UserUser.js";
import UserJob from "views/User/UserJob.js";
import UserJobs from "views/User/UserJobs.js";
import UserLeave from "views/User/UserLeave.js";
import UserLeaves from "views/User/UserLeaves.js";
import UserLog from "views/User/UserLog.js";
import UserLogs from "views/User/UserLogs.js";

import HomeLayout from "views/HomePage.js";
import FeedBack from "views/FeedbackPage.js";
import Login from "views/EmployeeLoginPage.js";
import Index from "views/Index.js";

const hist = createBrowserHistory();

const App = () =>{
  return <Provider store={ store }>
        <BrowserRouter basename="/hms" history={hist}>
          <React.Suspense>
          <Routes>
              <Route path="/admin" element={ <AdminLayout history={hist} />} />
              <Route path="/guest" element={ <GuesttAdminLayout history={hist} />} />
              <Route path="/guestmultiple" element={ <GuesttAdminMultiple history={hist} />} />
              <Route path="/guestmultiple/:id" element={ <GuesttAdminMultipleLayout history={hist} />} />
              <Route path="/account/*" element={<AccountLayout history={hist} />} />
              <Route path="/account/" element={ <AccountLayout history={hist} />} >
              <Route path="/account/user" element={ <UserPage history={hist} />} />
              <Route exact path="/account/users" element={ <UserPages history={hist} />} />
              <Route exact path="/account/userx/:id" element={ <UserProfile history={hist} />} />
              <Route exact path="/account/userm" element={ <UserUser history={hist} />} />
              <Route exact path="/account/userlog/:id" element={ <UserLog history={hist} />} />
              <Route exact path="/account/userjob" element={ <UserJob history={hist} />} />
              <Route exact path="/account/userjobs/:id" element={ <UserJobs history={hist} />} />
              <Route exact path="/account/userLeave/:id" element={ <UserLeave history={hist} />} />
              <Route exact path="/account/userLeaves" element={ <UserLeaves history={hist} />} />
              <Route exact path="/account/userLog/:id" element={ <UserLog history={hist} />} />
              <Route exact path="/account/userLogs" element={ <UserLogs history={hist} />} />
              <Route path="/account/home" element={ <HomeLayout history={hist} />} />
              <Route path="/account/inventory" element={ <InventoryLayout history={hist} />} />
              <Route path="/account/inventorys/:id" element={ <InventorysLayout history={hist} />} />
              <Route path="/account/inventoryhistory/:id/:cat" element={ <InventoryHistoryLayout history={hist} />} />
              <Route path="/account/inventoryreport/:id/:cat" element={ <InventoryReportLayout history={hist} />} />
              <Route path="/account/maintenance" element={ <MaintenanceLayout history={hist} />} />
              <Route path="/account/maintenances/:id" element={ <MaintenancesLayout history={hist} />} />
              <Route path="/account/maintenancehistory/:id/:cat" element={ <MaintenanceHistoryLayout history={hist} />} />
              <Route path="/account/maintenancereport/:id/:cat" element={ <MaintenanceReportLayout history={hist} />} />
              <Route path="/account/guests" element={ <GuestsLayout history={hist} />} />
              <Route path="/account/guestcomment" element={ <GuestComment history={hist} />} />
              <Route path="/account/guestbooking" element={ <GuestBooking history={hist} />} />
              <Route path="/account/room" element={ <RoomLayout history={hist} />} />
              <Route path="/account/rooms/:id" element={ <RoomsLayout history={hist} />} />
              <Route path="/account/roomhistory/:id/:cat" element={ <RoomHistoryLayout history={hist} />} />
              <Route path="/account/roomreport/:id/:cat" element={ <RoomReportLayout history={hist} />} />
              </Route>
              
              <Route path="/feedback/:map" element={ <FeedBack  />} />
              <Route path="/login" element={ <Login  />} />
              <Route path="/index" element={ <Index  />} />
              
              <Route path="/account/*" element={<AccountLayout />} />
              <Route path="/" element={ <Index  />} />
              <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
          </Routes>
          </React.Suspense>
        </BrowserRouter>
  </Provider>
}
export default App;

