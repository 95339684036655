import React, { useEffect, useState } from 'react'
import GuestApplied from './GuestApplied'
import GuestApproved from './GuestApproved'
import { Tab, Tabs, TabContent, TabPane, Nav, NavItem, NavLink, Container, Row, Col } from 'reactstrap';
import GuestAssigned from './GuestAssigned';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faWarning } from '@fortawesome/free-solid-svg-icons';
import { validatejson } from 'actions/common';


export default function GuestTabs() {
  const [activeTab, setActiveTab] = useState('1');
  const [user, setuser] = useState({})

  const {staffid, is_admin} = user || null

  const toggle = (tabId) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
    }
  };

  useEffect(() => {
    if(localStorage.getItem('auths')  == 1)
    {
        let f = localStorage.getItem('userx') || '{}'; 
        f = validatejson(f) ? JSON.parse(f) : {}
        if(Array.isArray(Object.keys(f)) && f.hasOwnProperty('id'))
        {
          setuser(f)
        }
        
    }
  },[])
  
  return (
      <>
       {is_admin == 1 ? <div style={{marginTop:'5rem'}}>
        <Nav tabs style={{marginTop:'5rem', fontSize:'5rem', color:'#000'}}>
          <NavItem>
            <NavLink
               style={{marginTop:'1rem', fontSize:'1rem', color:'#fff'}}
               className={activeTab == 1? 'bg-dark': "bg-info"}
              onClick={() => toggle('1')}
            >
              Active Request
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
            style={{marginTop:'1rem', fontSize:'1rem', color: '#fff'}}
            className={activeTab == 2? 'bg-dark': "bg-info"}
             onClick={() => toggle('2')}
            >
              Approved Request
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
            style={{marginTop:'1rem', fontSize:'1rem', color:'#fff'}}
            className={activeTab == 3? 'bg-dark': "bg-info"}
             onClick={() => toggle('3')}
            >
             Rooms Assigned
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <GuestApplied staffid={staffid} is_admin={is_admin} />
          </TabPane>
          <TabPane tabId="2">
           <GuestApproved staffid={staffid} is_admin={is_admin}  />
          </TabPane>
          <TabPane tabId="3">
           <GuestAssigned staffid={staffid} is_admin={is_admin}  />
          </TabPane>
        </TabContent>
        </div>:
        <Container style={{marginTop:'9rem',  color:'#000'}}>
           <Row style={{display:'flex', flexD:'column', justifyContents:'center', textAlign:'center'}}>
             <Col xs={12}>
               <FontAwesomeIcon color="yellow" size="4x" icon={faWarning} />
             </Col>
             <Col xs={12}>
                <h2>You don't have the required permission to approve request</h2>
             </Col>
             <Col xs={12}>
                <h3><b>Contact Admin </b></h3>
             </Col>
             <Col xs={12}>
                <a className='btn btn-lg btn-info' href="./guestmultiple"> BACK TO HOME </a>
             </Col>
           </Row>
        </Container>}
      
    </>
  )
}
