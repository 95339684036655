export const USERLEAVETRANSACTION_GET_MULTIPLE =  "USERLEAVETRANSACTION_GET_MULTIPLE"
export const USERLEAVETRANSACTION_GET_ONE =  "USERLEAVETRANSACTION_GET_ONE"
export const USERLEAVETRANSACTION_REGISTER_SUCCESS =  "USERLEAVETRANSACTION_REGISTER_SUCCESS"
export const USERLEAVETRANSACTION_REGISTER_FAIL =  "USERLEAVETRANSACTION_REGISTER_FAIL"
export const USERLEAVETRANSACTION_LOADING =  "USERLEAVETRANSACTION_LOADING"
export const USERLEAVETRANSACTION_LOADING_ERROR =  "USERLEAVETRANSACTION_LOADING_ERROR"
export const USERLEAVETRANSACTION_ACTIVATE_FAIL =  "USERLEAVETRANSACTION_ACTIVATE_FAIL"
export const USERLEAVETRANSACTION_ACTIVATE_SUCCESS =  "USERLEAVETRANSACTION_ACTIVATE_SUCCESS"
export const USERLEAVETRANSACTION_UPDATE_SUCCESS =  "USERLEAVETRANSACTION_UPDATE_SUCCESS"
export const USERLEAVETRANSACTION_UPDATE_FAIL =  "USERLEAVETRANSACTION_UPDATE_FAIL"
export const USERLEAVETRANSACTION_DELETE_SUCCESS =  "USERLEAVETRANSACTION_DELETE_SUCCESS"
export const USERLEAVETRANSACTION_DELETE_FAIL =  "USERLEAVETRANSACTION_DELETE_FAIL"
export const USERLEAVETRANSACTION_EDIT =  "USERLEAVETRANSACTION_EDIT"