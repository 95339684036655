export const USERJOB_GET_MULTIPLE =  "USERJOB_GET_MULTIPLE"
export const USERJOB_GET_ONE =  "USERJOB_GET_ONE"
export const USERJOB_REGISTER_SUCCESS =  "USERJOB_REGISTER_SUCCESS"
export const USERJOB_REGISTER_FAIL =  "USERJOB_REGISTER_FAIL"
export const USERJOB_LOADING =  "USERJOB_LOADING"
export const USERJOB_LOADING_ERROR =  "USERJOB_LOADING_ERROR"
export const USERJOB_ACTIVATE_FAIL =  "USERJOB_ACTIVATE_FAIL"
export const USERJOB_ACTIVATE_SUCCESS =  "USERJOB_ACTIVATE_SUCCESS"
export const USERJOB_UPDATE_SUCCESS =  "USERJOB_UPDATE_SUCCESS"
export const USERJOB_UPDATE_FAIL =  "USERJOB_UPDATE_FAIL"
export const USERJOB_DELETE_SUCCESS =  "USERJOB_DELETE_SUCCESS"
export const USERJOB_DELETE_FAIL =  "USERJOB_DELETE_FAIL"
export const USERJOB_EDIT =  "USERJOB_EDIT"