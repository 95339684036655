import React from "react";
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import {getRoomtransactioncomment, getRoomtransaction, registerRoomtransaction, updateRoomtransaction, deleteRoomtransaction } from '../../actions/roomtransaction';


import $ from "jquery";
import axios from "axios";
import { callError, path, axiosConfig1 } from "actions/common";
import { Col, Row } from "reactstrap";

class BottomCard extends React.Component {
   constructor(props){
       super(props);
       this.state ={
           id:null,
           cat:null,
            grp:null,
            afid:false,
            tfid:false,
            cfid:false,
            mfid:false,
            ifid:false,
            mid:null,
            fid:false,
            data:{},
            subtopic:'All Categories',
            started:'',
            ended:'',
            daterange:'',
            isshown:{}

       }
   }

    componentDidMount(){
        //GET PROPS
        let id = this.props.id;
        let dts = new Date();
        let started = new Date(dts.getFullYear(), dts.getMonth(), 1);
        let ended = new Date(dts.getFullYear(), dts.getMonth() + 1, 0);
        
       
        
        let daterange = ' '+moment(started).format('MMMM Do YYYY') + ' - ' + moment(ended).format('MMMM Do YYYY');
        
        this.setState({
            id:id, 
            cat:id,
            started:started,
            ended:ended,
            daterange:daterange
        });
            
       let dt = {
            'starts':started,
            'ends':ended,
            'locationid':this.props.user.location
        }
        let params = {
            data:JSON.stringify(dt),
            cat:'roomcomment',
            table:'room_comments'
        }
         let ctn = daterange;
         this.setState({subtopic:ctn});
         this.props.getRoomtransactioncomment(params);

    }
    
    componentDidUpdate(prevProps, prevState){
        //GET PROPS
        if(prevProps.id !== this.props.id || prevProps.user.location !== this.props.user.location ){
        let id = this.props.id;
        let choicestarted = this.props.choicestarted ? this.props.choicestarted : null;
        let choiceended = this.props.choiceended ? this.props.choiceended : null;
        let started = this.props.defaultstarted ? this.props.defaultstarted : null;
        let ended = this.props.defaultended ? this.props.defaultended : null;
        
        if(choicestarted !== null && choiceended !== null && choiceended > choicestarted)
        {
            started = choicestarted;
            ended = choiceended;
        }

        let daterange = ' '+moment(started).format('MMMM Do YYYY') + ' - ' + moment(ended).format('MMMM Do YYYY');
        
        this.setState({
            id:id, 
            cat:id,
            started:started,
            ended:ended,
            daterange:daterange
        });
        let dt = {
            'starts':started,
            'ends':ended,
            'locationid':this.props.user.location
        }
        let params = {
            data:JSON.stringify(dt),
            cat:'roomcomment',
            table:'room_comments'
        }
     
     this.setState({subtopic:daterange});
     this.props.getRoomtransactioncomment(params);

        $('#myTablex').DataTable();
         
        }
            

    }
   
    componentWillUnmount(){
        $('#myTablex').DataTable().destroy();
    }

    loadShown = (id, cat) =>{
          let ar = {};
          if(cat === 0)
          {
              ar[id] = true;
          }else{
              ar[id] = false;
          }
          this.setState({isshown:ar});
    }
    lunchDate = (func, func1, locs) =>{
        Swal.fire({
            title: 'Pick a date range:',
            type: 'question',
            html: '<input id="datepicker" type="date"  class="swal2-input"><br/><input id="datepicker1" type="date"  class="swal2-input">',
            customClass: 'swal2-overflow',
            
          }).then(function(result) {
              if(result.value)
              {
                  let v = $('#datepicker').val();
                  let v1 = $('#datepicker1').val();
                  let params = {
                    data:JSON.stringify(
                    {
                        'starts':moment(new Date(v)).format('YYYY-MM-DD'), 
                        'ends':moment(new Date(v1)).format('YYYY-MM-DD'), 
                        'locationid':locs
                    }),
                    cat:'roomcomment',
                    table:'room_comments'
                }
                func(params);
                func1(v, v1);
              }
          });
    }
    retState = (dt, dt1) =>{
        this.setState({starts:new Date(dt), ends:new Date(dt1)});
    }
    
    render() {
        let { subtopic } = this.state  || '';
        let data = this.props.roomtransactions.roomtransactioncomment;
        let tabl = data && Array.isArray(data) ? data.filter(rw=>rw !== null ).map((prop, ind)=>{
            if(prop !== null || prop !== undefined){
            return <tr 
                        key={ind}
                        style={{padding:'1px', margin:'0px'}}
                        >
                        <td className='text-left' style={{textTransform:'capitalize'}}>
                            {prop.date_confirmed}
                        </td>
                        <td className='text-left' >
                        <Row>
                                <Col xs='1' className='m-auto align-items-center text-center'>
                                    <i className='fa fa-user-circle fa-3x'></i>
                                </Col>
                                <Col xs='11' >
                                    <h6>{prop.fullname}</h6>
                                    <Row><Col><b>CHANNEL </b>: {prop.channel}{' '}<i>SENT:<b>{moment(prop.date_created).format('DD MMMM YYYY hh:mm')}</b></i></Col></Row>
                                    <Row><Col><b>RATING </b>:</Col>
                                    <Col className='text-primary'>
                                        { parseInt(prop.rating) === 1 ? <i className='fa fa-star fa-2x'></i>:''}
                                        { parseInt(prop.rating) === 2 ? <><i className='fa fa-star fa-2x'></i><i className='fa fa-star fa-2x'></i></>:''}
                                        { parseInt(prop.rating) === 3 ? <><i className='fa fa-star fa-2x'></i><i className='fa fa-star fa-2x'></i><i className='fa fa-star fa-2x'></i></>:''}
                                        { parseInt(prop.rating) === 4 ? <><i className='fa fa-star fa-2x'></i><i className='fa fa-star fa-2x'></i><i className='fa fa-star fa-2x'></i><i className='fa fa-star fa-2x'></i></>:''}
                                        { parseInt(prop.rating) === 5 ? <><i className='fa fa-star fa-2x'></i><i className='fa fa-star fa-2x'></i><i className='fa fa-star fa-2x'></i><i className='fa fa-star fa-2x'></i><i className='fa fa-star fa-2x'></i></>:''}
                                    
                                    </Col>
                                    </Row>
                                    <Row><Col><b>COMMENT </b>:{prop.comment}</Col></Row>
                                </Col>
                            </Row>
                        </td>
                    </tr>}
                }): '';
        

        return (
            <>
            
              <div className="content">
                  <div className='card'>
                  <div className='card-header tablecardheader'>
                      <h5>Guest Satisfaction</h5><br/>
                      {subtopic}
                      <a class="btn btn-outline-info btn-sm pull-right" onClick={()=>this.lunchDate(this.props.getRoomtransactioncomment, this.retState, this.props.user.location)} href="#">
                                <i className="now-ui-icons ui-1_calendar-60"></i>
                      </a>
                  </div>
                  <div className='card-body table-responsive'>
                    <table ref={el=>this.el = el} className='table-bordered display wrap table-striped mytables table-condensed table-hover'  id='myTableZ' width='100%' >
                        
                        <tbody>
                            {tabl}
                        </tbody>
                    </table>
                  </div>
                  </div>
              </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({ 
    user:state.userReducer,
    roomcategorys:state.roomcategoryReducer,
    roomtransactions:state.roomtransactionReducer
  })
  
export default connect(mapStateToProps, 
    { 
        getRoomtransactioncomment,
        getRoomtransaction,
        updateRoomtransaction,
        registerRoomtransaction,
        deleteRoomtransaction
    })(BottomCard)
