import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { registerRoomcategory, updateRoomcategory } from '../../actions/roomcategory';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, FormText, Label, Input, Col } from 'reactstrap';
import { alllocations, alllocationsObj, axiosConfig, callError, callSuccess, SERVER_URL } from '../../actions/common'; 
import Select  from 'react-select';
import Axios from 'axios';

const lockz = alllocations;
const lockzobject = alllocationsObj;

const FormRooms = (props) => {
  
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [location, setLocation] = useState(null);
  const [description, setDescription] = useState(null);

  const [room_category, setroom_category] = useState([])
  const [room, setroom] = useState([])
  const [loading, setloading] = useState(false)
  const [loadingCategory, setloadingCategory] = useState(false)
  const [categoryid, setcategoryid] = useState(null)
  const [roomid, setroomid] = useState(null)
  

  const resetdata= async() =>{
    toggle();
    setId(0);
    setName('');
    setModal(false)
}

  const toggle = () => setModal(!modal);
    useEffect(() => {
      setModal(props.st);
      if(parseInt(props.mid) > 0 )
      {
      setId(parseInt(props.mid));
      populate(props.data);  
      }
      
  },[props.st]);

useEffect(() => loadRoomCategory(), [])
useEffect(() => loadRoom(), [categoryid])

const loadRoomCategory = ()=>{
  setloading(true)
  let params = {
      data:JSON.stringify({
          is_active:0,
          locationid : 1
      }),
      cat:'selected',
      table:'room_categorys',
      token:''
  }
  
  Axios.get(SERVER_URL+"/index.php", {params}, axiosConfig)
  .then(res=>{
    setroom_category(res.data)
    setloadingCategory(false)
  })
  
  .catch(err=>{
      setloadingCategory(false)
      callError('Please check your network.')
  })
  
}
const loadRoom = ()=>{
  setloading(true)
  let params = {
      data:JSON.stringify({
          categoryid:categoryid,
          is_active:0
      }),
      cat:'selected',
      table:'room_types',
      token:''
  }
  
  Axios.get(SERVER_URL+"/index.php", {params}, axiosConfig)
  .then(res=>{
    setroom(res.data)
    setloading(false)
  })
  .catch(err=>{
      setloading(false)
      callError('Please check your network.')
  })
  
}
const handleSubmit = (e) =>{
      e.preventDefault();
      setModal(false)
      props.submitRoom(roomid)
}

const populate = async(data) =>{
      setName(data.name);
      let nm = {};
      nm['value'] = data.locationid;
      nm['label'] = lockz[data.locationid];
      setLocation(nm);
}

  let editColor = 'primary';
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false}>
        <ModalHeader toggle={resetdata}><i className='fa fa-hospital-o'></i> House</ModalHeader>
        <ModalBody>
        <Form>
          <FormGroup row>
          <Col sm={12}>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-hospital-o"></i></div>
              </div>
                <select
                  name="categoryid"
                  className='form-control'
                  value={categoryid}
                  defaultValue={categoryid}
                  onChange={(e)=>setcategoryid(e.target.value)}
                >
                  <options></options>
                  {
                    Array.isArray(room_category) ?
                      room_category.map(prop=>{
                        let {id, name} = prop || ''
                        return <option key={id} value={id} >{name}</option>
                    })
                    :null
                  }
                </select>
            </div>
          </Col>
          </FormGroup>
          <FormGroup row>
          <Col sm={12}>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fa fa-hospital-o"></i></div>
              </div>
                <select
                  name="roomid"
                  className='form-control'
                  value={roomid}
                  defaultValue={roomid}
                  onChange={(e)=>setroomid(e.target.value)}
                >
                  <options></options>
                  {
                    Array.isArray(room) ?
                      room.map(prop=>{
                        let {id, name} = prop || ''
                        return <option key={id} value={id} >{name}</option>
                      })

                    :null
                  }
                </select>
            </div>
          </Col>
          </FormGroup>    
        </Form>
        </ModalBody>
        <ModalFooter>
          <Button color={editColor} onClick={handleSubmit}>Assign Room</Button>{' '}
          <Button color="secondary" onClick={resetdata}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
const mapStateToProps = (state, ownProps) => ({ 
    user:state.userReducer,
    roomcategorys:state.roomcategoryReducer
  })
  
export default connect(mapStateToProps, { registerRoomcategory, updateRoomcategory })(FormRooms)
