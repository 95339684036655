import React from "react";
import { connect } from "react-redux";
import moment from 'moment';
import Swal from "sweetalert2";
import { getMaintenanceanalysis } from "./../../actions/maintenancetype";
import ReportMaintenanceWidget from "./../Form/ReportMaintenanceWidget";
import $ from 'jquery';
import ReactTooltip  from 'react-tooltip';
import { alllocations } from "actions/common";


const piority = {
    1:'Low',
    2:'Normal',
    3:'High',
    4:'Emergency',
    null:'All'
};
const piorityColor = {
    1:'secondary',
    2:'warning',
    3:'primary',
    4:'danger'
};
const stateColor = {
    0:'danger',
    1:'success'
};
const stater = [
    {'value':'0', 'label':'Not completed'},
    {'value':'1', 'label':'Completed'},
    {'value':'2', 'label':'Pending'},
    {'value':null, 'label':'All'},
];
class MaintenanceWidget extends React.Component {
    constructor(props){
        super(props);
        this.state ={
          startdate:new Date(),
          enddate:new Date(),
          fid:false,
          numb:5,
          title:'',
           data:{},
           start:'',
           end:'',
           grp:''
        }
      }

    componentDidMount(){
        let bk = new Date();
        let td = new Date(moment().subtract(3, 'months').calendar());
        let params = {
            data:JSON.stringify({
              'startdate':moment(new Date(td.getFullYear(), td.getMonth(), 1)).format('YYYY-MM-DD'),
              'enddate':moment(new Date(bk.getFullYear(), bk.getMonth() + 1, 0)).format('YYYY-MM-DD'),
              'locationid': this.props.user.location
            }),
            cat:'mainana',
            table:'maintenance_types'
        }
        this.props.getMaintenanceanalysis(params);
        this.setState({
          startdate:moment(new Date(td.getFullYear(), td.getMonth(), 1)).format('YYYY-MM-DD'), 
          enddate:moment(new Date(bk.getFullYear(), bk.getMonth() + 1, 0)).format('YYYY-MM-DD')
        });
      }


      componentDidUpdate(prevProps)
      {
        if(prevProps.user.location !== this.props.user.location){
        let bk = new Date();
        let td = new Date(moment().subtract(3, 'months').calendar());
        let params = {
            data:JSON.stringify({
              'startdate':moment(new Date(td.getFullYear(), td.getMonth(), 1)).format('YYYY-MM-DD'),
              'enddate':moment(new Date(bk.getFullYear(), bk.getMonth() + 1, 0)).format('YYYY-MM-DD'),
              'locationid': this.props.user.location
            }),
            cat:'mainana',
            table:'maintenance_types'
        }
        this.props.getMaintenanceanalysis(params);
        this.setState({
          startdate:moment(new Date(td.getFullYear(), td.getMonth(), 1)).format('YYYY-MM-DD'), 
          enddate:moment(new Date(bk.getFullYear(), bk.getMonth() + 1, 0)).format('YYYY-MM-DD')
        });
       }
      }

      lunchDate = (func, func1) =>{
        Swal.fire({
            title: 'Pick a date from and to:',
            type: 'question',
            html: '<input id="datepicker" type="date"  class="swal2-input"><br/><input id="datepicker1" type="date"  class="swal2-input">',
            customClass: 'swal2-overflow',
            
          }).then(function(result) {
              if(result.value){
                  let v = $('#datepicker').val();
                  let v1 = $('#datepicker1').val();
                  let params = {
                    data:JSON.stringify({'startdate':moment(new Date(v)).format('YYYY-MM-DD'), 'enddate':moment(new Date(v1)).format('YYYY-MM-DD')}),
                    cat:'mainana',
                    table:'maintenance_types'
                }
                func(params);
                func1(v, v1);
              }
          });
        }
        retState = (dt, dt1) =>{
            this.setState({startdate:new Date(dt), enddate:new Date(dt1)});
        }
        convertdate = (time) =>{
            let d = parseInt(time)/(60 * 60 * 24);
          
            return Number(d).toFixed(6);
        }
    showCategory = (data, start, end) =>{
        this.setState({
            fid:true,
            title:'Maintenance Category',
            data:data,
            start:start,
            end:end,
            grp:1
        })
    }
    showPiority = (data, start, end) =>{
        this.setState({
            fid:true,
            title:'Maintenance Piority',
            data:data,
            start:start,
            end:end,
            grp:2
        })
    }
    showState = (data, start, end) =>{
        this.setState({
            fid:true,
            title:'Maintenance State',
            data:data,
            start:start,
            end:end,
            grp:3
        })
    }

    render() {
        let {startdate, enddate } = this.state;
        let odata1 = this.props.maintenanceanalysis && Array.isArray(this.props.maintenanceanalysis) && this.props.maintenanceanalysis[4] ? this.props.maintenanceanalysis[4] : [] ;
        
        let data = odata1;
        
        
        let tabl = data && Array.isArray(data) ? data.filter(rw=>rw !== null).map((prop, ind)=>{
            if(ind < this.state.numb){
            return <tr 
                    key={ind}
                        style={{padding:'1px', margin:'0px'}}
                        >
                        <td className='text-center'>{String(prop.id).padStart(7, '0')}</td>
                        <td className='text-center'>{moment(new Date(prop.date_created)).format('DD-MMM-YYYY')}</td>
                        <td>
        {alllocations[prop.locationid]}{" | "} {prop.roomname}
                        </td>
                       
                        <td style={{textAlign:'left'}}>
                            {prop.maintenancename}
                        </td>
                        <td>
                            <span className={`text-${piorityColor[parseInt(prop.status)]}`}><b>{piority[parseInt(prop.status)]}</b></span>
                        </td>
                       
                    </tr>
            }
                }): '';

        return (
            <>
             {this.state.fid ?
            <ReportMaintenanceWidget
                st={this.state.fid}
                title={this.state.title}
                data={this.state.data}
                start={this.state.start}
                end={this.state.end}
                grp={this.state.grp}
                handleClose={()=>this.setState({
                    fid:false, 
                    title:'',
                    grp:'', 
                    data:{},
                    date:''
                })}
                />:''}
            <div class="row">
                <ReactTooltip id='occp' type='dark'>
                    <span>Click to view reports</span>
                </ReactTooltip>
                <ReactTooltip id='dater' type='dark'>
                    <span>Click to select date range</span>
                </ReactTooltip>
                <div class="col-md-12">
                    <div class="card card-stats">
                    <div class="card-header">
                        <h4 class="card-categorys" ><b><i className='fa fa-wrench'></i>{' '}Maintenance Issues Table ({this.props.user.locationname})</b></h4><br/>
                        <small>Ordered by piority, date recorded</small>
                    </div>
                        <div class="card-body table-responsive">
                            <table className='table table-bordered table-hover mytables table-condensed'>
                                <thead>
                                    <tr>
                                        <th>TID</th>
                                        <th>Date Recorded</th>
                                        <th>Location</th>
                                        <th>Maintenance Issue</th>
                                        <th>Piority</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tabl}
                                </tbody>
                                <tfoot className='text-center align-items-center'>
                                    <button className="btn btn-sm btn-info btn-block m-3" onClick={()=>this.setState({numb:this.state.numb+10})}>More...</button>
                                </tfoot>
                            </table>
                            
                        </div>
                        <div class="card-footer">
                            <div class="stats" data-tip data-for='dater' onClick={()=>this.lunchDate(this.props.getMaintenanceanalysis, this.retState)}>
                                <i class="now-ui-icons arrows-1_refresh-69"></i> {moment(new Date(startdate)).format("MMMM YYYY")}{" "}{moment(new Date(enddate)).format("MMMM YYYY")}
                            </div>
                        </div>
                    </div>
                </div>
                
             </div>
           </>
        )
    }
}
const mapStateToProps = (state, ownProps) => ({ 
    user:state.userReducer,
    maintenanceanalysis:state.maintenancetypeReducer.maintenanceanalysis,
  })
  export default connect(mapStateToProps, {getMaintenanceanalysis})(MaintenanceWidget);