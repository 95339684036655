import React from "react";
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import {getUserleavetransactions, getUserleavetransaction, registerUserleavetransaction, updateUserleavetransaction, deleteUserleavetransaction } from '../../actions/userleavetransaction';


import $ from "jquery";
import axios from "axios";
import { callError, path, axiosConfig1 } from "actions/common";
import { Link } from "react-router-dom";

class BottomCard extends React.Component {
   constructor(props){
       super(props);
       this.state ={
           id:null,
           cat:null,
            grp:null,
            afid:false,
            tfid:false,
            cfid:false,
            mfid:false,
            ifid:false,
            mid:null,
            fid:false,
            data:{},
            subtopic:'All Categories',
            started:'',
            ended:'',
            daterange:'',
            isshown:{}

       }
   }

    componentDidMount(){
        //GET PROPS
        let id = this.props.id;
        let dts = new Date();
        let started = new Date(dts.getFullYear(), dts.getMonth(), 1);
        let ended = new Date(dts.getFullYear(), dts.getMonth() + 1, 0);
        let daterange = ' '+moment(started).format('MMMM Do YYYY') + ' - ' + moment(ended).format('MMMM Do YYYY');
        
        this.setState({
            id:id, 
            cat:id,
            started:started,
            ended:ended,
            daterange:daterange
        });
            
       let dt = {
            'starts':started,
            'ends':ended,
            'locationid':this.props.user.location
        }
        let params = {
            data:JSON.stringify(dt),
            cat:'userleavetransaction',
            table:'room_comments'
        }
         let ctn = daterange;
         this.setState({subtopic:ctn});
         this.props.getUserleavetransactions(params);

    }
    
    componentDidUpdate(prevProps, prevState){
        //GET PROPS
        if(prevProps.id !== this.props.id || prevProps.user.location !== this.props.user.location || prevState.started !== this.state.started ){
        let id = this.props.id;
        
        let started = this.state.started;
        let ended = this.state.ended;
        
        let daterange = ' '+moment(started).format('MMMM Do YYYY') + ' - ' + moment(ended).format('MMMM Do YYYY');
        
        
        let dt = {
            'starts':started,
            'ends':ended,
            'locationid':this.props.user.location
        }
        let params = {
            data:JSON.stringify(dt),
            cat:'userleavetransaction',
            table:'room_comments'
        }
     
     this.setState({subtopic:daterange});
     this.props.getUserleavetransactions(params);

        $('#myTablex').DataTable();
         
        }
            

    }
   
    componentWillUnmount(){
        $('#myTablex').DataTable().destroy();
    }
    removeLeave = id =>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You will not be able restore",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
        
            if (result.value) {
                let fd = new FormData();
                fd.append('id', id);
                fd.append('cat', 'delete');
                fd.append('table', 'user_leave_transactions');
                this.props.deleteUserleavetransaction(fd, id);
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            }
          })
        
    }
    controlLeave = (id, num, info, func, uss) =>{
        Swal.fire({
            title: info,
            type: 'question',
            html: '<input id="txts" type="textarea"  class="swal2-input form-control">',
            customClass: 'swal2-overflow',
            
          }).then(function(result) {
              
                  let v = $('#txts').val();
                  let fd = new FormData();
                  fd.append('id', id);
                  fd.append('is_approved', num);
                  fd.append('notes', v);
                  fd.append('admin', uss.user.surname +" "+uss.user.firstname);
                  fd.append('dated_admin', moment(new Date()).format("YYYY-MM-DD hh:mm:ss"));
                  fd.append('table', 'user_leave_transactions');
                  fd.append('cat', 'update');
                  fd.append('rowzid', uss.user.id);
                  fd.append('locateid', info + ' leave');
                  func(fd);
              
          });    
    }
    loadShown = (id, cat) =>{
          let ar = {};
          if(cat === 0)
          {
              ar[id] = true;
          }else{
              ar[id] = false;
          }
          this.setState({isshown:ar});
    }
    lunchDate = (func, func1, locs) =>{
        Swal.fire({
            title: 'Pick a date range:',
            type: 'question',
            html: '<input id="datepicker" type="date"  class="swal2-input"><br/><input id="datepicker1" type="date"  class="swal2-input">',
            customClass: 'swal2-overflow',
            
          }).then(function(result) {
              if(result.value)
              {
                  let v = $('#datepicker').val();
                  let v1 = $('#datepicker1').val();
                  let params = {
                    data:JSON.stringify(
                    {
                        'starts':moment(new Date(v)).format('YYYY-MM-DD'), 
                        'ends':moment(new Date(v1)).format('YYYY-MM-DD'), 
                        'locationid':locs
                    }),
                    cat:'userleavetransaction',
                    table:'room_comments'
                }
                func(params);
                func1(v, v1);
              }
          });
    }
    retState = (dt, dt1) =>{
        this.setState({started:new Date(dt), ended:new Date(dt1)});
    }
    
    render() {
        let { subtopic } = this.state  || '';
        let data = this.props.userleavetransactions.userleavetransactions;
        let tabl = data && Array.isArray(data) ? data.filter(rw=>rw !== null ).map((prop, ind)=>{
            if(prop !== null || prop !== undefined){
                return <tr key={ind}>
                <td >
                    {moment(prop.date_created).format('DD MM YYYY hh:mm:ss')}
                    <div class="dropdown">
                        <button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-toggle="dropdown">
                            <i className='fa fa-gear'></i> Actions
                        </button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="#" onClick={()=>this.editLeave(prop.id, prop)}><i className='fa fa-edit'></i> Edit</a>
                            <a class="dropdown-item" href="#" onClick={()=>this.removeLeave(prop.id)}><i className='fa fa-remove'></i> Delete</a>
                            <a class="dropdown-item" href="#" onClick={()=>this.controlLeave(prop.id, 1, 'Approved', this.props.updateUserleavetransaction, this.props.user )}><i className='fa fa-thumbs-up text-success'></i> Approved</a>
                            <a class="dropdown-item" href="#" onClick={()=>this.controlLeave(prop.id, 2, 'Unpproved', this.props.updateUserleavetransaction, this.props.user)}><i className='fa fa-thumbs-down text-danger'></i> Unapproved</a>
                            <a class="dropdown-item" href="#" onClick={()=>this.controlLeave(prop.id, 0, 'Pending', this.props.updateUserleavetransaction, this.props.user)}><i className='fa fa-reload'></i> Reset to pending</a>
                        </div>
                    </div>
                </td>
                
                <td className='pull-left text-left w-100'>
            <h6><Link to=''>{prop.surname}{' '}{prop.firstname}</Link></h6>
                    {moment(prop.starts).format('DD MMMM YYYY hh:mm:ss')}{" - "}{moment(prop.ends).format('DD MMMM YYYY hh:mm:ss')}
                    <table width='100%'>
                    <tr><td width='100%'>{prop.reason}</td></tr>
                    <tr><td width='100%'>
                        <br/>
     {parseInt(prop.is_approved) === 0 ? <span className='badge badge-warning'>{`Pending`}</span>:""}
     {parseInt(prop.is_approved) === 1 ? <><span className='badge badge-success'>{`Approved by:${prop.admin} dated ${moment(prop.dated_admin).format('DD MMMM YYYY ')}`}</span><p>{prop.notes}</p></>:""}
     {parseInt(prop.is_approved) === 2 ? <><span className='badge badge-danger'>{`Unapproved by:${prop.admin} dated ${moment(prop.dated_admin).format('DD MMMM YYYY')}`}</span><p>{prop.notes}</p></>:""}
     </td></tr></table>
                </td> 
               
            </tr>
                    }
                }): '';
        
                
        return (
            <>
            
              <div className="content">
                  <div className='card'>
                  <div className='card-header tablecardheader'>
                      <h5>Employee Leave</h5><br/>
                      {subtopic}
                      <a class="btn btn-outline-info btn-sm pull-right" onClick={()=>this.lunchDate(this.props.getUserleavetransactions, this.retState, this.props.user.location)} href="#">
                                <i className="now-ui-icons ui-1_calendar-60"></i>
                      </a>
                  </div>
                  <div className='card-body table-responsive'>
                    <table ref={el=>this.el = el} className='table-bordered display wrap table-striped mytable table-condensed table-hover'  id='myTableZ' width='100%' >
                    <thead>
                                    <tr>
                                        <th width='180px'>APPLIED ON.</th>
                                        <th>DURATION<br/>REASON</th>
                                    </tr>
                                </thead>
                        <tbody>
                            {tabl}
                        </tbody>
                    </table>
                  </div>
                  </div>
              </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({ 
    user:state.userReducer,
    roomcategorys:state.roomcategoryReducer,
    userleavetransactions:state.userleavetransactionReducer
  })
  
export default connect(mapStateToProps, 
    { 
        getUserleavetransactions,
        getUserleavetransaction,
        updateUserleavetransaction,
        registerUserleavetransaction,
        deleteUserleavetransaction
    })(BottomCard)
