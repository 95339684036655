import React from "react";

class BottomCard extends React.Component {
    render() {
        return (
            <>
              <div class="content">
                  
              </div>
            </>
        )
    }
}
export default BottomCard;