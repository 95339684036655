import React from "react";
import { connect } from 'react-redux';
import { NavLink, Link, Navigate } from "react-router-dom";
import { Nav } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import {getUserLogout, changeLocation, getUsers} from './../../actions/user';
import {getClients} from './../../actions/client';
import "assets/css/mine.css";
import FormUser from "views/Form/FormUser";
import FormUserLeave from "views/Form/FormUserLeave";
import FormInventoryTransaction from "views/Form/FormInventoryTransaction";
import FormMaintenanceTransaction from "views/Form/FormMaintenanceTransaction1";
var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
        loc:0,
        afid:false,
        mid:null,
        fidd:false,
        lidd:false,
        iidd:false,
        midd:false
    }
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
       ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }

    let params = {
          data:JSON.stringify({
                locationid:this.props.user.location,
                dates:this.props.user.dates
          }),
          cat:'notification',
          table:'user_types',
          token:''
    }
   
    this.props.getUsers(params);
    this.props.getClients(params);
  }
  componentDidUpdate(prevProps, preveState){
      if(prevProps.user.locationid !== this.props.user.locationid || prevProps.user.dates !== this.props.user.dates){
      let params = {
            data:JSON.stringify({
                  locationid:this.props.user.location,
                  dates:this.props.user.dates
            }),
            cat:'notification',
            table:'user_types',
            token:''
      }
     
      this.props.getUsers(params);
      this.props.getClients(params);
}
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  handleDirectDash = () =>{
    return <Navigate to="/account/user" />
  }
  addStaff = () =>{
      this.setState({fidd:true});
  }
  addLeave = () =>{
      this.setState({lidd:true});
  }
  addInventory = () =>{
      this.setState({iidd:true});
  }
  addMaintenance = () =>{
      this.setState({midd:true});
  }
  logOut = () =>{
    this.props.getUserLogout();
  }

  render() {
    let {access} = this.props.user.user || {}; 
    let isAdmin = this.props.user.isAdmin;
    let dates = this.props.client.clients && this.props.client.clients.length > 9?this.props.client.clients : [{'id':null},{'id':null},{'id':null},{'id':null},{'id':null},{'id':null},{'id':null},{'id':null},{'id':null},{'id':null},{'id':null},{'id':null}] ;
    let datesuser = this.props.user.users && this.props.user.users.length > 2 ? this.props.user.users : [{'id':null},{'id':null},{'id':null},{'id':null},{'id':null},{'id':null},{'id':null},{'id':null},{'id':null},{'id':null},{'id':null},{'id':null}] ;  
    let sum_1_room = parseInt(dates[1].id);
    let sum_3_booking = parseInt(dates[3].id);
    let sum_5_comment = parseInt(dates[5].id);
    let sumguest = sum_3_booking + sum_5_comment;
    let sum_7_inventory = parseInt(dates[7].id);
    let sum_9_maintenance = parseInt(dates[9].id);
    let sum_0_user_types = parseInt(datesuser[0].id);
    let sum_1_user_trans = parseInt(datesuser[1].id);
    let sum_2_user_leave = parseInt(datesuser[2].id);
    let sumuser = sum_0_user_types + sum_1_user_trans + sum_2_user_leave;
    let acc = access !== undefined & access !== '' ? JSON.parse(access) : [[], [], [], [], [], []] ;
    return (
          <>
          { this.state.fidd ?
          <FormUser
            st={this.state.fidd}
            mid={null}
            handleClose={()=>this.setState({fidd:false})}
          />
          :''}
           { this.state.lidd ?
          <FormUserLeave
            st={this.state.lidd}
            mid={null}
            staffid={this.props.user.user.id}
            handleClose={()=>this.setState({lidd:false})}
          />
          :''}
          { this.state.iidd ?
          <FormInventoryTransaction
            st={this.state.iidd}
            mid={null}
            handleClose={()=>this.setState({iidd:false})}
          />
          :''}
           { this.state.midd ?
          <FormMaintenanceTransaction
            st={this.state.midd}
            mid={null}
            handleClose={()=>this.setState({midd:false})}
          />
          :''}
      <div id='siddd' className="sidebar d-print-none hidden-print" data-color={this.props.backgroundColor}>
        <div className="logo" style={{backgroundColor:'#001f2b', color:'#fff'}}>
          <a
            href=""
            className="simple-text logo-mini"
            target="_blank"
          >
            <div className="logo-img">
              <img src={require("assets/img/logo.png")} alt="s" />
            </div>
          </a>
          <a
            href="#"
            className="simple-text logo-normal"
            target="_blank"
            style={{color:'#00b8ff', lineHeight:'80%',  margin:'0px', padding:'0px', marginBottom:'0px',marginTop:'5px' }}
          >
           <p className='text-info' style={{ margin:'0px', padding:'0px',  textTransform:'capitalize', fontSize:'1.5em', fontFamily:'Bree Serif'}}>{process.env.REACT_APP_WEBSITE_AKA}</p>
           <p style={{fontSize:'0.6em', color:'#fff', margin:'0px', padding:'0px', textTransform:'capitalize', fontFamily:'Josefin Sans'}}>Powered By {process.env.REACT_APP_WEBSITE_NAME}</p>
          </a>
        </div>
        <div className="logo align-items-center" style={{background:'#001f2b', margin:'0px', padding:'0px', textAlign:'center'}}>
              <span style={{margin:'0px', padding:'0px', fontSize:'0.9em', color:'gray', textTransform:'uppercase'}}><span className="fa fa-globe" ></span> Change Location</span>
        {isAdmin || ['0'].some(el=>acc[0].includes(el)) ?
            <div className='btn-group align-items-center' style={{fontSize:'1.4em', margin:'auto', textAlign:'center', color:'white'}}>
                  <a onClick={()=>this.props.changeLocation(3)} className={parseInt(this.props.user.location) === 3 ? 'changeloc1' : 'changeloc '} >Unified</a>
                  :<a onClick={()=>this.props.changeLocation(1)} className={parseInt(this.props.user.location) === 1 ? 'changeloc1' : 'changeloc '}>Kainji</a>
                  :<a onClick={()=>this.props.changeLocation(2)} className={parseInt(this.props.user.location) === 2 ? 'changeloc1' : 'changeloc '}>Jebba</a>
            </div>:
          <a
            href=""
            className="simple-text logo-normal"
            target="_blank"
            style={{fontFamily:'Josefin Sans', textTransform:'capitalize', textAlign:'center', fontSize:'1.6rem'}}
          >
           {this.props.user.locationname}
          </a>}
        </div>
        
          
        <div className="sidebar-wrapper sc" id="sidebar-wrapper" ref="sidebar" style={{background:'#001f2b'}}>
        <Nav>
              <NavLink to='/account/home'><li className="sidebarmenu">
                    <span className="fa fa-dashboard" ></span>
                    <p className="" >Dashboard {" "}</p>  
              </li></NavLink>
              {isAdmin || acc[1].length > 0 ?<><li className="sidebarmenu">
                    <span className="fa fa-user" ></span>
                    <p className="" data-toggle='collapse' data-target="#guest"  data-parent='#sidebar-wrapper' >Guest {" "}</p>  
                    {parseInt(sumguest) > 0 ? <span style={{padding:'auto', margin:'auto'}} className='badge badge-pill badge-danger pull-right'>{sumguest}</span>: <span className="fa fa-caret-right" ></span>}
              </li>
              <span className='collapse in' id='guest' data-parent='#sidebar-wrapper'>
              {isAdmin || ['0', '1'].some(el=>acc[1].includes(el)) ?
              <li className="sidebarmenus my-0 py-0" >
              <NavLink 
                    to='/account/guest' 
                    className="sidebarmenux my-0 py-0"
           >Guest Information</NavLink>
              </li>: ''}
              {isAdmin || ['0', '2'].some(el=>acc[1].includes(el)) ?
              <li className="sidebarmenus my-0 py-0">
              <NavLink 
                    to='/account/guestcomment' 
                    className="sidebarmenux my-0 py-0"
                    >Guest Satisfaction</NavLink>  
                    {parseInt(sum_5_comment) > 0 ? <span className='badge badge-warning badge-pill pull-right'>{sum_5_comment}</span>: ''}
                  </li>:''}
              {isAdmin || ['0', '3'].some(el=>acc[1].includes(el)) ?
              <li className="sidebarmenus my-0 py-0">
              <NavLink 
                    to='/account/guestbooking' 
                    className="sidebarmenux my-0 py-0"
                    >Guest Booking</NavLink> 
                    {parseInt(sum_3_booking) > 0 ? <span className='badge badge-danger badge-pill pull-right'>{sum_3_booking}</span>: ''} 
              </li>:''}
              </span></>:''}
              {isAdmin || acc[2].length > 0 ?<><li className="sidebarmenu">
                    <span className="fa fa-thumb-tack" ></span>
                    <p className="" data-toggle='collapse' data-target="#invt"  data-parent='#sidebar-wrapper'>Inventory {" "}</p>  
                    {parseInt(sum_7_inventory) > 0 ? <span style={{padding:'auto', margin:'auto'}} className='badge badge-pill badge-danger pull-right'>{sum_7_inventory}</span>: <span className="fa fa-caret-right" ></span>}
              </li>
              <span className='collapse' id='invt' data-parent='#sidebar-wrapper'>
              {isAdmin || ['0', '1'].some(el=>acc[2].includes(el)) ?
              <li className="sidebarmenus my-0 py-0">
                    <a 
                    onClick={()=>this.addInventory()} 
                    className="sidebarmenux my-0 py-0"
                    >Inventory Record form</a>
              </li>:''}
              {isAdmin || ['0', '2'].some(el=>acc[2].includes(el)) ?
              <li className="sidebarmenus my-0 py-0">
                    <NavLink 
                    to='/account/inventoryhistory/all/2' 
                    className="sidebarmenux my-0 py-0"
                    >Inventory History</NavLink>
                    {parseInt(sum_7_inventory) > 0 ? <span className='badge badge-info badge-pill pull-right'>{sum_7_inventory}</span>: ''}
              </li>:''}
              {isAdmin || ['0', '3'].some(el=>acc[2].includes(el)) ?
              <li className="sidebarmenus my-0 py-0">
                    <NavLink 
                    to='/account/inventoryreport/all/2' 
                    className="sidebarmenux my-0 py-0"
                    >Inventory Report</NavLink>
              </li>:''}
              {isAdmin || ['0', '4'].some(el=>acc[2].includes(el)) ?
              <li className="sidebarmenus my-0 py-0">
                    <NavLink 
                    to='/account/inventory' 
                    className="sidebarmenux my-0 py-0"
                    >Inventory Categories</NavLink>
              </li>:''}
              {isAdmin || ['0', '5'].some(el=>acc[2].includes(el)) ?
              <li className="sidebarmenus my-0 py-0">
                    <NavLink 
                    to='/account/inventorys/all' 
                    className="sidebarmenux my-0 py-0"
                    >Inventories</NavLink>
              </li>:''}
              </span></>:''}
              {isAdmin || acc[3].length > 0 ?<><li className="sidebarmenu">
                    <span className="fa fa-bed" ></span>
                    <p className="" data-toggle='collapse' data-target="#rom"  data-parent='#sidebar-wrapper'>Housekeeping {" "}</p>
                    {parseInt(sum_1_room) > 0 ? <span style={{padding:'auto', margin:'auto'}} className='badge badge-pill badge-success pull-right'>{sum_1_room}</span>: <span className="fa fa-caret-right" ></span>} 
              </li>
              <span className='collapse' id='rom' data-parent='#sidebar-wrapper'>
              {isAdmin || ['0', '1'].some(el=>acc[3].includes(el)) ? 
              <li className="sidebarmenus my-0 py-0">
                    <NavLink 
                    to='/account/roomhistory/all/2' 
                    className="sidebarmenux my-0 py-0"
                    >Booking</NavLink>
                    {parseInt(dates[1].id) > 0 ? <span className='badge badge-success badge-pill pull-right'>{dates[1].id}</span>: ''}
              </li>:''}
              {isAdmin || ['0', '2'].some(el=>acc[3].includes(el)) ?
              <li className="sidebarmenus my-0 py-0">
                     <NavLink 
                    to='/account/roomreport/all/2' 
                    className="sidebarmenux my-0 py-0"
                    >Occupancy</NavLink>
              </li>:''}
              {isAdmin || ['0', '3'].some(el=>acc[3].includes(el)) ?
              <li className="sidebarmenus my-0 py-0">
                    <NavLink 
                    to='/account/room' 
                    className="sidebarmenux my-0 py-0"
                    >Houses</NavLink>
              </li>:''}
              {isAdmin || ['0', '4'].some(el=>acc[3].includes(el)) ?
              <li className="sidebarmenus my-0 py-0">
                    <NavLink 
                    to='/account/rooms/all' 
                    className="sidebarmenux my-0 py-0"
                    >Rooms</NavLink>
              </li>:''}
              </span></>:''}
              {isAdmin || acc[4].length > 0 ?<><li className="sidebarmenu">
                    <span className="fa fa-wrench" ></span>
                    <p className="" data-toggle='collapse' data-target="#mtn">Maintenance {" "}</p>
                    {parseInt(sum_9_maintenance) > 0 ? <span style={{padding:'auto', margin:'auto'}} className='badge badge-pill badge-info pull-right'>{sum_9_maintenance}</span>: <span className="fa fa-caret-right" ></span>}  
              </li>
              <span className='collapse' id='mtn' data-parent='#sidebar-wrapper'>
              {isAdmin || ['0', '1'].some(el=>acc[4].includes(el)) ?
                  <li className="sidebarmenus my-0 py-0">
                    <a 
                    onClick={()=>this.addMaintenance()} 
                    className="sidebarmenux my-0 py-0"
                    >Maintenance Request form</a>
              </li>:''}
              {isAdmin || ['0', '2'].some(el=>acc[4].includes(el)) ?
              <li className="sidebarmenus my-0 py-0">
                    <NavLink 
                    to='/account/maintenancehistory/all/2' 
                    className="sidebarmenux my-0 py-0"
                    >Maint. Issues</NavLink>
                    {parseInt(sum_9_maintenance) > 0 ? <span className='badge badge-info badge-pill pull-right'>{sum_9_maintenance}</span>: ''}
                  </li>:''}
              {isAdmin || ['0', '3'].some(el=>acc[4].includes(el)) ?
              <li className="sidebarmenus my-0 py-0">
                    <NavLink 
                    to='/account/maintenancereport/all/2' 
                    className="sidebarmenux my-0 py-0"
                    >Maintenance Report</NavLink>
              </li>:''}
              {isAdmin || ['0', '4'].some(el=>acc[4].includes(el)) ?
              <li className="sidebarmenus my-0 py-0">
                    <NavLink 
                    to='/account/maintenance' 
                    className="sidebarmenux my-0 py-0"
                    >Maintenance Categories</NavLink>
              </li>:''}
              {isAdmin || ['0', '5'].some(el=>acc[4].includes(el)) ?
              <li className="sidebarmenus my-0 py-0">
                    <NavLink 
                    to='/account/maintenances/all' 
                    className="sidebarmenux my-0 py-0"
                    >Maintenances</NavLink>
              </li>:''}
               
             
              </span></>:''}
              {isAdmin || acc[5].length > 0 ?<> <li className="sidebarmenu">
                    <span className="fa fa-users" ></span>
                    <p className="" data-toggle='collapse' data-target="#stf">Employees {" "}</p>
                    {parseInt(sumuser) > 0 ? <span style={{padding:'auto', margin:'auto'}} className='badge badge-pill badge-primary pull-right'>{sumuser}</span>: <span className="fa fa-caret-right" ></span>}  
              </li>
              <span className='collapse' id='stf' data-parent='#sidebar-wrapper'>
              {isAdmin || ['0', '1'].some(el=>acc[5].includes(el)) ?
              <li className="sidebarmenus my-0 py-0">
                    <NavLink 
                    to='/account/users/active' 
                    className="sidebarmenux my-0 py-0"
                    >Veiw all staff</NavLink>
                    {parseInt(sum_0_user_types) > 0 ? <span style={{padding:'auto', margin:'auto'}} className='badge badge-pill badge-primary pull-right'>{sum_0_user_types}</span>: ''}  
              </li>:""}
              {isAdmin || ['0', '2'].some(el=>acc[5].includes(el)) ?
               <li className="sidebarmenus my-0 py-0">
                    <a 
                    onClick={()=>this.addStaff()} 
                    className="sidebarmenux my-0 py-0"
                    >Add staff</a>
              </li>:''}
              {isAdmin || ['0', '3'].some(el=>acc[5].includes(el)) ?
              <li className="sidebarmenus my-0 py-0">
                    <NavLink 
                    to='/account/userjob' 
                    className="sidebarmenux my-0 py-0"
                    >Job roles</NavLink>
              </li>:''}
              {isAdmin || ['0', '4'].some(el=>acc[5].includes(el)) ?
              <li className="sidebarmenus my-0 py-0">
                    <NavLink 
                    to='/account/user' 
                    className="sidebarmenux my-0 py-0"
                    >Departments</NavLink>
              </li>:''}
              {isAdmin || ['0', '5'].some(el=>acc[5].includes(el)) ?
              <li className="sidebarmenus my-0 py-0">
                    <Link
                    to='/account/userleaves'  
                    className="sidebarmenux my-0 py-0"
                    >Leave request</Link>
                    {parseInt(sum_2_user_leave) > 0 ? <span style={{padding:'auto', margin:'auto'}} className='badge badge-pill badge-primary pull-right'>{sum_2_user_leave}</span>: ''}  
              </li>:''}
              {isAdmin || ['0', '6'].some(el=>acc[5].includes(el)) ?
              <li className="sidebarmenus my-0 py-0">
                    <Link
                    to='/account/userlogs'  
                    className="sidebarmenux my-0 py-0"
                    >Audit log</Link>
                    {parseInt(sum_1_user_trans) > 0 ? <span style={{padding:'auto', margin:'auto'}} className='badge badge-pill badge-primary pull-right'>{sum_1_user_trans}</span>: ''}  
              </li>:''}
           
              </span></>:''}
              
              {isAdmin || ['0'].some(el=>acc[0].includes(el)) ?
              <><li className="sidebarmenu">
                    <span className="fa fa-globe" ></span>
                    <p className="" data-toggle='collapse' data-target="#loks">Location {" "}</p>
                    <span className="fa fa-caret-right" ></span>  
              </li>
              <span className='collapse' id='loks' data-parent='#sidebar-wrapper'>
              
              <li className="sidebarmenus my-0 py-0">
                    <NavLink 
                     onClick={()=>this.props.changeLocation(2)}
                    to='#' 
                    className="sidebarmenux my-0 py-0"
                    >Jebba</NavLink>
              </li>
               <li className="sidebarmenus my-0 py-0">
                    <a 
                    onClick={()=>this.props.changeLocation(1)}
                    to='#'
                    className="sidebarmenux my-0 py-0"
                    >Kainji</a>
              </li>
            
              <li className="sidebarmenus my-0 py-0">
                    <a 
                     onClick={()=>this.props.changeLocation(3)}
                     to='#'
                    className="sidebarmenux my-0 py-0"
                    >Both</a>
              </li>
              </span></>:''}
              <li className="sidebarmenu" onClick={this.logOut}>
                    <span className="fa fa-lock" ></span>
                    <p className="" data-toggle='collapse' data-target="#dash">Logout {" "}</p> 
              </li> 
        </Nav>
        </div>       
      </div>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) =>({
  user: state.userReducer,
  client: state.clientReducer
})

export default connect(mapStateToProps, {getUserLogout, changeLocation, getUsers, getClients})(Sidebar);
