export const ROOMTRANSACTION_GET_MULTIPLE =  "ROOMTRANSACTION_GET_MULTIPLE"
export const ROOMTRANSACTION_GET_ONE =  "ROOMTRANSACTION_GET_ONE"
export const ROOMTRANSACTION_GUEST =  "ROOMTRANSACTION_GUEST"
export const ROOMTRANSACTION_BOOKING =  "ROOMTRANSACTION_BOOKING"
export const ROOMTRANSACTION_COMMENT =  "ROOMTRANSACTION_COMMENT"
export const ROOMTRANSACTION_REGISTER_SUCCESS =  "ROOMTRANSACTION_REGISTER_SUCCESS"
export const ROOMTRANSACTION_REGISTER_FAIL =  "ROOMTRANSACTION_REGISTER_FAIL"
export const ROOMTRANSACTION_LOADING =  "ROOMTRANSACTION_LOADING"
export const ROOMTRANSACTION_LOADING_ERROR =  "ROOMTRANSACTION_LOADING_ERROR"
export const ROOMTRANSACTION_ACTIVATE_FAIL =  "ROOMTRANSACTION_ACTIVATE_FAIL"
export const ROOMTRANSACTION_ACTIVATE_SUCCESS =  "ROOMTRANSACTION_ACTIVATE_SUCCESS"
export const ROOMTRANSACTION_UPDATE_SUCCESS =  "ROOMTRANSACTION_UPDATE_SUCCESS"
export const ROOMTRANSACTION_UPDATE_FAIL =  "ROOMTRANSACTION_UPDATE_FAIL"
export const ROOMTRANSACTION_DELETE_SUCCESS =  "ROOMTRANSACTION_DELETE_SUCCESS"
export const ROOMTRANSACTION_DELETE_FAIL =  "ROOMTRANSACTION_DELETE_FAIL"
export const ROOMTRANSACTION_SUMMARY =  "ROOMTRANSACTION_SUMMARY"