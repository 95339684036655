export const USERTYPE_GET_MULTIPLE =  "USERTYPE_GET_MULTIPLE"
export const USERTYPE_GET_MULTIPLE_ANALYSIS =  "USERTYPE_GET_MULTIPLE_ANALYSIS"
export const USERTYPE_GET_ONE =  "USERTYPE_GET_ONE"
export const USERTYPE_REGISTER_SUCCESS =  "USERTYPE_REGISTER_SUCCESS"
export const USERTYPE_REGISTER_FAIL =  "USERTYPE_REGISTER_FAIL"
export const USERTYPE_LOADING =  "USERTYPE_LOADING"
export const USERTYPE_LOADING_ERROR =  "USERTYPE_LOADING_ERROR"
export const USERTYPE_ACTIVATE_FAIL =  "USERTYPE_ACTIVATE_FAIL"
export const USERTYPE_ACTIVATE_SUCCESS =  "USERTYPE_ACTIVATE_SUCCESS"
export const USERTYPE_UPDATE_SUCCESS =  "USERTYPE_UPDATE_SUCCESS"
export const USERTYPE_UPDATE_FAIL =  "USERTYPE_UPDATE_FAIL"
export const USERTYPE_DELETE_SUCCESS =  "USERTYPE_DELETE_SUCCESS"
export const USERTYPE_DELETE_FAIL =  "USERTYPE_DELETE_FAIL"
export const USERTYPE_EDIT =  "USERTYPE_EDIT"