import React from "react";
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import {getRoomtransactions, getRoomtransaction, registerRoomtransaction, updateRoomtransaction, deleteRoomtransaction } from './../../actions/roomtransaction';
import { getRoomcategorys } from './../../actions/roomcategory';
import { getRoomtypes } from './../../actions/roomtype';
import $ from "jquery";
import { alllocations,  } from "./../../actions/common";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import FormRoomTransaction from "views/Form/FormRoomTransaction";
import FormBooking from "views/Form/FormBooking";
import Select  from 'react-select';

const locs = alllocations;
let inventors = {
    1:'Supply',
    2:'Dispatched',
    3:'Deployed',
    4:'Damaged',
}
const  customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted',
      color: state.isSelected ? 'yellow' : 'black',
      backgroundColor: state.isSelected ? 'green' : 'white'
    }),
    control: (provided) => ({
      ...provided,
      marginTop: "5%",
    })
  }

class BottomCard extends React.Component {
   constructor(props){
       super(props);
       this.state ={
           id:null,
           cat:null,
            grp:null,
            afid:false,
            tfid:false,
            cfid:false,
            mfid:false,
            ifid:false,
            mid:null,
            fid:false,
            data:{},
            subtopic:'All Categories',
            started:'',
            ended:'',
            daterange:'',
            isshown:{},
            room:null,
            userz:null

       }
   }

    componentDidMount(){
        let re = {
            data:JSON.stringify({}),
            cat:'roomall',
            table:'room_types'
        };
        this.props.getRoomtypes(re);
       
        //GET PROPS
        let id = this.props.id;
        let cat = this.props.cat && parseInt(this.props.cat) > 0 ? parseInt(this.props.cat) : 2;
        let dt = new Date();
	    let started  = new Date(dt.getFullYear(), dt.getMonth(), 1);
        let ended = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
        let daterange = ' '+moment(started).format('MMMM Do YYYY') + ' - ' + moment(ended).format('MMMM Do YYYY');
        
	    this.setState({
             id:id,
             cat:cat,
	         started: moment(started).format("YYYY-MM-DD"),
             ended: moment(ended).format("YYYY-MM-DD"),
             daterange:daterange
	    })
            
        
        //GET ALL CATEGORYS
        let res = {};
        let ctn = '';
        let params = {};
        if(parseInt(id) > 0)
        {
            if(parseInt(cat) === 1)
            {
                res = {
                    'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                    'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                    'locationid':this.props.user.location,
                    'categoryid':id
                };
                let catname = this.props.roomtypes.roomtypes && Array.isArray(this.props.roomtypes.roomtypes) && this.props.roomtypes.roomtypes.length > 0 ? this.props.roomcategorys.roomcategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''}
                ctn = catname !== undefined ? catname.name :'None';
                 params = {
                    data:JSON.stringify(res),
                    cat:'roomtransaction',
                    table:'room_transactions'
                 }
            }
            else if(parseInt(cat) === 2){
                let aatname = this.props.roomtypes.roomtypes && Array.isArray(this.props.roomtypes.roomtypes) && this.props.roomtypes.roomtypes.length > 0 ? this.props.roomtypes.roomtypes.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''}
                let atnid = aatname !== undefined ? aatname.categoryid :null;
                let atnm = aatname !== undefined ? aatname.name :null;
                let catname = atnid !== null && parseInt(atnid) > 0 && this.props.roomcategorys.roomcategorys && Array.isArray(this.props.roomcategorys.roomcategorys) && this.props.roomcategorys.roomcategorys.length > 0 ? this.props.roomcategorys.roomcategorys.filter(rw=>parseInt(rw.id) === parseInt(atnid))[0] : {'name':''} 
                let ctnid = catname !== undefined ? catname.name :null;
                ctn = ctnid +' '+atnm;
                res = {
                    'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                    'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                    'locationid':this.props.user.location,
                    'roomid':id
                };
                
                params = {
                    data:JSON.stringify(res),
                    cat:'roomtransaction',
                    table:'room_transactions'
                    }
            }
        }else if(id = 'all'){
            res = {
                    'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                    'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                    'locationid':this.props.user.location
                };
            ctn = 'Bookings';
            params = {
                data:JSON.stringify(res),
                cat:'roomtransaction',
                table:'room_transactions'
            }
        }
        this.setState({subtopic:ctn});
        this.props.getRoomtransactions(params);
        $('#myTablex').DataTable().destroy();
        $('#myTablex').DataTable();
    }
    componentDidUpdate(prevProps, prevState){
        //GET PROPS
        if(prevProps.id !== this.props.id || prevProps.user.location !== this.props.user.location || prevState.starts !== this.state.starts || prevState.ends !== this.state.ends)
        {
         //GET PROPS
         let id = this.props.id;
         let cat = this.props.cat && parseInt(this.props.cat) > 0 ? parseInt(this.props.cat) : 2;
         let started  = new Date(this.state.started);
         let ended = new Date(this.state.ended);
         let daterange = ' '+moment(started).format('MMMM Do YYYY') + ' - ' + moment(ended).format('MMMM Do YYYY');
         
         this.setState({
              id:id,
              cat:cat,
              started: moment(started).format("YYYY-MM-DD"),
              ended: moment(ended).format("YYYY-MM-DD"),
              daterange:daterange
         })
             
         
         //GET ALL CATEGORYS
         let res = {};
         let ctn = '';
         let params = {};
         if(parseInt(id) > 0)
         {
             if(parseInt(cat) === 1)
             {
             res = {
                     'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                     'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                     'locationid':this.props.user.location,
                     'roomid':id
                 };
             let catname = this.props.roomtypes.roomtypes && Array.isArray(this.props.roomtypes.roomtypes) && this.props.roomtypes.roomtypes.length > 0 ? this.props.roomcategorys.roomcategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''}
             ctn = catname !== undefined ? catname.name :'None';
                  params = {
                     data:JSON.stringify(res),
                     cat:'roomtransaction',
                     table:'room_transactions'
                  }
             }
             else{
                 let aatname = this.props.roomtypes.roomtypes && Array.isArray(this.props.roomtypes.roomtypes) && this.props.roomtypes.roomtypes.length > 0 ? this.props.roomcategorys.roomcategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''}
                 let atnid = aatname !== undefined ? aatname.categoryid :null;
                 let atnm = aatname !== undefined ? aatname.name :null;
                 let catname = atnid !== null && parseInt(atnid) > 0 && this.props.roomcategorys.roomcategorys && Array.isArray(this.props.roomcategorys.roomcategorys) && this.props.roomcategorys.roomcategorys.length > 0 ? this.props.roomcategorys.roomcategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''} 
                 let ctnid = catname !== undefined ? catname.name :null;
                 ctn = ctnid +' '+atnm;
                 res = {
                     'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                     'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                     'locationid':this.props.user.location,
                     'roomid':id
                 };
                 
                 params = {
                     data:JSON.stringify(res),
                     cat:'roomtransaction',
                     table:'room_transactions'
                     }
             }
         }else if(id = 'all')
         {
             res = {
                     'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                     'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                     'locationid':this.props.user.location
                 };
             ctn = 'Room Transaction List';
             params = {
                 data:JSON.stringify(res),
                 cat:'roomtransaction',
                 table:'room_transactions'
             }
         }
         this.setState({subtopic:ctn});
         this.props.getRoomtransactions(params);
         $('#myTablex').DataTable();
         
        }
            

    }
    componentWillUnmount(){
        $('#myTablex').DataTable().destroy();
    }
    control = (id, func, uss) =>{
        Swal.fire({
            title: 'Confirm/Approve Entry',
            type: 'question',
            html: '<select id="txts1"  class="swal2-input form-control"><option value="1">Lodged</option><option value="2">Vacated</option></select>',
            customClass: 'swal2-overflow',
            
          }).then(function(result) {
                  let num = $('#txts1').val();
                  if(parseInt(num) === 1)
                  {
                  let fd = new FormData();
                  fd.append('id', id);
                  fd.append('is_lodged', 1);
                  fd.append('complaints', uss.user.surname +" "+uss.user.firstname);
                  fd.append('endbook', moment(new Date()).format("YYYY-MM-DD hh:mm:ss"));
                  fd.append('table', 'room_transactions');
                  fd.append('cat', 'update');
                  fd.append('rowzid', uss.user.id);
                  fd.append('locateid', num =1 ? 'Approved':'Unapproved' + ' leave');
                  func(fd);
                  }if(parseInt(num) === 2)
                  {
                    let fd = new FormData();
                    fd.append('id', id);
                    fd.append('is_lodged', 1);
                    fd.append('is_vacated', 1);
                    fd.append('complaints', uss.user.surname +" "+uss.user.firstname);
                    fd.append('endbook', moment(new Date()).format("YYYY-MM-DD hh:mm:ss"));
                    fd.append('endleft', moment(new Date()).format("YYYY-MM-DD hh:mm:ss"));
                    fd.append('table', 'room_transactions');
                    fd.append('cat', 'update');
                    fd.append('rowzid', uss.user.id);
                    fd.append('locateid', num =1 ? 'Approved':'Unapproved' + ' leave');
                    func(fd);
                  }
                  
              
          });    
    }
    retState = (dt, dt1) =>{
        this.setState({starts:new Date(dt), ends:new Date(dt1)});
    }
    //EDIT ROOM
    editForm = (id, dat) =>{
        this.props.getRoomtransaction(id);
        this.setState({cfid:true, mid:id});
    }
    //DELETE ROOM
    deleteForm = id =>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You will not be able restore",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
        
            if (result.value) {
                let fd = new FormData();
                fd.append('id', id);
                fd.append('is_delete', 1);
                fd.append('cat', 'update');
                fd.append('table', 'room_transactions');
                this.props.updateRoomtransaction(fd);
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            }
          })
        
    }
    //ACTIVATE ROOM
    activateForm = (id, ac) =>{
        let act = ac === 0 ? 1 : 0;
        let fd = new FormData();
        fd.append('id', id);
        fd.append('is_active', act);
        fd.append('cat', 'update');
        fd.append('table', 'room_transactions');
        this.props.updateRoomtransaction(fd);
    }
   
   
    loadShown = (id, cat) =>{
          let ar = {};
          if(cat === 0)
          {
              ar[id] = true;
          }else{
              ar[id] = false;
          }
          this.setState({isshown:ar});
    }
    lunchDate = (func, func1, locs) =>{
        Swal.fire({
            title: 'Pick a date from and to:',
            type: 'question',
            html: '<input id="datepicker" type="date"  class="swal2-input"><br/><input id="datepicker1" type="date"  class="swal2-input">',
            customClass: 'swal2-overflow',
            
          }).then(function(result) {
              if(result.value)
              {
                  let v = $('#datepicker').val();
                  let v1 = $('#datepicker1').val();
                  let params = {
                    data:JSON.stringify(
                    {
                        'starts':moment(new Date(v)).format('YYYY-MM-DD'), 
                        'ends':moment(new Date(v1)).format('YYYY-MM-DD'), 
                        'locationid':locs
                    }),
                    cat:'roomtransaction',
                    table:'room_transaction'
                }
                func(params);
                func1(v, v1);
              }
          });
    }
    changeRoom = (e) =>{
        this.setState({room:e.value});
    }
    filterUser = (e) =>{
        this.setState({userz:e});
    }
    retState = (dt, dt1) =>{
        let daterange = ' '+moment(new Date(dt)).format('MMMM Do YYYY') + ' - ' + moment(new Date(dt1)).format('MMMM Do YYYY');
        this.setState({started:new Date(dt), ended:new Date(dt1), daterange:daterange});
    }

    render() {
        let { cfid, mid, isshown, subtopic, daterange, room, userz } = this.state  || '';
        let datax = this.props.roomtransactions.roomtransactions;
        let data = datax;
        if(room !== null && parseInt(room) > 0)
        {
            data = data && Array.isArray(data) ? data.filter(rw=>rw !== null && rw !== undefined && parseInt(rw.roomid) === parseInt(room)):[];
        }
        if(userz !== null ){
            data = data && Array.isArray(data) ? data.filter(rw=>rw !== null && rw !== undefined && rw.fullname == userz):[];
        }
        
        let tabl = data && Array.isArray(data) ? data.filter(rw=>rw !== null).map((prop, ind)=>{
            return <tr 
                    key={ind}
                        style={{padding:'1px', margin:'0px'}}
                        onMouseEnter={()=>this.loadShown(prop.id, 0)}
                        onMouseLeave={()=>this.loadShown(prop.id, 1)}
                        >
                        <td className='text-center'>{prop.id !== undefined ? String(prop.id).padStart(7, '0') : ''}</td>
                        <td>
                        <small><b >{moment(prop.transaction_date).format('DD-MM-YYYY')}</b></small><br/>
                        <small>{moment(prop.date_created).format('DD-MM-YYYY')}</small>
                            
                        </td>
                        <td style={{textAlign:'left'}}>
                            <b style={{textTransform:'capitalize'}}>{prop.fullname}</b><br/>
                            <small>{prop.categoryname}{" | "}{prop.roomname}</small>
                            { prop.id in isshown && isshown[prop.id] === true ? 
                                <span className='reportcontrol pull-right'>
                                    <a onClick={()=>this.filterUser(prop.fullname)} title='Filter by name' href='#'><i className='fa fa-filter text-secondary'></i></a>
                                    <a onClick={()=>this.control(prop.id, this.props.updateRoomtransaction, this.props.user)} title='Lodged' href='#'><i className="fa fa-bed"></i></a>
                                    <a onClick={()=>this.editForm(prop.id, prop)} title='Edit' href='#'><i className='fa fa-edit text-info'></i></a>
                                    <a onClick={()=>this.deleteForm(prop.id, prop)} title='Delete' href='#'><i className='now-ui-icons ui-1_simple-remove'></i></a>
                                </span>
                            : ''}
                        </td>
                        <td className='text-left'>
                           <i className='fa fa-envelope'></i>{'  '}<a href={`mailto:${prop.email}`}>{prop.email}</a><br/>
                           <i className='fa fa-phone'></i>{'  '}<a href={`tel:${prop.email}`}>{prop.phone}</a>
                        </td>
                        {new Date(prop.transaction_date) < new Date() ?
                        <td>
                        {parseInt(prop.is_lodged) === 1 ? <small><b className="text-success">Lodged</b></small> : <small><b className="text-danger">Pending</b></small> }
                        {" | "}{parseInt(prop.is_vacated) === 1  && parseInt(prop.is_lodged) === 1 ? <small><b className="text-success">Vacated</b></small> : <small><b className="text-info">Inhouse</b></small>}
                        </td>:
                        <td>
                            <i className='badge badge-pill badge-info'>Booked</i>
                        </td>}
                        <td style={{textAlign:'left'}}>
                            <small>{prop.username}</small><br/>
                            <small>{prop.complaints}</small>
                        </td>
                    </tr>
                }): '';
        
                let options = [];
                let oops = this.props.roomtypes.roomtypes && Array.isArray(this.props.roomtypes.roomtypes) ? this.props.roomtypes.roomtypes:[]
                oops.filter(rw=>rw !== null && rw !== undefined).forEach(pt=>{
                    let ar = {};
                    ar['value'] = pt.id;
                    ar['label'] = pt.categoryname+" "+pt.name;
                    return options.push(ar);
                   })

        return (
            <>
            
            {cfid === true ? 
            <FormBooking
                st={cfid}
                mid={mid}
                handleClose={()=>this.setState({mid:null, cfid:false})}
            />:''}
           
              <div className="content">
                  <div className='card'>
                  <div className='card-header tablecardheader'>
                  
                  <Container xs='12'>
                      <Row>
                          <Col>
                          <h4 className='cardtitl'><i className='fa fa-bed'></i> {subtopic}</h4>
                          </Col>
                      </Row>
                  <Row xs='12'>
                      <Col>{daterange}</Col>
                      <Col xs='3'>
                      <Select
                            value={room}
                            styles = { customStyles }
                            onChange={this.changeRoom}
                            options={options}
                            autoFocus={true}
                            placeholder='Select Room'
                            />
                      </Col>
                      <Col xs='1'>
                      <a class="btn btn-outline-info pull-right" title='Change date range' onClick={()=>this.lunchDate(this.props.getRoomtransactions, this.retState, this.props.user.location)} href="#">
                                <i className="now-ui-icons ui-1_calendar-60"></i>
                      </a>
                     </Col>
                     <Col xs='1'>
                      <a class="btn btn-outline-danger pull-right" title='Clear filter' onClick={()=>this.setState({userz:null, room:null})} href="#">
                                <i className="fa fa-eraser"></i>
                      </a>
                     </Col>
                      </Row>
                      </Container>
                  </div>
                  <div className='card-body table-responsive'>
                    <table ref={el=>this.el = el} className='table-bordered display wrap table-striped mytables table-condensed table-hover'  id='myTablez' width='100%' >
                        <thead>
                            <tr>
                                <th style={{maxWidth:'50px'}}>TID</th>
                                <th><small>Date Booked: <br/>Date Recorded:</small> </th>
                                <th><small>Guest Name<br/>House | Room</small> </th>
                                <th>Contact</th>
                                <th>State</th>
                                <th><small>Booked By: <br/>Lodged By:</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tabl}
                        </tbody>
                    </table>
                  </div>
                  </div>
              </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({ 
    user:state.userReducer,
    roomcategorys:state.roomcategoryReducer,
    roomtypes:state.roomtypeReducer,
    roomtransactions:state.roomtransactionReducer
  })
  
export default connect(mapStateToProps, 
    { 
        getRoomtransactions,
        getRoomtransaction,
        updateRoomtransaction,
        registerRoomtransaction,
        deleteRoomtransaction,
        getRoomcategorys,
        getRoomtypes
    })(BottomCard)
