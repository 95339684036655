/*eslint-disable*/
import React from "react";
import Animate from 'animate.css-react'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// reactstrap components
import { Container } from "reactstrap";
// core components

function IndexHeader(props) {
  const {user:userReducer} = props
  const {isAuthenticated, user } = userReducer
  const {id, isAdmin, surname, firstname, is_role } = user || ''
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform = "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
    
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/header.jpg") + ")",
          }}
          ref={pageHeader}
        ></div>
        <Container className='pagemaker'>
          <div className="content-center brand" style={{padding:'15px' , backgroundColor:'rgba(0, 0, 0, 0.4)', borderRadius:'10px'}}>
          {/* <Animate
                key={1}
                appear="fadeIn"
                durationAppear={2000}
                component="div" >
            </Animate> */}
            {/* <Animate
                key={2}
                appear="fadeInDown"
                durationAppear={3000}
                component="div" >
            <h1 className="h1-seo shadowlogo" style={{fontFamily:'Poiret One', textTransform:'capitalize'}}>MESL Guest Houses</h1>
            </Animate> */}
             {/* <Animate
                key={3}
                appear="fadeInDown"
                durationAppear={4000}
                component="div" >
            {props.user.isAuthenticated ? <Link to='/account/home' className='btn btn-round btn-lg btn-info'><i className="now-ui-icons users_single-02"></i> {props.user.user.surname}{' '} {props.user.user.firstname} Dashboard</Link>: <><a href='#booking' className='btn btn-round btn-lg btn-warning'><i className="now-ui-icons files_paper"></i> <b>Guest Booking</b></a><a href='#' onClick={()=>props.login(true)} className='btn btn-round btn-lg btn-outline-secondary'><i className="now-ui-icons ui-1_lock-circle-open"></i> Staff login</a></>}
              </Animate> */}
               <h1 className="h1-seo shadowlogo" style={{fontFamily:'Poiret One', textTransform:'capitalize'}}>MESL Guest Houses</h1>
               {isAuthenticated ? 
               
                 is_role == 2 ?
                  <Link to='/guestmultiple' className='btn btn-round btn-lg btn-info'>
                 <i className="now-ui-icons users_single-02"></i> Guest Dashboard</Link>:
                 <>
                  <Link to='/guestmultiple' className='btn btn-round btn-lg btn-info'>
                 <i className="now-ui-icons users_single-02"></i> Guest Dashboard</Link>
                 <Link to='/account/home' className='btn btn-round btn-lg btn-info'>
                 <i className="now-ui-icons users_single-02"></i> {surname}{' '} {firstname} Dashboard</Link>
                 </>
                 : 
                 
                 <>
                 <Link to='/guestmultiple' className='btn btn-round btn-lg btn-info'>
                 <i className="now-ui-icons users_single-02"></i> Booking Mulitple Guest</Link>
                 <a href='#booking' className='btn btn-round btn-lg btn-warning'>
                   <i className="now-ui-icons files_paper"></i> <b>Booking</b></a>
                   <a href='#' onClick={()=>props.login(true)} className='btn btn-round btn-lg btn-outline-secondary'>
                     <i className="now-ui-icons ui-1_lock-circle-open"></i> Staff login</a>
                 </>}
          </div>
          
        </Container>
      </div>
    </>
  );
}
const mapStateToProps = (state, ownProps) => ({ 
    user:state.userReducer
})
  
export default connect(mapStateToProps, {})(IndexHeader);
