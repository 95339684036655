import axios from 'axios';
import {
    USERLEAVETRANSACTION_GET_ONE,
    USERLEAVETRANSACTION_GET_MULTIPLE,
    USERLEAVETRANSACTION_REGISTER_SUCCESS,
    USERLEAVETRANSACTION_REGISTER_FAIL,
    USERLEAVETRANSACTION_LOADING,
    USERLEAVETRANSACTION_LOADING_ERROR,
    USERLEAVETRANSACTION_UPDATE_SUCCESS,
    USERLEAVETRANSACTION_UPDATE_FAIL,
    USERLEAVETRANSACTION_DELETE_SUCCESS,
    USERLEAVETRANSACTION_DELETE_FAIL,
    USERLEAVETRANSACTION_EDIT,
} from "./../types/userleavetransaction";
import { MAIN_TOKEN, SERVER_PATHS, axiosConfig1, axiosConfig } from './common';

let TABLE_NAME = 'user_leave_transactions';
const path = SERVER_PATHS + '/staff.php';;

let params = {
    data:{},
    cat:'all',
    table:TABLE_NAME,
    token:MAIN_TOKEN
  }
//GET ALL USERLEAVETRANSACTION 
export const getUserleavetransactions = params => (dispatch, getState) => {
    //SET PAGE LOADING
    params.token = MAIN_TOKEN;
    dispatch({type : USERLEAVETRANSACTION_LOADING});
        axios.get(path, {params}, axiosConfig)
            .then(res => {                                                                                                                                                                                                                                        
                dispatch({
                    type: USERLEAVETRANSACTION_GET_MULTIPLE,
                    payload: res.data
                })
            })
            .catch(err => {
                dispatch({
                    type : USERLEAVETRANSACTION_LOADING_ERROR,
                    payload:err
                })
            })
};
//GET SINGLE USERLEAVETRANSACTION 
export const getUserleavetransaction = id => (dispatch, getState) => {
    //SET PAGE LOADING
    dispatch(
        {
        type : USERLEAVETRANSACTION_GET_ONE,
        payload: id
    });  
};
//USERLEAVETRANSACTION DELETE
export const deleteUserleavetransaction = (dat, id )=> (dispatch, getState) =>{
    
    axios.post(path, dat, axiosConfig)
        .then(res => {
            dispatch({
                type: USERLEAVETRANSACTION_DELETE_SUCCESS,
                payload: id
            })
        })
        .catch(err => {
            dispatch({
                type : USERLEAVETRANSACTION_DELETE_FAIL,
                payload : err
            })
        })
        
}
//USERLEAVETRANSACTION REGISTER
export const registerUserleavetransaction = data => dispatch => {
   
    axios.post(path, data, axiosConfig1)
        .then(res => {
            dispatch({
                type: USERLEAVETRANSACTION_REGISTER_SUCCESS,
                payload: res.data.data
            })
        })
        .catch(err => {
            dispatch({
                type : USERLEAVETRANSACTION_REGISTER_FAIL,
                payload: err
            })
        })
};
 //USERLEAVETRANSACTION UPDATE
export const updateUserleavetransaction = (data) => (dispatch, getState) => {
    //body
    axios.post(path, data, axiosConfig1)
        .then(res => {
            dispatch({
                type: USERLEAVETRANSACTION_UPDATE_SUCCESS,
                payload: res.data.data
            })
        })
        .catch(err => {
            dispatch({
                type : USERLEAVETRANSACTION_UPDATE_FAIL,
                payload: err
            })
        })
};
