import Swal from 'sweetalert2';
export const API_PATHS = process.env.REACT_APP_API_PATHS;
export const SERVER_PATHS = process.env.REACT_APP_SERVER_PATHS;
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const MAIN_TOKEN ='';
export const PICS = process.env.REACT_APP_PHOTO;
export const imgx = process.env.REACT_APP_PHOTO;
export const imgb = process.env.REACT_APP_BG;
export const axiosConfig = {
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
    }
  };
export const axiosConfig1 = {
    headers: {
        "Content-Type": "multipart/form-data"
    }
  };
  export const validatejson = (val) =>{
    try {
      JSON.parse(val)
      return true;
    } catch (error) {
      return false
    }

  }
export const callError = (err) =>{
  Swal.fire({
    position: 'top-end',
    icon: 'error',
    title: err,
    showConfirmButton: false,
    timer: 1000
  })
}
export const callSuccess = (err) =>{
  Swal.fire({
    position: 'top-end',
    icon: 'success',
    title: err,
    showConfirmButton: false,
    timer: 1000
  })
}
  export const checkImage = imageSrc =>{
      var img = new Image();
      try{
        img.src = imageSrc;
        return true;
      }catch(err){
        return false;
      }

  }
  export const timeConvert = date =>{
    return '';

}
export const alllocations = {
   1:'Kainji',
   2:'Jebba',
   3:'Unified'
  };
export const alllocationsObj = [
{'value':1, 'label':'Kainji'},
{'value':2, 'label':'Jebba'}
];
export const statuss = {
    1 :'Low',
    2 :'Normal',
    3 :'High',
    4 :'Emergency'
  };
export const statussobj = [
    {'value':1, 'label':'Low'},
    {'value':2, 'label':'Normal'},
    {'value':3, 'label':'High'},
    {'value':4, 'label':'Emergency'}
  ];
export const access = {
  0: {
        name:'Location/Dashboard',
        data:{
          0:'All locations',
          1:'Kainji',
          2:'Jebba',
          3:'Veiw HouseKeeping Dashboard',
          4:'Veiw Maintenance Dashboard',
          5:'Veiw Employee Dashboard',
        }
      },
  1: {
        name:'Guest',
        data: {
              0:'All',
              1:'Veiw Guest Information',
              2:'Veiw Guest Complaints',
              3:'Send request for guest feedback'
              }
      },
  2: {
        name:'Booking',
        data : {
            0:'All',
            1:'Log booking activities',
            2:'Veiw reports',
            3:'Manage Houses',
            4:'Manage Rooms'
          }
      },
  3: {
      name:'Inventory',
      data : {
          0:'All',
          1:'Make inventory Request',
          2:'Veiw Reports',
          3:'Approve Inventory request',
          4:'Manage Inventory Categories',
          5:'Manage Inventories List'
        }
    },
  4: {
    name:'Maintenance',
    data : {
      0:'All',
      1:'Apply for Maintenance',
      2:'Veiw Reports',
      3:'Approve Maintenance request',
      4:'Manage Maintenance Categories',
      5:'Manage Maintenance Issues'
    }
  },
  5: {
    name:'Employee',
    data : {
      0:'All',
      1:'Add Employee',
      2:'Assign Job Role',
      3:'Approve Leave',
      4:'Veiw All Staff Data',
      5:'Change Staff Data',
      6:'Audit Log'
      }
  },
}
export const tablereport = {
    'user_categorys':{
        'update':' updated department with id',
        'insert':' created department with id ',
        'confirm':' created department with id ',
        'delete':' deleted department with id ',
        'login':' logged in ',
    },
    'user_transactions':{
        'update':' made a change with id',
        'insert':' created department with id ',
        'confirm':' created department with id ',
        'delete':' deleted department with id '
    },
    'user_types':{
        'update':' changed staff data with id ',
        'insert':' created staff record with id ',
        'confirm':' created staff record with id ',
        'delete':' deleted staff record with id '
    },
    'inventory_categorys':{
        'update':' updated inventory category with id',
        'insert':' created inventory category with id ',
        'confirm':' created inventory categorywith id ',
        'delete':' deleted inventory category with id '
    },
    'inventory_transactions':{
        'update':' Changed inventory record with id',
        'insert':' created inventory record with id ',
        'confirm':' created inventory record with id ',
        'delete':' deleted inventory record with id '
    },
    'inventory_types':{
        'update':' updated inventory category with id',
        'insert':' created inventory category with id ',
        'confirm':' created inventory categorywith id ',
        'delete':' deleted inventory category with id '
    },
    'maintenance_categorys':{
        'update':' updated maintenance category with id',
        'insert':' created maintenance category with id ',
        'confirm':' created maintenance category with id ',
        'delete':' deleted maintenance category with id '
    },
    'maintenance_transactions':{
        'update':' Changed maintenance record with id',
        'insert':' created maintenance record with id ',
        'confirm':' created maintenance record with id ',
        'delete':' deleted maintenance record with id '
    },
    'maintenance_types':{
        'update':' updated maintenance type with id',
        'insert':' created maintenance type with id ',
        'confirm':' created maintenance type ywith id ',
        'delete':' deleted maintenance type with id '
    },
    'room_categorys':{
        'update':' updated a house with id',
        'insert':' created a house with id ',
        'confirm':' created a house with id ',
        'delete':' deleted a house with id '
    },
    'room_transactions':{
        'update':' updated room record with id',
        'insert':' booked room record with id ',
        'confirm':' booked room record with id ',
        'delete':' deleted booking record with id '
    },
    'room_types':{
        'update':' updated room with id',
        'insert':' created room with id ',
        'confirm':' created room with id ',
        'delete':' deleted room with id '
    },
}