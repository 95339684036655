import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { registerUserleavetransaction, updateUserleavetransaction } from './../../actions/userleavetransaction';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, FormText, Label, Input, Col } from 'reactstrap';


const Modals = (props) => {
  
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(null);
  const [starts, setStarts] = useState(moment(new Date()).format('YYYY-MM-DD hh:mm:ss'));
  const [ends, setEnds] = useState(moment(new Date()).format('YYYY-MM-DD hh:mm:ss'));
  const [reason, setReason] = useState(null);
  

  const resetdata= async() =>{
    toggle();
    setId(0);
    props.handleClose();
}

  const toggle = () => setModal(!modal);
  useEffect(() => {
    setModal(props.st);
    if(parseInt(props.mid) > 0 )
    {
     setId(parseInt(props.mid));
     populate(props.userleavetransactions.userleavetransaction);  
    }
    
},[props.st]);

  const handleSubmit = (e) =>{
        e.preventDefault();
      
        let fd = new FormData();
        fd.append('starts', starts);
        fd.append('ends', ends);
        fd.append('reason', reason);
        fd.append('table', 'user_leave_transactions');
        fd.append('rowzid', props.user.user.id);
        

        if(id && id > 0)
        {
          fd.append('id', id);
          fd.append('cat', 'update');
          fd.append('locateid', 'Changed leave record');
          props.updateUserleavetransaction(fd);
        }else{
          fd.append('staffid', props.staffid);
          fd.append('cat', 'insert');
          fd.append('locateid', 'Appllied for leave');
          props.registerUserleavetransaction(fd);
        }
        
        resetdata();
  }

  const populate = async(data) =>{
        setStarts(data.name);
        setEnds(data.description);
        setReason(data.reason);
    }

    
  
  let editId = id ? id : null;
  let editColor = 'primary';

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false}>
        <ModalHeader toggle={resetdata}><i className='fa fa-plane'></i> Leave Request Form</ModalHeader>
        <ModalBody>
        <Form>
        <FormGroup row>
                <Label for="starts" sm={12}>Leave will start: </Label>
                <Col sm={12}>
              <Input 
                      type="datetime-local" 
                      name="starts" 
                      id="starts" 
                      placeholder='starts' 
                      required
                      defaultValue={starts}
                      onChange={e=>setStarts(e.target.value)} 
                      />
                      
          </Col>
          </FormGroup>
          <FormGroup row>
                <Label for="ends" sm={12}>Leave will end: </Label>
                <Col sm={12}>
              <Input 
                      type="datetime-local" 
                      name="ends" 
                      id="ends" 
                      placeholder='ends' 
                      required
                      defaultValue={ends}
                      onChange={e=>setEnds(e.target.value)} 
                      />
                      
          </Col>
          </FormGroup>

          <FormGroup row>
                <Label for="reason" sm={12}>Why do you need the time of work? </Label>
                <Col sm={12}>
              <Input 
                      type="textarea" 
                      name="reason" 
                      id="reason" 
                      placeholder='Annual Leave, To meet up a medical appointment... etc' 
                      required
                      defaultValue={reason}
                      onChange={e=>setReason(e.target.value)} 
                      />
                      
          </Col>
          </FormGroup>
          
            
           
            
        </Form>
        </ModalBody>
        <ModalFooter>
          <Button color={editColor} onClick={handleSubmit}>{editId ? 'Edit' : 'Submit'}</Button>{' '}
          <Button color="secondary" onClick={resetdata}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
const mapStateToProps = (state, ownProps) => ({ 
    user:state.userReducer,
    userleavetransactions:state.userleavetransactionReducer
  })
  
export default connect(mapStateToProps, { registerUserleavetransaction, updateUserleavetransaction })(Modals)
