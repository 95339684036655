import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getRoomtransactions, getRoomtransaction, registerRoomtransaction, updateRoomtransaction } from './../../actions/roomtransaction';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, FormText, Label, Input, Col, Container, Row } from 'reactstrap';
import moment from 'moment';

const Modalx = (props) => {
  
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(null);
  const [title, setTitle] = useState('Select Dates');
  const [activemonth, setActivemonth] = useState(0);
  const [activeyear, setActiveyear] = useState(0);
  const [booking, setBooking] = useState({});
  const [datas, setDatas] = useState({});
  const [keepdate, setKeepdate] = useState([]);
  const [keeplog, setKeeplog] = useState([]);

  const toggle = () => setModal(!modal);
  useEffect(()=>{
    let arrr = calendarArray(activeyear, activemonth);
    setDatas(arrr);   
  },[activemonth, activeyear])

  useEffect(() => {
    setModal(true);
   
    let dt = new Date();
    let yrs = dt.getFullYear();
    let mts = dt.getMonth();
    setActivemonth(mts);
    setActiveyear(yrs);
    if(Array.isArray(props.dates)){
        setKeepdate(props.dates)
    }
},[props.st])
const resetdata = () =>{
    props.handleClose();
}
const onNext=()=>{
    let cmonth = activemonth;
    let cyear = activeyear;
    let nmonth = activemonth;
    let nyear = activeyear;
    if(cmonth === 12)
    {
        nyear = cyear + 1;
        nmonth = 1;
    }else
    {
        nyear = cyear;
        nmonth = cmonth + 1
    }
    setActivemonth(nmonth);
    setActiveyear(nyear);
}
const onPrev=()=>{
    let cmonth = activemonth;
    let cyear = activeyear;
    let nmonth = activemonth;
    let nyear = activeyear;
    if(cmonth === 1)
    {
        nyear = cyear - 1;
        nmonth = 12;
    }else
    {
        nyear = cyear;
        nmonth = cmonth - 1
    }
    setActivemonth(nmonth);
    setActiveyear(nyear);
}

const calendarArray = (yr, mt) =>{ 
    
    let numOfDays = new Date(yr, mt, 0).getDate() ;
    let firstday = new Date(yr, mt, 1).getDay();
    let lastday = new Date(yr, mt + 1, 0).getDay();
    let totaldays = numOfDays + firstday;
    let noOfWeeks = Math.ceil(totaldays / 7);
    let offset = numOfDays - totaldays + 1;
    let days = {};

   
      for(let i = 0; i < noOfWeeks; ++i)
    {
        let b = {};
            for(let j = 0 ; j < 7; ++j)
            {
                let ar = {
                    'wk':i,
                    'dy':j,
                    'year':yr,
                    'month':mt,
                    'ds': offset++,
                    'dt':new Date(yr, mt, offset).toJSON(),
                    'dx':new Date(yr, mt, offset).getDate()
                }
                b[j] = ar;
            }
            days[i] = b;
    }
    return days;
}

const loadForm= () =>{
    console.log(keepdate);
    props.handleBooking(keepdate);
    resetdata();
}

const removedata = () =>{

}

const selectDate = (dt, lg) =>{
    let d = [...keepdate];
    let k = [...keeplog];
    if(d.includes(dt))
    {
        let y = d.filter(rw =>rw !== dt );
        setKeepdate(y);
    }else
    {
        d.push(dt);
        setKeepdate(d);
    }

    if(lg !== 0){
            if(k.includes(lg))
            {
                let y1 = k.filter(rw =>rw !== lg );
                setKeeplog(y1);
            }else
            {
                k.push(lg);
                setKeeplog(k);
            }
        }
    
}

let d = [0, 1, 2, 3, 4].map((prop, ind)=>{
     return <tr>
            { prop in datas ?
                [0, 1, 2, 3, 4, 5, 6].map((prop1, ind1)=>{
                    return prop1 in datas[prop] ? <td 
                            key={ind1.toString() + ind.toString()}
                            style={{border : keepdate.includes(moment(new Date(datas[prop][prop1]['dt'])).format("YYYY-MM-DD")) ? '5px solid skyblue' : ''}}
                            className={ id in booking && moment(new Date(datas[prop][prop1]['dt'])).format("YYYY-MM-DD") in booking[id] && booking[id][moment(new Date(datas[prop][prop1]['dt'])).format("YYYY-MM-DD")]['islodged'] == 1 ? "tablerow1 bg-info" : "tablerow2"}
                            onClick={()=>selectDate(moment(new Date(datas[prop][prop1]['dt'])).format("YYYY-MM-DD"),  id in booking && moment(new Date(datas[prop][prop1]['dt'])).format("YYYY-MM-DD") in booking[id]  ? booking[id][moment(new Date(datas[prop][prop1]['dt'])).format("YYYY-MM-DD")]['rowid'] : 0)}>
                        <div>
                          <b>{datas[prop][prop1]['dx']}</b>
                        </div>
                        <div 
                            className='m-0 p-0' 
                            style={{
                                font :'Josefin Sans',
                                width:'100%', 
                                fontSize:'0.8em', 
                                minHeight:'80%',
                                lineHeight:'110%',
                                textAlign:'center',
                                textTransform:'capitalize',
                                fontWeight:'normal'
                                }}>
                            <b className={id in booking && moment(new Date(datas[prop][prop1]['dt'])).format("YYYY-MM-DD") in booking[id] && booking[id][moment(new Date(datas[prop][prop1]['dt'])).format("YYYY-MM-DD")]['islodged'] == 0 ? 'text-info': 'text-light'}>
                            {id in booking && moment(new Date(datas[prop][prop1]['dt'])).format("YYYY-MM-DD") in booking[id] ? booking[id][moment(new Date(datas[prop][prop1]['dt'])).format("YYYY-MM-DD")]['name'] : ''}
                            </b></div>
                    </td>: ''
                })
            :''}
     </tr>

});

let get_days_num = id in booking ?  Object.keys(booking[id]).length : 0;
let month_days = new Date(activeyear, activemonth, 0).getDate();
let occ = get_days_num >  0 ? (get_days_num/month_days) * 100 : 0;

  return (
    <div>
      <Modal isOpen={modal} size='lg'  toggle={toggle} backdrop='static' keyboard={false}>
        <ModalHeader toggle={resetdata} xs='12'>
            <Container xs='12' style={{width:'500px'}} >
                <Row xs='12'>
                    <Col xs='12' class="pull-right" style={{margin:'0px', top:'0px'}}>
                    <Row xs='12'>{title}</Row>
                    </Col>
                </Row>
                </Container>
            </ModalHeader>
        <ModalBody>
            <Container xs='12'>
                <Row xs='12' className='table-responsive'>
           <table width='100%' border='2px' style={{tableLayout:'fixed', width:'100%'}}>
                <thead>
                    <tr>
                        <td className='calenderHeader1'>Mon</td>
                        <td className='calenderHeader1'>Tues</td>
                        <td className='calenderHeader1'>Wed</td>
                        <td className='calenderHeader1'>Thu</td>
                        <td className='calenderHeader1'>Fri</td>
                        <td className='calenderHeader1'>Sat</td>
                        <td className='calenderHeader1'>Sun</td>
                    </tr>
                </thead>
                <tbody>
                    {d}
                </tbody>

            </table>
            </Row> 
            </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onPrev}>Previos</Button>{" "}
          { keepdate.length > 0 ? <Button color="success" onClick={loadForm}>Save</Button>:''}
          { keeplog.length > 0 ? <Button color="danger" onClick={removedata}>Remove</Button>:''}
          <Button color="secondary" onClick={resetdata}>Close</Button>{" "}
          <Button color="primary" onClick={onNext}>Next</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
const mapStateToProps = (state, ownProps) => ({ 
    roomtransactions: state.roomtransactionReducer.roomtransactions,
    user:state.userReducer.user,
    roomcategorys:state.roomcategoryReducer.roomcategorys,
    rooms:state.roomReducer.rooms,
  })
  
export default connect(mapStateToProps, { getRoomtransactions, getRoomtransaction, registerRoomtransaction, updateRoomtransaction})(Modalx)
