export const MAINTENANCETYPE_GET_MULTIPLE =  "MAINTENANCETYPE_GET_MULTIPLE"
export const MAINTENANCETYPE_GET_MULTIPLE_ANALYSIS =  "MAINTENANCETYPE_GET_MULTIPLE_ANALYSIS"
export const MAINTENANCETYPE_GET_ONE =  "MAINTENANCETYPE_GET_ONE"
export const MAINTENANCETYPE_REGISTER_SUCCESS =  "MAINTENANCETYPE_REGISTER_SUCCESS"
export const MAINTENANCETYPE_REGISTER_FAIL =  "MAINTENANCETYPE_REGISTER_FAIL"
export const MAINTENANCETYPE_LOADING =  "MAINTENANCETYPE_LOADING"
export const MAINTENANCETYPE_LOADING_ERROR =  "MAINTENANCETYPE_LOADING_ERROR"
export const MAINTENANCETYPE_ACTIVATE_FAIL =  "MAINTENANCETYPE_ACTIVATE_FAIL"
export const MAINTENANCETYPE_ACTIVATE_SUCCESS =  "MAINTENANCETYPE_ACTIVATE_SUCCESS"
export const MAINTENANCETYPE_UPDATE_SUCCESS =  "MAINTENANCETYPE_UPDATE_SUCCESS"
export const MAINTENANCETYPE_UPDATE_FAIL =  "MAINTENANCETYPE_UPDATE_FAIL"
export const MAINTENANCETYPE_DELETE_SUCCESS =  "MAINTENANCETYPE_DELETE_SUCCESS"
export const MAINTENANCETYPE_DELETE_FAIL =  "MAINTENANCETYPE_DELETE_FAIL"
export const MAINTENANCETYPE_EDIT =  "MAINTENANCETYPE_EDIT"