import React from "react";
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import {getInventorytypes, getInventorytype, registerInventorytype, updateInventorytype, deleteInventorytype } from './../../actions/inventorytype';
import FormInventory from "views/Form/FormInventory";

import $ from "jquery";
import { alllocations } from "./../../actions/common";
import { Col, Container, Row } from "reactstrap";
import { Link, NavLink } from "react-router-dom";

const locs = alllocations;
class BottomCard extends React.Component {
   constructor(props){
       super(props);
       this.state ={
           id:null,
           cat:null,
            grp:null,
            afid:false,
            tfid:false,
            cfid:false,
            mfid:false,
            ifid:false,
            mid:null,
            fid:false,
            data:{},
            subtopic:'All Categories',
            started:'',
            ended:'',
            daterange:'',
            isshown:{}

       }
   }

    componentDidMount(){
        //GET PROPS
        let id = this.props.id;
        let choicestarted = this.props.choicestarted ? this.props.choicestarted : null;
        let choiceended = this.props.choiceended ? this.props.choiceended : null;
        let started = this.props.defaultstarted ? this.props.defaultstarted : null;
        let ended = this.props.defaultended ? this.props.defaultended : null;
        this.$el = $(this.el);
        if(choicestarted !== null && choiceended !== null && choiceended > choicestarted)
        {
            started = choicestarted;
            ended = choiceended;
        }

        let daterange = ' '+moment(started).format('MMMM Do YYYY') + ' - ' + moment(ended).format('MMMM Do YYYY');
        
        this.setState({
            id:id, 
            cat:id,
            started:started,
            ended:ended,
            daterange:daterange
        });
            
            let catname = this.props.inventorycategorys.inventorycategorys && Array.isArray(this.props.inventorycategorys.inventorycategorys) && this.props.inventorycategorys.inventorycategorys.length > 0 ? this.props.inventorycategorys.inventorycategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':'None'}
            //GET ALL CATEGORYS
            let res = {};
            let ctn = '';
            let params = {};
            if(parseInt(id) > 0){
                res = {'categoryid':id, 'is_delete':0};
                ctn = catname !== undefined ? catname.name :'None';
                params = {
                    data:JSON.stringify(res),
                    cat:'categoryinventory',
                    table:'inventory_types'
                }
            }else if(id = 'all'){
                res = {};
                ctn = 'Inventory List';
                params = {
                    data:JSON.stringify(res),
                    cat:'inventoryall',
                    table:'inventory_types'
                }
            }
            
         
         this.setState({subtopic:ctn});
         this.props.getInventorytypes(params);

         $('#myTablex').DataTable();
         
            
            

    }
     componentDidUpdate(prevProps, prevState){
        //GET PROPS
        if(prevProps.id !== this.props.id){
        let id = this.props.id;
        let choicestarted = this.props.choicestarted ? this.props.choicestarted : null;
        let choiceended = this.props.choiceended ? this.props.choiceended : null;
        let started = this.props.defaultstarted ? this.props.defaultstarted : null;
        let ended = this.props.defaultended ? this.props.defaultended : null;
        this.$el = $(this.el);
        if(choicestarted !== null && choiceended !== null && choiceended > choicestarted)
        {
            started = choicestarted;
            ended = choiceended;
        }

        let daterange = ' '+moment(started).format('MMMM Do YYYY') + ' - ' + moment(ended).format('MMMM Do YYYY');
        
        this.setState({
            id:id, 
            cat:id,
            started:started,
            ended:ended,
            daterange:daterange
        });
            
            let catname = this.props.inventorycategorys.inventorycategorys && Array.isArray(this.props.inventorycategorys.inventorycategorys) && this.props.inventorycategorys.inventorycategorys.length > 0 ? this.props.inventorycategorys.inventorycategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':'None'}
            //GET ALL CATEGORYS
            let res = {};
            let ctn = '';
            
            if(parseInt(id) > 0){
                res = {'categoryid':id, 'is_delete':0};
                ctn = catname !== undefined ? catname.name :'None'
            }
            let params = {
                data:JSON.stringify(res),
                cat:'categoryinventory',
                table:'inventory_types'
            }
         
         this.setState({subtopic:ctn});
         this.props.getInventorytypes(params);

         $('#myTablex').DataTable();
         
        }
            

    }
   
    componentWillUnmount(){
        $('#myTablex').DataTable().destroy();
    }
    //EDIT INVENTORY
    editForm = (id, dat) =>{
        this.props.getInventorytype(id);
        this.setState({cfid:true, mid:id});
    }
  
    
    //DELETE INVENTORY
    deleteForm = id =>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You will not be able restore",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
        
            if (result.value) {
                let fd = new FormData();
                fd.append('id', id);
                fd.append('is_delete', 1);
                fd.append('cat', 'update');
                fd.append('table', 'inventory_types');
                this.props.updateInventorytype(fd);
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            }
          })
        
    }
    //ACTIVATE INVENTORY
    activateForm = (id, ac) =>{
        let act = ac === 0 ? 1 : 0;
        let fd = new FormData();
        fd.append('id', id);
        fd.append('is_active', act);
        fd.append('cat', 'update');
        fd.append('table', 'inventory_types');
        this.props.updateInventorytype(fd);
    }
   
    loadShown = (id, cat) =>{
          let ar = {};
          if(cat === 0)
          {
              ar[id] = true;
          }else{
              ar[id] = false;
          }
          this.setState({isshown:ar});
    }
    render() {
        let { cfid, mid, isshown, subtopic } = this.state  || '';
        let data = this.props.inventorytypes.inventorytypes;
        let tabl = data && Array.isArray(data) ? data.map((prop, ind)=>{
            let name = prop.name;
            return <tr 
            key={ind}
                        style={{padding:'1px', margin:'15px'}}
                        onMouseEnter={()=>this.loadShown(prop.id, 0)}
                        onMouseLeave={()=>this.loadShown(prop.id, 1)}
                        >
                        <td className='text-center' width='24px' data-col-width='4' style={{maxWidth:'24px'}}>{ind + 1}</td>
                        
                        <td className='text-left'>{prop.categoryname}</td>
                        <td className='text-left' style={{textTransform:'capitalize'}}>
                             {parseInt(prop.is_active) === 0 ? name : <strike>{name}</strike>}
                            
                        </td>
                        <td>
                        <span className='reportcontrol pull-right'>
                                    <NavLink to={`/account/inventoryhistory/${prop.id}/2`}><i className='fa fa-list'></i></NavLink>
                                    <NavLink to={`/account/inventoryreport/${prop.id}/2`}><i className='fa fa-list-alt'></i></NavLink>
                                    <a onClick={()=>this.activateForm(parseInt(prop.id), parseInt(prop.is_active))} title='Block/Unblock Issue' href='#'>{<i className= {parseInt(prop.is_active) === 0 ? "fa fa-thumbs-up" : "fa fa-thumbs-down"}></i>}</a>
                                    <a onClick={()=>this.editForm(prop.id, prop)} title='Edit' href='#'><i className='fa fa-edit'></i></a>
                                    <a onClick={()=>this.deleteForm(prop.id, prop)} title='Delete' href='#'><i className='now-ui-icons ui-1_simple-remove'></i></a>
                                </span>
                        </td>
                    </tr>
                }): '';
        


        return (
            <>
            
            {cfid === true ? 
            <FormInventory
                st={cfid}
                mid={mid}
                handleClose={()=>this.setState({mid:null, cfid:false})}
            />:''}
           
              <div className="content">
                  <div className='card'>
                  <div className='card-header tablecardheader'>
                  
                  <Container xs='12'>
                          <Row xs='12'>
                              <Col md='8' sm='12'>
                              <h4 className='cardtitl'>{subtopic}</h4>
                              </Col >
                              <Col md='4' sm='12' className='pull-right'>
                              <a href='#' className='btn btn-outline-info btn-sm' onClick={()=>this.editForm(null, {})}><i className='now-ui-icons ui-1_simple-add'></i> Add Inventory Type</a>
                              <Link to='/account/inventory'><button className='btn btn-outline-primary btn-sm'><i className='fa fa-backward'></i> Back</button></Link>
                              </Col >

                          </Row>
                      </Container>
                  </div>
                  <div className='card-body table-responsive'>
                    <table ref={el=>this.el = el} className='table-bordered display wrap table-striped mytables table-condensed table-hover'  id='myTablexz' width='100%' >
                        <thead>
                            <tr>
                                <th style={{maxWidth:'10px'}}>SN.</th>
                                <th>Category</th>
                                <th>Types</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tabl}
                        </tbody>
                    </table>
                  </div>
                  </div>
              </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({ 
    user:state.userReducer.user,
    inventorycategorys:state.inventorycategoryReducer,
    inventorytypes:state.inventorytypeReducer
  })
  
export default connect(mapStateToProps, 
    { 
        getInventorytypes,
        getInventorytype,
        updateInventorytype,
        registerInventorytype,
        deleteInventorytype
    })(BottomCard)
