import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function CompleteExamples() {
  return (
    <>
      <div className="section">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col lg="8" md="12">
              <h2 className="title" style={{color:'#000000',fontFamily:'Kaushan Script', textTransform:'none'}}>Welcome to SIL HMS</h2>
              <h5 className="description" style={{color:'#000000', fontFamily:"Arial"}}>
              Welcome to your home away from home! 
              Our guest house offers comfortable accommodations 
              and a relaxing atmosphere, perfect for unwinding after a busy day. 
               Whether it's just for you or your entire team, 
               our easy-to-use booking system lets staff reserve their own stay or 
               coordinate housing for multiple colleagues with just a few clicks.
              </h5>
            </Col>
            <Col lg="8" md="12">
              <a href="#booking" className="btn btn-lg btn-info">Reserve Your Stay</a>
              <a href={process.env.PUBLIC_URL+"/guestmultiple"} className="btn btn-lg btn-info">Cordinate Team Housing</a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default CompleteExamples;
