import React from "react";
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import {getUsertransactions, getUsertransaction, registerUsertransaction, updateUsertransaction, deleteUsertransaction } from './../../actions/usertransaction';
import {getUsertype } from './../../actions/usertype';
import $ from "jquery";
import { Col, Row } from "reactstrap";

const db = {
    'update':'changed',
    'insert':'created',
    'confirm':'changed',
    'delete':'deleted',
    'login':'logged',
}

const db1 = {
    'user_categorys':{
        'update':' updated department with id',
        'insert':' created department with id ',
        'confirm':' created department with id ',
        'delete':' deleted department with id ',
        'login':' logged in ',
    },
    'user_transactions':{
        'update':' made a change with id',
        'insert':' created department with id ',
        'confirm':' created department with id ',
        'delete':' deleted department with id '
    },
    'user_types':{
        'update':' changed staff data with id ',
        'insert':' created staff record with id ',
        'confirm':' created staff record with id ',
        'delete':' deleted staff record with id '
    },
    'inventory_categorys':{
        'update':' updated inventory category with id',
        'insert':' created inventory category with id ',
        'confirm':' created inventory categorywith id ',
        'delete':' deleted inventory category with id '
    },
    'inventory_transactions':{
        'update':' Changed inventory record with id',
        'insert':' created inventory record with id ',
        'confirm':' created inventory record with id ',
        'delete':' deleted inventory record with id '
    },
    'inventory_types':{
        'update':' updated inventory category with id',
        'insert':' created inventory category with id ',
        'confirm':' created inventory categorywith id ',
        'delete':' deleted inventory category with id '
    },
    'maintenance_categorys':{
        'update':' updated maintenance category with id',
        'insert':' created maintenance category with id ',
        'confirm':' created maintenance category with id ',
        'delete':' deleted maintenance category with id '
    },
    'maintenance_transactions':{
        'update':' Changed maintenance record with id',
        'insert':' created maintenance record with id ',
        'confirm':' created maintenance record with id ',
        'delete':' deleted maintenance record with id '
    },
    'maintenance_types':{
        'update':' updated maintenance type with id',
        'insert':' created maintenance type with id ',
        'confirm':' created maintenance type ywith id ',
        'delete':' deleted maintenance type with id '
    },
    'room_categorys':{
        'update':' updated a house with id',
        'insert':' created a house with id ',
        'confirm':' created a house with id ',
        'delete':' deleted a house with id '
    },
    'room_transactions':{
        'update':' updated room record with id',
        'insert':' booked room record with id ',
        'confirm':' booked room record with id ',
        'delete':' deleted booking record with id '
    },
    'room_types':{
        'update':' updated room with id',
        'insert':' created room with id ',
        'confirm':' created room with id ',
        'delete':' deleted room with id '
    },
}


class BottomLog extends React.Component {
   constructor(props){
       super(props);
       this.state ={
           id:null,
           cat:null,
            grp:null,
            afid:false,
            tfid:false,
            cfid:false,
            mfid:false,
            ifid:false,
            mid:null,
            fid:false,
            data:{},
            subtopic:'All Categories',
            started:'',
            ended:'',
            daterange:'',
            isshown:{}

       }
   }

    componentDidMount(){
        //GET PROPS
        let id = this.props.id;
        let dts = new Date();
        let started = new Date(dts.getFullYear(), dts.getMonth(), 1);
        let ended = new Date(dts.getFullYear(), dts.getMonth() + 1, 0);
        let daterange = ' '+moment(started).format('MMMM Do YYYY') + ' - ' + moment(ended).format('MMMM Do YYYY');
        
        this.setState({
            id:id, 
            cat:id,
            started:started,
            ended:ended,
            daterange:daterange
        });
            //GET ALL CATEGORYS
            
            let ctn = '';
    
            let params = {
                data:JSON.stringify({
                    'staffid':id,
                    'starts':started,
                    'ends':ended,
                }),
                cat:'usertransaction',
                table:'user_transactions'
            }
         
         this.setState({subtopic:ctn});
         this.props.getUsertransactions(params);            

    }
    
     
   
    componentWillUnmount(){
        $('#myTablex').DataTable().destroy();
    }
    lunchDate = (func, func1, locs) =>{
        Swal.fire({
            title: 'Pick a date range:',
            type: 'question',
            html: '<input id="datepicker" type="date"  class="swal2-input"><br/><input id="datepicker1" type="date"  class="swal2-input">',
            customClass: 'swal2-overflow',
            
          }).then(function(result) {
              if(result.value)
              {
                  let v = $('#datepicker').val();
                  let v1 = $('#datepicker1').val();
                  let params = {
                    data:JSON.stringify(
                    {
                        'starts':moment(new Date(v)).format('YYYY-MM-DD'), 
                        'ends':moment(new Date(v1)).format('YYYY-MM-DD'), 
                        'staffid':locs
                    }),
                    cat:'usertransaction',
                    table:'user_transactions'
                }
                func(params);
                func1(v, v1);
              }
          });
    }
    retState = (dt, dt1) =>{
        this.setState({started:new Date(dt), ended:new Date(dt1)});
        let daterange = ' '+moment(dt).format('MMMM Do YYYY') + ' - ' + moment(dt1).format('MMMM Do YYYY');
        this.setState({subtopic:daterange});
    }
    render() {
        let { 
            id,  
            title, 
            surname, 
            firstname, 
            middlename, 

         } = this.props.usertypes.usertype || '';
        let data = this.props.usertransactions.usertransactions;
        let tabl = data && Array.isArray(data) ? data.map((prop, ind)=>{
            return <tr key={ind} style={{padding:'1px', margin:'0px'}}>
                        <td className='text-left' width='150px'>{moment(prop.date_created).format('DD-MM-YYYY hh:mm:ss')}</td>
                        <td className='text-left' width='150px'>{prop.fullname}</td>
                        <td className='text-left' style={{textTransform:'capitalize'}}>
                            {prop.tables in db1 && prop.actions in db1[prop.tables] ? db1[prop.tables][prop.actions] : ''} {prop.ids}
                            
                        </td>
                    </tr>
                }): '';
        


        return (
            <>
              <div className="content">
                  <div className='card'>
                  <div className='card-header tablecardheader'>
                  <div className='col-sm-12'>
                  <Row xs='12'>
                        <Col sm='8'>
                            <h4>{this.state.subtopic}</h4>
                        </Col>
                        <Col>
                            <a class="btn btn-outline-info btn-sm pull-right" onClick={()=>this.lunchDate(this.props.getUsertransactions, this.retState, this.state.id)} href="#">
                                        <i className="now-ui-icons ui-1_calendar-60"></i>
                            </a>
                        </Col>
                      </Row>
                      
                      </div>
                    
                  </div>
                  <div className='card-body table-responsive'>
                    <table ref={el=>this.el = el} className='table table-bordered display wrap table-striped mytables table-condensed table-hover'  id='myTable' width='100%' >
                        <thead>
                            <tr>
                                <th style={{maxWidth:'20px'}}>DATE</th>
                                <th>STAFF NAME</th>
                                <th>ACTIVITY</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tabl}
                        </tbody>
                    </table>
                  </div>
                  </div>
              </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({ 
    user:state.userReducer.user,
    usercategorys:state.usercategoryReducer,
    usertransactions:state.usertransactionReducer,
    usertypes:state.usertypeReducer
  })
  
export default connect(mapStateToProps, 
    { 
        getUsertransactions,
        getUsertransaction,
        updateUsertransaction,
        registerUsertransaction,
        deleteUsertransaction,
        getUsertype
    })(BottomLog)
