export const ROOMMAINTENANCE_GET_MULTIPLE =  "ROOMMAINTENANCE_GET_MULTIPLE"
export const ROOMMAINTENANCE_GET_ONE =  "ROOMMAINTENANCE_GET_ONE"
export const ROOMMAINTENANCE_REGISTER_SUCCESS =  "ROOMMAINTENANCE_REGISTER_SUCCESS"
export const ROOMMAINTENANCE_REGISTER_FAIL =  "ROOMMAINTENANCE_REGISTER_FAIL"
export const ROOMMAINTENANCE_LOADING =  "ROOMMAINTENANCE_LOADING"
export const ROOMMAINTENANCE_LOADING_ERROR =  "ROOMMAINTENANCE_LOADING_ERROR"
export const ROOMMAINTENANCE_ACTIVATE_FAIL =  "ROOMMAINTENANCE_ACTIVATE_FAIL"
export const ROOMMAINTENANCE_ACTIVATE_SUCCESS =  "ROOMMAINTENANCE_ACTIVATE_SUCCESS"
export const ROOMMAINTENANCE_UPDATE_SUCCESS =  "ROOMMAINTENANCE_UPDATE_SUCCESS"
export const ROOMMAINTENANCE_UPDATE_FAIL =  "ROOMMAINTENANCE_UPDATE_FAIL"
export const ROOMMAINTENANCE_DELETE_SUCCESS =  "ROOMMAINTENANCE_DELETE_SUCCESS"
export const ROOMMAINTENANCE_DELETE_FAIL =  "ROOMMAINTENANCE_DELETE_FAIL"
export const ROOMMAINTENANCE_EDIT =  "ROOMMAINTENANCE_EDIT"