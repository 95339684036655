export const BOOKING_GET_MULTIPLE =  "BOOKING_GET_MULTIPLE"
export const BOOKING_GET_ONE =  "BOOKING_GET_ONE"
export const BOOKING_REGISTER_SUCCESS =  "BOOKING_REGISTER_SUCCESS"
export const BOOKING_REGISTER_FAIL =  "BOOKING_REGISTER_FAIL"
export const BOOKING_LOADING =  "BOOKING_LOADING"
export const BOOKING_LOADING_ERROR =  "BOOKING_LOADING_ERROR"
export const BOOKING_ACTIVATE_FAIL =  "BOOKING_ACTIVATE_FAIL"
export const BOOKING_ACTIVATE_SUCCESS =  "BOOKING_ACTIVATE_SUCCESS"
export const BOOKING_UPDATE_SUCCESS =  "BOOKING_UPDATE_SUCCESS"
export const BOOKING_UPDATE_FAIL =  "BOOKING_UPDATE_FAIL"
export const BOOKING_DELETE_SUCCESS =  "BOOKING_DELETE_SUCCESS"
export const BOOKING_DELETE_FAIL =  "BOOKING_DELETE_FAIL"
export const BOOKING_EDIT =  "BOOKING_EDIT"