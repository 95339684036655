import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import { deleteMaintenancetransaction, registerMaintenancetransaction, updateMaintenancetransaction, getMaintenancetransactions, getMaintenancetransaction } from '../../actions/maintenancetransaction';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Container } from 'reactstrap';
import { statussobj } from 'actions/common';

const statuss = statussobj


const Modals = (props) => {
  
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState({});
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [data, setData] = useState({});
  const [isshown, setIsshown] = useState({});

 //const componentRef = useRef('')
  const resetdata= async() =>{
    props.handleClose();
}

  const toggle = () => setModal(!modal);
  useEffect(() => {
    setModal(props.st);
    setTitle(props.title);
    setStart(props.start);
    setEnd(props.end);
    setData(props.maintenancetransactions);
  },[props.st, props.maintenancetransactions])


const loadShown = (id, cat) =>{
    let ar = {};
    if(cat === 0)
    {
        ar[id] = true;
    }else{
        ar[id] = false;
    }
    setIsshown(ar);
}
const loadComplete=(id) =>{

}
const editRow =(id, data)=>{
   props.getMaintenancetransaction(id);
   props.editMaintenance(id, data);
}
const deleteRow =(id, data)=>{
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this! You lose all records",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          props.deleteMaintenancetransaction({'id':id});
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }
      })
}

const print=()=> {
  var content = document.getElementById('mainbody');
  var pri = document.getElementById('ifmcontentstoprint').contentWindow;
  pri.document.open();
  pri.document.write(content.innerHTML);
  pri.document.close();
  pri.focus();
  pri.print();
}   

 let tabl = data && Array.isArray(data) && data.length > 0 ? data.map((prop, ind)=>{
     if(prop !== null){
     let st = statuss.filter(rw=>rw.value == prop.status)[0];
     
     return <tr 
                id={ind} 
                style={{padding:'1px', margin:'0px'}}
                onMouseEnter={()=>loadShown(prop.id, 0)}
                onMouseLeave={()=>loadShown(prop.id, 1)}
                >
                
                <td style={{padding:'1px', margin:'0px'}}>{moment(new Date(prop.transaction_date)).format("DD-MM-YYYY hh:mm:ss")}</td>
                <td style={{padding:'1px', margin:'0px'}}>
                { prop.id in isshown && isshown[prop.id] === true ? 
                    <span className='reportcontrol pull-left'>
                        <a onClick={()=>editRow(prop.id, prop)} href='#'><i className='fa fa-edit'></i></a>
                        <a onClick={()=>deleteRow(prop.id, prop)} href='#'><i className='fa fa-trash'></i></a>
                    </span>
                : ''}{prop.maintenancename}
                </td>
                
                <td style={{padding:'1px', margin:'0px'}}><a onClick={()=>loadComplete(prop.id)}><span className={prop.is_completed == 1 ? 'badge badge-success': 'badge badge-danger'}>{st.label}</span></a></td>
                <td style={{padding:'1px', margin:'0px'}}>{moment(new Date(prop.transaction_date)).fromNow()}</td>
                <td style={{padding:'1px', margin:'0px'}}>{prop.username}</td>
            </tr>
 }}):''
  return (
    <div>
       <iframe id="ifmcontentstoprint" style={{
                        height: '0px',
                        width: '0px',
                        position: 'absolute'
                    }}></iframe> 
      <Modal 
      id='mainbody'  
      isOpen={modal} 
      size='lg' 
      toggle={toggle} backdrop='static' keyboard={false}>
        <ModalHeader toggle={resetdata}><i className='fa fa-wrench'></i> {title}</ModalHeader>
        <ModalBody >
          <h6><small><i className='fa fa-calendar'></i> {moment(start).format('DD MMMM YYYY')}{' TO '}{moment(end).format('DD MMMM YYYY')}</small></h6>
          <Container>
        <table width='100%' className=" table table-bordered table-striped" id="myTable">
            <thead>
                <tr style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>REQUEST DATE</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}} width='250px'>MAINTENANCE ISSUES</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>PIORITY</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}}>RES.<b/> TIME</th>
                    <th style={{fontSize:'0.9em', fontFamily:'Tahoma', fontWeight:'bolder', lineHeight:'110%'}} width='70px'>BY</th>
                </tr>
            </thead>
            <tbody style={{fontSize:'0.8em', lineHeight:'100%', fontFamily: 'Tahoma', padding:'1px'}}>
            {tabl}
            </tbody>
        </table>
        </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={resetdata}>Cancel</Button>
          <Button color="info" onClick={print}>Print</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
const mapStateToProps = (state, ownProps) => ({ 
    user:state.userReducer.user,
    maintenancecategorys:state.maintenancecategoryReducer,
    maintenancetypes:state.maintenancetypeReducer,
    maintenancetransactions:state.maintenancetransactionReducer.maintenancetransactions
  })
  
export default connect(mapStateToProps, { 
   deleteMaintenancetransaction, registerMaintenancetransaction, updateMaintenancetransaction, getMaintenancetransactions, getMaintenancetransaction })(Modals)
