import React from "react";
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import {getMaintenancetransactionsummary,getMaintenancetransactions, getMaintenancetransaction, registerMaintenancetransaction, updateMaintenancetransaction, deleteMaintenancetransaction } from './../../actions/maintenancetransaction';


import $ from "jquery";
import { alllocations,  } from "./../../actions/common";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import FormMaintenanceTransaction from "views/Form/FormMaintenanceTransaction";

const locs = alllocations;
let inventors = {
    1:'Supply',
    2:'Dispatched',
    3:'Deployed',
    4:'Damaged',
}
class BottomCard extends React.Component {
   constructor(props){
       super(props);
       this.state ={
           id:null,
           cat:null,
            grp:null,
            afid:false,
            tfid:false,
            cfid:false,
            mfid:false,
            ifid:false,
            mid:null,
            fid:false,
            data:{},
            subtopic:'All Categories',
            started:'',
            ended:'',
            daterange:'',
            isshown:{}

       }
   }

    componentDidMount(){
        //GET PROPS
        let id = this.props.id;
        let cat = this.props.cat;
        let dt = new Date();
	    let started  = new Date(dt.getFullYear(), dt.getMonth(), 1);
        let ended = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
        let daterange = ' '+moment(started).format('MMMM Do YYYY') + ' - ' + moment(ended).format('MMMM Do YYYY');
        
	    this.setState({
             id:id,
             cat:cat,
	         started: moment(started).format("YYYY-MM-DD"),
             ended: moment(ended).format("YYYY-MM-DD"),
             daterange:daterange
	    })
            
        
        //GET ALL CATEGORYS
        let res = {};
        let ctn = '';
        let params = {};
        if(parseInt(id) > 0)
        {
            if(parseInt(cat) === 1)
            {
            res = {
                    'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                    'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                    'locationid':this.props.user.location,
                    'maintenanceid':id
                };
            let catname = this.props.maintenancetypes.maintenancetypes && Array.isArray(this.props.maintenancetypes.maintenancetypes) && this.props.maintenancetypes.maintenancetypes.length > 0 ? this.props.maintenancecategorys.maintenancecategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''}
            ctn = catname !== undefined ? catname.name :'None';
                 params = {
                    data:JSON.stringify(res),
                    cat:'maintenancetransactionsummary',
                    table:'maintenance_transactions'
                 }
            }
            else{
                let aatname = this.props.maintenancetypes.maintenancetypes && Array.isArray(this.props.maintenancetypes.maintenancetypes) && this.props.maintenancetypes.maintenancetypes.length > 0 ? this.props.maintenancecategorys.maintenancecategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''}
                let atnid = aatname !== undefined ? aatname.categoryid :null;
                let atnm = aatname !== undefined ? aatname.name :null;
                let catname = atnid !== null && parseInt(atnid) > 0 && this.props.maintenancecategorys.maintenancecategorys && Array.isArray(this.props.maintenancecategorys.maintenancecategorys) && this.props.maintenancecategorys.maintenancecategorys.length > 0 ? this.props.maintenancecategorys.maintenancecategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''} 
                let ctnid = catname !== undefined ? catname.name :null;
                ctn = ctnid +' '+atnm;
                res = {
                    'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                    'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                    'locationid':this.props.user.location,
                    'maintenanceid':id
                };
                
                params = {
                    data:JSON.stringify(res),
                    cat:'maintenancetransactionsummary',
                    table:'maintenance_transactions'
                    }
            }
        }else if(id = 'all'){
            res = {
                    'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                    'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                    'locationid':this.props.user.location
                };
            ctn = 'Maintenance Transaction Summary';
            params = {
                data:JSON.stringify(res),
                cat:'maintenancetransactionsummary',
                table:'maintenance_transactions'
            }
        }
        this.setState({subtopic:ctn});
        this.props.getMaintenancetransactionsummary(params);
        $('#myTablex').DataTable().destroy();
        $('#myTablex').DataTable();
    }
    componentDidUpdate(prevProps, prevState){
        //GET PROPS
        if(prevProps.id !== this.props.id || prevProps.user.location !== this.props.user.location || prevState.starts !== this.state.starts || prevState.ends !== this.state.ends)
        {
         //GET PROPS
         let id = this.props.id;
         let cat = this.props.cat;
         let dt = new Date();
         let started  = new Date(this.state.started);
         let ended = new Date(this.state.ended);
         let daterange = ' '+moment(started).format('MMMM Do YYYY') + ' - ' + moment(ended).format('MMMM Do YYYY');
         
         this.setState({
              id:id,
              cat:cat,
              started: moment(started).format("YYYY-MM-DD"),
              ended: moment(ended).format("YYYY-MM-DD"),
              daterange:daterange
         })
             
         
         //GET ALL CATEGORYS
         let res = {};
         let ctn = '';
         let params = {};
         if(parseInt(id) > 0)
         {
             if(parseInt(cat) === 1)
             {
             res = {
                     'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                     'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                     'locationid':this.props.user.location,
                     'maintenanceid':id
                 };
             let catname = this.props.maintenancetypes.maintenancetypes && Array.isArray(this.props.maintenancetypes.maintenancetypes) && this.props.maintenancetypes.maintenancetypes.length > 0 ? this.props.maintenancecategorys.maintenancecategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''}
             ctn = catname !== undefined ? catname.name :'None';
                  params = {
                     data:JSON.stringify(res),
                     cat:'maintenancetransactionsummary',
                     table:'maintenance_transactions'
                  }
             }
             else{
                 let aatname = this.props.maintenancetypes.maintenancetypes && Array.isArray(this.props.maintenancetypes.maintenancetypes) && this.props.maintenancetypes.maintenancetypes.length > 0 ? this.props.maintenancecategorys.maintenancecategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''}
                 let atnid = aatname !== undefined ? aatname.categoryid :null;
                 let atnm = aatname !== undefined ? aatname.name :null;
                 let catname = atnid !== null && parseInt(atnid) > 0 && this.props.maintenancecategorys.maintenancecategorys && Array.isArray(this.props.maintenancecategorys.maintenancecategorys) && this.props.maintenancecategorys.maintenancecategorys.length > 0 ? this.props.maintenancecategorys.maintenancecategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''} 
                 let ctnid = catname !== undefined ? catname.name :null;
                 ctn = ctnid +' '+atnm;
                 res = {
                     'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                     'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                     'locationid':this.props.user.location,
                     'maintenanceid':id
                 };
                 
                 params = {
                     data:JSON.stringify(res),
                     cat:'maintenancetransactionsummary',
                     table:'maintenance_transactions'
                     }
             }
         }else if(id = 'all'){
             res = {
                     'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                     'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                     'locationid':this.props.user.location
                 };
             ctn = 'Maintenance Transaction Summary';
             params = {
                 data:JSON.stringify(res),
                 cat:'maintenancetransactionsummary',
                 table:'maintenance_transactions'
             }
         }
         this.setState({subtopic:ctn});
         this.props.getMaintenancetransactionsummary(params);
         $('#myTablex').DataTable();
         
        }
            

    }
    componentWillUnmount(){
        $('#myTablex').DataTable().destroy();
    }
    retState = (dt, dt1) =>{
        this.setState({starts:new Date(dt), ends:new Date(dt1)});
    }
    //EDIT MAINTENANCE
    editForm = (id, dat) =>{
        this.props.getMaintenancetransaction(id);
        this.setState({cfid:true, mid:id});
    }
    //DELETE MAINTENANCE
    deleteForm = id =>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You will not be able restore",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
        
            if (result.value) {
                let fd = new FormData();
                fd.append('id', id);
                fd.append('is_delete', 1);
                fd.append('cat', 'update');
                fd.append('table', 'maintenance_transactions');
                this.props.updateMaintenancetransaction(fd);
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            }
          })
        
    }
    //ACTIVATE MAINTENANCE
    activateForm = (id, ac) =>{
        let act = ac === 0 ? 1 : 0;
        let fd = new FormData();
        fd.append('id', id);
        fd.append('is_active', act);
        fd.append('cat', 'update');
        fd.append('table', 'maintenance_transactions');
        this.props.updateMaintenancetransaction(fd);
    }
   
   
    loadShown = (id, cat) =>{
          let ar = {};
          if(cat === 0)
          {
              ar[id] = true;
          }else{
              ar[id] = false;
          }
          this.setState({isshown:ar});
    }
    lunchDate = (func, func1, locs) =>{
        Swal.fire({
            title: 'Pick a date from and to:',
            type: 'question',
            html: '<input id="datepicker" type="date"  class="swal2-input"><br/><input id="datepicker1" type="date"  class="swal2-input">',
            customClass: 'swal2-overflow',
            
          }).then(function(result) {
              if(result.value)
              {
                  let v = $('#datepicker').val();
                  let v1 = $('#datepicker1').val();
                  let params = {
                    data:JSON.stringify(
                    {
                        'starts':moment(new Date(v)).format('YYYY-MM-DD'), 
                        'ends':moment(new Date(v1)).format('YYYY-MM-DD'), 
                        'locationid':locs
                    }),
                    cat:'maintenancetransactionsummary',
                    table:'maintenance_transaction'
                }
                func(params);
                func1(v, v1);
              }
          });
    }

    render() {
        let { cfid, mid, subtopic, daterange } = this.state  || '';
        let data = this.props.maintenancetransactions.maintenancetransactionsummary;
        let ar = {};
        if(data && Array.isArray(data) ){
        data.filter(rw=>rw !== null).forEach(element => {
            if(element.maintenanceid !== null && element.maintenanceid !== undefined && parseInt(element.maintenanceid) > 0){
            if(element.maintenanceid in Object.keys(ar))
            {
                ar[element.maintenanceid][element.status] = parseFloat(element.qty) > 0 ? parseFloat(element.qty) : parseFloat(element.qty)  * -1 ;;
            }else
            {
                ar[element.maintenanceid] = {};
                ar[element.maintenanceid]['id'] = element.maintenanceid; 
                ar[element.maintenanceid]['maintenancename'] = element.maintenancename; 
                ar[element.maintenanceid][element.status] = parseFloat(element.qty) > 0 ? parseFloat(element.qty) : parseFloat(element.qty)  * -1 ; 
            }
        }
        });
    }
        
        let tabl = ar && Array.isArray(Object.keys(ar)) ? Object.keys(ar).map((prp, ind)=>{
            let prop = ar[prp];
            let sum = 
            (Number(prop[1])  ? Number(prop[1]) : 0)  - (Number(prop[2])  ? Number(prop[2]) : 0)  -  (Number(prop[3])  ? Number(prop[3]) : 0 )  -  (Number(prop[4])  ? Number(prop[4]) : 0 );
            return <tr>
                        <td className='text-center' width='70px'>{ind + 1}</td>
                        <td className='text-left'>{prop.maintenancename}</td>
                        <td className='text-center'>{1 in prop ? prop[1] : '-'}</td>
                        <td className='text-center'>{2 in prop ? prop[2] : '-'}</td>
                        <td className='text-center'>{3 in prop ? prop[3] : '-'}</td>
                        <td className='text-center'>{4 in prop ? prop[4] : '-'}</td>
                        <td className='text-center'>{sum}</td>
                    </tr>
                }): '';
        


        return (
            <>
            
            {cfid === true ? 
            <FormMaintenanceTransaction
                st={cfid}
                mid={mid}
                handleClose={()=>this.setState({mid:null, cfid:false})}
            />:''}
           
              <div className="content">
                  <div className='card'>
                  <div className='card-header tablecardheader'>
                  
                  <Container xs='12'>
                          <Row xs='12'>
                              <Col md='6' sm='12'>
                              <h4 className='cardtitl'>{subtopic}</h4>
                              <span>{daterange}</span>
                              </Col >
                              <Col md='6' sm='12' className='pull-right'>
                              <a href='#' className='btn btn-outline-info btn-sm' onClick={()=>this.editForm(null, {})}><i className='now-ui-icons ui-1_simple-add'></i> Maintenance Request Form</a>
                              <Link to={`/account/maintenances/${this.state.id}`}><button className='btn btn-outline-primary btn-sm'><i className='fa fa-backward'></i> Back</button></Link>
                              <a class="btn btn-outline-info btn-sm" onClick={()=>this.lunchDate(this.props.getMaintenancetransactions, this.retState, this.props.user.location)} href="#">
                                <i className="now-ui-icons ui-1_calendar-60"></i></a>
                              </Col >

                          </Row>
                      </Container>
                  </div>
                  <div className='card-body table-responsive'>
                    <table ref={el=>this.el = el} className='table-bordered display wrap table-striped mytables table-condensed table-hover'  id='myTablex' width='100%' >
                        <thead>
                            <tr>
                                <th>SN.</th>
                                <th>ITEM</th>
                                <th width='60px'>SUPPLIED</th>
                                <th width='60px'>CONSUMED</th>
                                <th width='60px'>DEPLOYED</th>
                                <th width='60px'><small>DAMAGED /<br/> RETIRED</small></th>
                                <th width='60px'>TOTAL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tabl}
                        </tbody>
                    </table>
                  </div>
                  </div>
              </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({ 
    user:state.userReducer,
    maintenancecategorys:state.maintenancecategoryReducer,
    maintenancetypes:state.maintenancetypeReducer,
    maintenancetransactions:state.maintenancetransactionReducer
  })
  
export default connect(mapStateToProps, 
    { 
        getMaintenancetransactionsummary,
        getMaintenancetransactions,
        getMaintenancetransaction,
        updateMaintenancetransaction,
        registerMaintenancetransaction,
        deleteMaintenancetransaction
    })(BottomCard)
