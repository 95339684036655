export const USERTRANSACTION_GET_MULTIPLE =  "USERTRANSACTION_GET_MULTIPLE"
export const USERTRANSACTION_GET_ONE =  "USERTRANSACTION_GET_ONE"
export const USERTRANSACTION_REGISTER_SUCCESS =  "USERTRANSACTION_REGISTER_SUCCESS"
export const USERTRANSACTION_REGISTER_FAIL =  "USERTRANSACTION_REGISTER_FAIL"
export const USERTRANSACTION_LOADING =  "USERTRANSACTION_LOADING"
export const USERTRANSACTION_LOADING_ERROR =  "USERTRANSACTION_LOADING_ERROR"
export const USERTRANSACTION_ACTIVATE_FAIL =  "USERTRANSACTION_ACTIVATE_FAIL"
export const USERTRANSACTION_ACTIVATE_SUCCESS =  "USERTRANSACTION_ACTIVATE_SUCCESS"
export const USERTRANSACTION_UPDATE_SUCCESS =  "USERTRANSACTION_UPDATE_SUCCESS"
export const USERTRANSACTION_UPDATE_FAIL =  "USERTRANSACTION_UPDATE_FAIL"
export const USERTRANSACTION_DELETE_SUCCESS =  "USERTRANSACTION_DELETE_SUCCESS"
export const USERTRANSACTION_DELETE_FAIL =  "USERTRANSACTION_DELETE_FAIL"
export const USERTRANSACTION_EDIT =  "USERTRANSACTION_EDIT"