export const USER_GET_MULTIPLE =  "USER_GET_MULTIPLE"
export const USER_GET_ONE =  "USER_GET_ONE"
export const USER_CHANGE_ONE =  "USER_CHANGE_ONE"
export const USER_REGISTER_SUCCESS =  "USER_REGISTER_SUCCESS"
export const USER_REGISTER_FAIL =  "USER_REGISTER_FAIL"
export const USER_LOADING =  "USER_LOADING"
export const USER_LOADING_ERROR =  "USER_LOADING_ERROR"
export const USER_ACTIVATE_FAIL =  "USER_ACTIVATE_FAIL"
export const USER_ACTIVATE_SUCCESS =  "USER_ACTIVATE_SUCCESS"
export const USER_UPDATE_SUCCESS =  "USER_UPDATE_SUCCESS"
export const USER_UPDATE_FAIL =  "USER_UPDATE_FAIL"
export const USER_DELETE_SUCCESS =  "USER_DELETE_SUCCESS"
export const USER_DELETE_FAIL =  "USER_DELETE_FAIL"
export const USER_EDIT =  "USER_EDIT"
export const USER_LOGOUT_SUCCESS ="USER_LOGOUT_SUCCESS"
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL"
export const USER_LOGIN = "USER_LOGIN"
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR"