import React from "react";
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import {getRoomtransactionsummary,getRoomtransactions, getRoomtransaction, registerRoomtransaction, updateRoomtransaction, deleteRoomtransaction } from './../../actions/roomtransaction';


import $ from "jquery";
import { alllocations,  } from "./../../actions/common";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import FormRoomTransaction from "views/Form/FormRoomTransaction";

const locs = alllocations;
let inventors = {
    1:'Supply',
    2:'Dispatched',
    3:'Deployed',
    4:'Damaged',
}
class BottomCard extends React.Component {
   constructor(props){
       super(props);
       this.state ={
           id:null,
           cat:null,
            grp:null,
            afid:false,
            tfid:false,
            cfid:false,
            mfid:false,
            ifid:false,
            mid:null,
            fid:false,
            data:{},
            subtopic:'All Categories',
            started:'',
            ended:'',
            daterange:'',
            isshown:{},
            days:0

       }
   }

    componentDidMount(){
        //GET PROPS
        let id = this.props.id;
        let cat = this.props.cat;
        let dt = new Date();
	    let started  = new Date(dt.getFullYear(), dt.getMonth(), 1);
        let ended = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
        let daterange = ' '+moment(started).format('MMMM Do YYYY') + ' - ' + moment(ended).format('MMMM Do YYYY');
        
	    this.setState({
             id:id,
             cat:cat,
	         started: moment(started).format("YYYY-MM-DD"),
             ended: moment(ended).format("YYYY-MM-DD"),
             daterange:daterange
	    })
            
        
        //GET ALL CATEGORYS
        let res = {};
        let ctn = '';
        let params = {};
        if(parseInt(id) > 0)
        {
            if(parseInt(cat) === 1)
            {
            res = {
                    'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                    'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                    'locationid':this.props.user.location,
                    'roomid':id
                };
            let catname = this.props.roomtypes.roomtypes && Array.isArray(this.props.roomtypes.roomtypes) && this.props.roomtypes.roomtypes.length > 0 ? this.props.roomcategorys.roomcategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''}
            ctn = catname !== undefined ? catname.name :'None';
                 params = {
                    data:JSON.stringify(res),
                    cat:'roomtransactionsummary',
                    table:'room_transactions'
                 }
            }
            else{
                let aatname = this.props.roomtypes.roomtypes && Array.isArray(this.props.roomtypes.roomtypes) && this.props.roomtypes.roomtypes.length > 0 ? this.props.roomcategorys.roomcategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''}
                let atnid = aatname !== undefined ? aatname.categoryid :null;
                let atnm = aatname !== undefined ? aatname.name :null;
                let catname = atnid !== null && parseInt(atnid) > 0 && this.props.roomcategorys.roomcategorys && Array.isArray(this.props.roomcategorys.roomcategorys) && this.props.roomcategorys.roomcategorys.length > 0 ? this.props.roomcategorys.roomcategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''} 
                let ctnid = catname !== undefined ? catname.name :null;
                ctn = ctnid +' '+atnm;
                res = {
                    'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                    'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                    'locationid':this.props.user.location,
                    'roomid':id
                };
                
                params = {
                    data:JSON.stringify(res),
                    cat:'roomtransactionsummary',
                    table:'room_transactions'
                    }
            }
        }else if(id = 'all'){
            res = {
                    'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                    'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                    'locationid':this.props.user.location
                };
            ctn = 'Room Transaction Summary';
            params = {
                data:JSON.stringify(res),
                cat:'roomtransactionsummary',
                table:'room_transactions'
            }
        }
        let date1 = new Date(started);
        let date2 = new Date(ended);
        let difftime = Math.abs(date2 - date1);
        let diffdays = Math.ceil(difftime/(1000 * 60 * 60 * 24)) + 1;
        this.setState({subtopic:ctn, days:diffdays});
        this.props.getRoomtransactionsummary(params);
        $('#myTablex').DataTable().destroy();
        $('#myTablex').DataTable();
    }
    componentDidUpdate(prevProps, prevState){
        //GET PROPS
        if(prevProps.id !== this.props.id || prevProps.user.location !== this.props.user.location || prevState.starts !== this.state.starts || prevState.ends !== this.state.ends)
        {
         //GET PROPS
         let id = this.props.id;
         let cat = this.props.cat;
         let dt = new Date();
         let started  = new Date(this.state.started);
         let ended = new Date(this.state.ended);
         let daterange = ' '+moment(started).format('MMMM Do YYYY') + ' - ' + moment(ended).format('MMMM Do YYYY');
         
         this.setState({
              id:id,
              cat:cat,
              started: moment(started).format("YYYY-MM-DD"),
              ended: moment(ended).format("YYYY-MM-DD"),
              daterange:daterange
         })
             
         let date1 = new Date(started);
        let date2 = new Date(ended);
        let difftime = Math.abs(date2 - date1);
        let diffdays = Math.ceil(difftime/(1000 * 60 * 60 * 24))
        
         //GET ALL CATEGORYS
         let res = {};
         let ctn = '';
         let params = {};
         if(parseInt(id) > 0)
         {
             if(parseInt(cat) === 1)
             {
             res = {
                     'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                     'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                     'locationid':this.props.user.location,
                     'roomid':id
                 };
             let catname = this.props.roomtypes.roomtypes && Array.isArray(this.props.roomtypes.roomtypes) && this.props.roomtypes.roomtypes.length > 0 ? this.props.roomcategorys.roomcategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''}
             ctn = catname !== undefined ? catname.name :'None';
                  params = {
                     data:JSON.stringify(res),
                     cat:'roomtransactionsummary',
                     table:'room_transactions'
                  }
             }
             else{
                 let aatname = this.props.roomtypes.roomtypes && Array.isArray(this.props.roomtypes.roomtypes) && this.props.roomtypes.roomtypes.length > 0 ? this.props.roomcategorys.roomcategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''}
                 let atnid = aatname !== undefined ? aatname.categoryid :null;
                 let atnm = aatname !== undefined ? aatname.name :null;
                 let catname = atnid !== null && parseInt(atnid) > 0 && this.props.roomcategorys.roomcategorys && Array.isArray(this.props.roomcategorys.roomcategorys) && this.props.roomcategorys.roomcategorys.length > 0 ? this.props.roomcategorys.roomcategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':''} 
                 let ctnid = catname !== undefined ? catname.name :null;
                 ctn = ctnid +' '+atnm;
                 res = {
                     'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                     'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                     'locationid':this.props.user.location,
                     'roomid':id
                 };
                 
                 params = {
                     data:JSON.stringify(res),
                     cat:'roomtransactionsummary',
                     table:'room_transactions'
                     }
             }
         }else if(id = 'all'){
             res = {
                     'starts':moment(new Date(started)).format('YYYY-MM-DD'),
                     'ends':moment(new Date(ended)).format('YYYY-MM-DD'),
                     'locationid':this.props.user.location
                 };
             ctn = 'Room Transaction Summary';
             params = {
                 data:JSON.stringify(res),
                 cat:'roomtransactionsummary',
                 table:'room_transactions'
             }
         }
         this.setState({subtopic:ctn, days:diffdays});
         this.props.getRoomtransactionsummary(params);
         $('#myTablex').DataTable();
         
        }
            

    }
    componentWillUnmount(){
        $('#myTablex').DataTable().destroy();
    }
    retState = (dt, dt1) =>{
        let date1 = new Date(dt);
        let date2 = new Date(dt1);
        let difftime = Math.abs(date2 - date1);
        let diffdays = Math.ceil(difftime/(1000 * 60 * 60 * 24)) + 1;
        let dr = ' '+moment(dt).format('MMMM Do YYYY') + ' - ' + moment(dt1).format('MMMM Do YYYY');
        
        this.setState({started:new Date(dt), ended:new Date(dt1), daterange:dr, days:diffdays});
    }
    //EDIT ROOM
    editForm = (id, dat) =>{
        this.props.getRoomtransaction(id);
        this.setState({cfid:true, mid:id});
    }
    //DELETE ROOM
    deleteForm = id =>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You will not be able restore",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
        
            if (result.value) {
                let fd = new FormData();
                fd.append('id', id);
                fd.append('is_delete', 1);
                fd.append('cat', 'update');
                fd.append('table', 'room_transactions');
                this.props.updateRoomtransaction(fd);
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            }
          })
        
    }
    //ACTIVATE ROOM
    activateForm = (id, ac) =>{
        let act = ac === 0 ? 1 : 0;
        let fd = new FormData();
        fd.append('id', id);
        fd.append('is_active', act);
        fd.append('cat', 'update');
        fd.append('table', 'room_transactions');
        this.props.updateRoomtransaction(fd);
    }
   
   
    loadShown = (id, cat) =>{
          let ar = {};
          if(cat === 0)
          {
              ar[id] = true;
          }else{
              ar[id] = false;
          }
          this.setState({isshown:ar});
    }
    lunchDate = (func, func1, locs) =>{
        Swal.fire({
            title: 'Pick a date from and to:',
            type: 'question',
            html: '<input id="datepicker" type="date"  class="swal2-input"><br/><input id="datepicker1" type="date"  class="swal2-input">',
            customClass: 'swal2-overflow',
            
          }).then(function(result) {
              if(result.value)
              {
                  let v = $('#datepicker').val();
                  let v1 = $('#datepicker1').val();
                  let params = {
                    data:JSON.stringify(
                    {
                        'starts':moment(new Date(v)).format('YYYY-MM-DD'), 
                        'ends':moment(new Date(v1)).format('YYYY-MM-DD'), 
                        'locationid':locs
                    }),
                    cat:'roomtransactionsummary',
                    table:'room_transaction'
                }
                func(params);
                func1(v, v1);
              }
          });
    }

    render() {
        let { cfid, mid, daterange } = this.state  || '';
        let ar = this.props.roomtransactions.roomtransactionsummary;
        let tabl = ar && Array.isArray(ar) ? ar.map((prop, ind)=>{
            let occ = this.state.days > 0 ? (prop.qty/this.state.days) * 100 : 0;
            let lod = prop.lodged.split(','); 
            let lode = lod.filter(rw=>parseInt(rw) === 1).length;
            let occ1 = lode > 0 && occ > 0 ? (lode/this.state.days) * 100 : 0;
            let ress = Math.ceil(parseFloat(prop.duration)/(1000 * 60 * 60 * 24))
            return <tr>
                        <td className='text-center' width='70px'>{ind + 1}</td>
        <td className='text-left'>{prop.categoryname}{' | '}{prop.roomname}</td>
                        <td className='text-center'>{Number(occ).toFixed(1)}%</td>
                        <td className='text-center'>{Number(occ1).toFixed(1)}%</td>
                        <td className='text-center'>{Number(ress).toFixed(0)}</td>
                    </tr>
                }): '';
        


        return (
            <>
            
            {cfid === true ? 
            <FormRoomTransaction
                st={cfid}
                mid={mid}
                handleClose={()=>this.setState({mid:null, cfid:false})}
            />:''}
           
              <div className="content">
                  <div className='card'>
                  <div className='card-header tablecardheader'>
                  
                  <Container xs='12'>
                          <Row xs='12'>
                              <Col md='12' sm='12'>
                              <h4 className='cardtitl'><i className='fa fa-bed'></i> Occupancy Rate</h4>
                              <span><a title='Change date range' class="btn btn-outline-info btn-sm" onClick={()=>this.lunchDate(this.props.getRoomtransactionsummary, this.retState, this.props.user.location)} href="#">
                                <i className="now-ui-icons ui-1_calendar-60"></i></a> {daterange}</span>
                              </Col >
                          </Row>
                      </Container>
                  </div>
                  <div className='card-body table-responsive'>
                    <table ref={el=>this.el = el} className='table-bordered display wrap table-striped mytables table-condensed table-hover'  id='myTablez' width='100%' >
                        <thead>
                            <tr>
                                <th>SN.</th>
                                <th>HOUSE | ROOM</th>
                                <th>BOOKING</th>
                                <th>OCCUPANCY</th>
                                <th>AVG. LEAD TIME (DAYS)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tabl}
                        </tbody>
                    </table>
                  </div>
                  </div>
              </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({ 
    user:state.userReducer,
    roomcategorys:state.roomcategoryReducer,
    roomtypes:state.roomtypeReducer,
    roomtransactions:state.roomtransactionReducer
  })
  
export default connect(mapStateToProps, 
    { 
        getRoomtransactionsummary,
        getRoomtransactions,
        getRoomtransaction,
        updateRoomtransaction,
        registerRoomtransaction,
        deleteRoomtransaction
    })(BottomCard)
