export const CLIENT_GET_MULTIPLE =  "CLIENT_GET_MULTIPLE"
export const CLIENT_GET_ONE =  "CLIENT_GET_ONE"
export const CLIENT_REGISTER_SUCCESS =  "CLIENT_REGISTER_SUCCESS"
export const CLIENT_REGISTER_FAIL =  "CLIENT_REGISTER_FAIL"
export const CLIENT_LOADING =  "CLIENT_LOADING"
export const CLIENT_LOADING_ERROR =  "CLIENT_LOADING_ERROR"
export const CLIENT_ACTIVATE_FAIL =  "CLIENT_ACTIVATE_FAIL"
export const CLIENT_ACTIVATE_SUCCESS =  "CLIENT_ACTIVATE_SUCCESS"
export const CLIENT_UPDATE_SUCCESS =  "CLIENT_UPDATE_SUCCESS"
export const CLIENT_UPDATE_FAIL =  "CLIENT_UPDATE_FAIL"
export const CLIENT_DELETE_SUCCESS =  "CLIENT_DELETE_SUCCESS"
export const CLIENT_DELETE_FAIL =  "CLIENT_DELETE_FAIL"
export const CLIENT_EDIT =  "CLIENT_EDIT"