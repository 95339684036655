import {
    MAINTENANCETRANSACTION_GET_MULTIPLE,
    MAINTENANCETRANSACTION_GET_ONE,
    MAINTENANCETRANSACTION_REGISTER_SUCCESS,
    MAINTENANCETRANSACTION_REGISTER_FAIL,
    MAINTENANCETRANSACTION_LOADING,
    MAINTENANCETRANSACTION_LOADING_ERROR,
    MAINTENANCETRANSACTION_ACTIVATE_FAIL,
    MAINTENANCETRANSACTION_ACTIVATE_SUCCESS,
    MAINTENANCETRANSACTION_UPDATE_SUCCESS,
    MAINTENANCETRANSACTION_UPDATE_FAIL,
    MAINTENANCETRANSACTION_DELETE_SUCCESS,
    MAINTENANCETRANSACTION_DELETE_FAIL,
    MAINTENANCETRANSACTION_SUMMARY
} from "../types/maintenancetransaction";

let maintenancetransactionStore = JSON.parse(localStorage.getItem('maintenancetransaction'))
let maintenancetransactionsummaryStore = JSON.parse(localStorage.getItem('maintenancetransactionsummary'))
const initialState = {
    isLoading: false,
    maintenancetransactions: maintenancetransactionStore ? maintenancetransactionStore : [],
    maintenancetransactionsummary: maintenancetransactionsummaryStore ? maintenancetransactionsummaryStore : [],
    maintenancetransaction:{},
    msg: null,
    isEdit:-1,
    ref:null,
}

const changeState = (aluu, actid) =>
{
    let newMaintenancetransaction = [...aluu];
    newMaintenancetransaction.forEach(alu => {
        if(alu.id == actid.id){
            alu.is_active = actid.is_active
        }
    });
    return newMaintenancetransaction;
}

function dynamicSort(property) {
    var sortOrder = -1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export default function(state = initialState, action){
    switch (action.type) {
        case MAINTENANCETRANSACTION_SUMMARY:
            localStorage.setItem('maintenancetransactionsummary', JSON.stringify(action.payload));
            return {
                ...state,
                maintenancetransactionsummary : action.payload
            };
        case MAINTENANCETRANSACTION_LOADING:
            return {
                ...state,
                isLoading : true
            };
        case MAINTENANCETRANSACTION_GET_MULTIPLE:
            localStorage.setItem('maintenancetransaction', JSON.stringify(action.payload));
            return {
                ...state,
                maintenancetransactions : action.payload.sort(dynamicSort("id")),
                msg:'DONE!!!'
            };
        case MAINTENANCETRANSACTION_GET_ONE:
            let all = [...state.maintenancetransactions];
            let ses = all.filter(row=>row.id == action.payload)[0];
            return {
                ...state,
                maintenancetransaction : ses,
                MSG:"DONE!!!"
            };
        case MAINTENANCETRANSACTION_REGISTER_SUCCESS:
            localStorage.setItem('maintenancetransaction', JSON.stringify([...state.maintenancetransactions, action.payload]));
            let pp = [...state.maintenancetransactions];
            pp.unshift(action.payload)
            return {
                ...state,
                maintenancetransactions: pp.sort(dynamicSort("id")),
                msg:action.msg
            }; 
        case MAINTENANCETRANSACTION_ACTIVATE_SUCCESS:
            let ac = changeState(state.maintenancetransactions, action.payload);
            localStorage.setItem('maintenancetransaction', JSON.stringify(ac));
            return{
                ...state,
                msg:'DONE!!!',
                maintenancetransactions: ac
            }
        case MAINTENANCETRANSACTION_DELETE_SUCCESS:
            let rem = state.maintenancetransactions.filter(cat => cat.id != action.payload);
            localStorage.setItem('maintenancetransaction', JSON.stringify(rem));
            return{
                ...state,
                msg:'DONE!!!',
                maintenancetransactions: rem
            }
        case MAINTENANCETRANSACTION_UPDATE_SUCCESS:
            const findInd = state.maintenancetransactions.findIndex(cat => cat.id == action.payload.id);
            let newState = [...state.maintenancetransactions];
            newState[findInd] = action.payload;
            localStorage.setItem('maintenancetransaction', JSON.stringify(newState));
            return {
                ...state,
                ...action.payload,
                maintenancetransactions : newState,
                maintenancetransaction:action.payload
            }; 
        case MAINTENANCETRANSACTION_LOADING_ERROR:
        case MAINTENANCETRANSACTION_ACTIVATE_FAIL:
        case MAINTENANCETRANSACTION_REGISTER_FAIL:
        case MAINTENANCETRANSACTION_DELETE_FAIL:
        case MAINTENANCETRANSACTION_UPDATE_FAIL:

            return {
                ...state,
                isLoading: false,
                msg: action.msg
            };
        default:
            return state;
    }

}