import React from "react";
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import {getUsertypes, getUsertype, registerUsertype, updateUsertype, deleteUsertype } from './../../actions/usertype';
import FormUserCategory from "views/Form/FormUserCategory";
import FormUserAccess from "views/Form/FormUserAccess";

import $ from "jquery";
import FormUser from "views/Form/FormUser";
import { Link } from "react-router-dom";
import { alllocations } from './../../actions/common';
class BottomCard extends React.Component {
   constructor(props){
       super(props);
       this.state ={
           id:null,
           cat:null,
            grp:null,
            afid:false,
            tfid:false,
            cfid:false,
            mfid:false,
            ifid:false,
            mid:null,
            fid:false,
            data:{},
            subtopic:'All Categories',
            started:'',
            ended:'',
            daterange:'',
            isshown:{}

       }
   }

    componentDidMount(){
        //GET PROPS
        let id = this.props.id;
        let userid = this.props.userid ? parseInt(this.props.userid) : null;
        let username = this.props.user ? parseInt(this.props.username) : '';
        let choicestarted = this.props.choicestarted ? this.props.choicestarted : null;
        let choiceended = this.props.choiceended ? this.props.choiceended : null;
        let started = this.props.defaultstarted ? this.props.defaultstarted : null;
        let ended = this.props.defaultended ? this.props.defaultended : null;
        this.$el = $(this.el);
        if(choicestarted !== null && choiceended !== null && choiceended > choicestarted)
        {
            started = choicestarted;
            ended = choiceended;
        }

        let daterange = ' '+moment(started).format('MMMM Do YYYY') + ' - ' + moment(ended).format('MMMM Do YYYY');
        
        this.setState({
            id:id, 
            cat:id,
            started:started,
            ended:ended,
            daterange:daterange
        });
            
            let catname = this.props.usercategorys.usercategorys && Array.isArray(this.props.usercategorys.usercategorys) && this.props.usercategorys.usercategorys.length > 0 ? this.props.usercategorys.usercategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':'None'}
            //GET ALL CATEGORYS
            let res = {};
            let ctn = '';
        
            let params = {
                data:JSON.stringify(res),
                cat:'userall',
                table:'user_types'
            }
         
         this.setState({subtopic:ctn});
         this.props.getUsertypes(params);

      

    }
    
     componentDidUpdate(prevProps, prevState){
        //GET PROPS
        if(prevProps.id !== this.props.id){
        let id = this.props.id;
        let userid = this.props.userid ? parseInt(this.props.userid) : null;
        let username = this.props.user ? parseInt(this.props.username) : '';
        let choicestarted = this.props.choicestarted ? this.props.choicestarted : null;
        let choiceended = this.props.choiceended ? this.props.choiceended : null;
        let started = this.props.defaultstarted ? this.props.defaultstarted : null;
        let ended = this.props.defaultended ? this.props.defaultended : null;
        this.$el = $(this.el);
        if(choicestarted !== null && choiceended !== null && choiceended > choicestarted)
        {
            started = choicestarted;
            ended = choiceended;
        }

        let daterange = ' '+moment(started).format('MMMM Do YYYY') + ' - ' + moment(ended).format('MMMM Do YYYY');
        
        this.setState({
            id:id, 
            cat:id,
            started:started,
            ended:ended,
            daterange:daterange
        });
            
            let catname = this.props.usercategorys.usercategorys && Array.isArray(this.props.usercategorys.usercategorys) && this.props.usercategorys.usercategorys.length > 0 ? this.props.usercategorys.usercategorys.filter(rw=>parseInt(rw.id) === parseInt(id))[0] : {'name':'None'}
            //GET ALL CATEGORYS
            let res = {};
            let ctn = '';
            
            let params = {
                data:JSON.stringify(res),
                cat:'userall',
                table:'user_types'
            }
         
         this.setState({subtopic:ctn});
         this.props.getUsertypes(params);

           $('#myTablex').DataTable();
         
        }
            

    }
   
    componentWillUnmount(){
        $('#myTablex').DataTable().destroy();
    }
    //EDIT USER
    editForm = (id, dat) =>{
        this.props.getUsertype(id);
        this.setState({fid:true, mid:id});
    }
     //EDIT USER
     addEmp= (id, dat) =>{
        this.setState({fid:true, mid:null});
    }
    //EDIT USER
    accessForm = (id, data) =>{
        this.props.getUsertype(id);
        this.setState({afid:true, mid:id, data:data});
    }
    //DELETE USER
    deleteForm = id =>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You will not be able restore",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
        
            if (result.value) {
                let fd = new FormData();
                fd.append('id', id);
                fd.append('is_delete', 1);
                fd.append('cat', 'update');
                fd.append('table', 'user_types');
                this.props.updateUsertype(fd);
              Swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            }
          })
        
    }
    //ACTIVATE USER
    activateForm = (id, ac) =>{
        let act = ac === 0 ? 1 : 0;
        let fd = new FormData();
        fd.append('id', id);
        fd.append('is_active', act);
        fd.append('cat', 'update');
        fd.append('table', 'user_types');
        this.props.updateUsertype(fd);
    }
   
    loadShown = (id, cat) =>{
          let ar = {};
          if(cat === 0)
          {
              ar[id] = true;
          }else{
              ar[id] = false;
          }
          this.setState({isshown:ar});
    }
    render() {
        let { cfid, afid, mid, fid } = this.state  || '';
        let data = this.props.usertypes.usertypes;
        let tabl = data && Array.isArray(data) ? data.filter(rw=>rw !== null && rw !== undefined).map((prop, ind)=>{
            let fullname = prop.title+" "+prop.surname+" "+prop.firstname+" "+prop.middlename;
            return <tr 
                        key={ind}
                        style={{padding:'1px', margin:'0px'}}
                        onMouseEnter={()=>this.loadShown(prop.id, 0)}
                        onMouseLeave={()=>this.loadShown(prop.id, 1)}
                        >
                        <td className='text-left' style={{padding:'0px'}}>{prop.employment_no}</td>
                        <td className='text-left' style={{textTransform:'capitalize', padding:'0px'}}>
                             {parseInt(prop.is_active) === 0 ? fullname : <strike>{fullname}</strike>}
                        </td>
                        <td className='text-left' style={{padding:'0px'}}>{prop.categoryname}</td>
                        <td className='text-left' style={{padding:'0px'}}>{alllocations[prop.locationid]}</td>
                        <td width="25%" className='text-left' style={{padding:'0px', margin:'0px', textTransform:'capitalize'}} >
                                    <Link className='btn btn-sm btn-outline-primary m-0  '  title='View' to={`/account/userx/${prop.id * 111222333444555}`}><i className='fa fa-eye'></i></Link> 
                                    <a className='btn btn-sm btn-outline-primary m-0  ' onClick={()=>this.accessForm(parseInt(prop.id), prop)} title='Access Level' href='#'><i className='fa fa-key'></i></a>           
                                    <a className={parseInt(prop.is_active) === 0 ? 'btn btn-sm btn-outline-primary m-0 ' : 'btn btn-sm btn-primary m-0 '} onClick={()=>this.activateForm(parseInt(prop.id), parseInt(prop.is_active))} title='Block/Unblock' href='#'>{<i className= {parseInt(prop.is_active) === 0 ? "fa fa-unlock" : "fa fa-lock"}></i>}</a>
                                    <a className='btn btn-sm btn-outline-primary m-0  ' onClick={()=>this.editForm(prop.id, prop)} title='Edit' href='#'><i className='fa fa-edit'></i></a>
                                    <a className='btn btn-sm btn-outline-primary m-0  ' onClick={()=>this.deleteForm(prop.id, prop)} title='Delete' href='#'><i className='now-ui-icons ui-1_simple-remove'></i></a>   
                            </td>
                    </tr>
                }): '';
        


        return (
            <>
            {cfid === true ? 
            <FormUserCategory
                st={cfid}
                mid={mid}
                handleClose={()=>this.setState({mid:null, cfid:false})}
            />:''}
            {afid === true ? 
            <FormUserAccess
                st={afid}
                mid={mid}
                data={this.state.data}
                handleClose={()=>this.setState({mid:null, afid:false})}
            />:''}
            {fid === true ? 
            <FormUser
                st={fid}
                mid={mid}
                data={this.state.data}
                handleClose={()=>this.setState({mid:null, fid:false})}
            />:''}
            
              <div className="content">
                  <div className='card'>
                  <div className='card-header tablecardheader'>
                  <button className='btn btn-sm btn-outline-info' onClick={()=>this.addFilter()}><i className='fa fa-filter'></i> Filter</button>
                  <button className='btn btn-sm btn-info' onClick={()=>this.addEmp()}><i className='fa fa-plus'></i> Add Employee</button>
                  </div>
                  <div className='card-body table-responsive'>
                    <table border="1px" ref={el=>this.el = el} className=''  id='myTable' width='100%' >
                        <thead>
                            <tr>
                                <th style={{maxWidth:'120px'}}>Employm. ID.</th>
                                <th>Fullname</th>
                                <th>Department</th>
                                <th>Location</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tabl}
                        </tbody>
                    </table>
                  </div>
                  </div>
              </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({ 
    user:state.userReducer.user,
    usercategorys:state.usercategoryReducer,
    usertypes:state.usertypeReducer
  })
  
export default connect(mapStateToProps, 
    { 
        getUsertypes,
        getUsertype,
        updateUsertype,
        registerUsertype,
        deleteUsertype
    })(BottomCard)
