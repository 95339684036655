import React from 'react'
import Home from "views/Home.js"
import FeedBack from "views/FeedbackPage.js";
import Room from "views/RoomPage.js";
import Rooms from "views/Room/RoomPages.js";
import RoomHistory from "views/Room/RoomHistory.js";
import RoomReport from "views/Room/RoomReport.js";
import Inventory from "views/InventoryPage.js";
import Inventorys from "views/Inventory/InventoryPages.js";
import InventoryHistory from "views/Inventory/InventoryHistory.js";
import InventoryReport from "views/Inventory/InventoryReport.js";
import Maintenance from "views/MaintenancePage.js";
import Maintenances from "views/Maintenance/MaintenancePages.js";
import MaintenanceHistory from "views/Maintenance/MaintenanceHistory.js";
import MaintenanceReport from "views/Maintenance/MaintenanceReport.js";
import Guest from "views/Guest/GuestPage.js";
import Guests from "views/Guest/GuestPages.js";
import GuestMultiple from "views/Guest/GuestPages.js";
import GuestComment from "views/Guest/GuestComment.js";
import GuestBooking from "views/Guest/GuestBooking.js";
import User from "views/UserPage.js";
import Users from "views/User/UserPages.js";
import UserProfile from "views/User/UserProfile.js";
import UserUser from "views/User/UserUser.js";
import UserLeave from "views/User/UserLeave.js";
import UserLeaves from "views/User/UserLeaves.js";
import UserLog from "views/User/UserLog.js";
import UserLogs from "views/User/UserLogs.js";
import UserJob from "views/User/UserJob.js";
import UserJobs from "views/User/UserJobs.js";

var dashRoutes = [
  {
    path: "/home",
    name: "Home",
    icon: "design_app",
    component: <Home/> ,
    layout: "/account",
    num: 1
  }, {
    path: "/feedback",
    name: "FeedBack",
    icon: "design_app",
    component: <FeedBack/> ,
    layout: "/",
    num: 1
  },{
    path: "/inventory",
    name: "Inventory",
    icon: "design_app",
    component: <Inventory/> ,
    layout: "/account",
    num: 1
  },{
    path: "/inventorys/:id",
    name: "Inventories",
    icon: "design_app",
    component: <Inventorys/> ,
    layout: "/account",
    num: 1
  },{
    path: "/inventoryhistory/:id/:cat",
    name: "Inventory History",
    icon: "design_app",
    component: InventoryHistory ,
    layout: "/account",
    num: 1
  },{
    path: "/inventoryreport/:id/:cat",
    name: "Inventory Report",
    icon: "design_app",
    component: <InventoryReport /> ,
    layout: "/account",
    num: 1
  },{
    path: "/maintenance",
    name: "Maintenance",
    icon: "design_app",
    component: <Maintenance/> ,
    layout: "/account",
    num: 1
  },{
    path: "/maintenances/:id",
    name: "Maintenances",
    icon: "design_app",
    component: <Maintenances/> ,
    layout: "/account",
    num: 1
  },,{
    path: "/maintenancehistory/:id/:cat",
    name: "Maintenance History",
    icon: "design_app",
    component: <MaintenanceHistory/> ,
    layout: "/account",
    num: 1
  },{
    path: "/maintenancereport/:id/:cat",
    name: "Maintenance Report",
    icon: "design_app",
    component: <MaintenanceReport/> ,
    layout: "/account",
    num: 1
  },{
    path: "/guest",
    name: "Guest",
    icon: "design_app",
    component: <Guest />,
    layout: "/account",
    num: 1
  },{
    path: "/guests",
    name: "Guests",
    icon: "design_app",
    component: <Guests/> ,
    layout: "/account",
    num: 1
  },{
    path: "/guestmultiple",
    name: "Guests",
    icon: "design_app",
    component: <GuestMultiple/> ,
    layout: "/account",
    num: 1
  },{
    path: "/guestcomment",
    name: "Guests",
    icon: "design_app",
    component: <GuestComment/> ,
    layout: "/account",
    num: 1
  },{
    path: "/guestbooking",
    name: "Guests",
    icon: "design_app",
    component: <GuestBooking /> ,
    layout: "/account",
    num: 1
  },{
    path: "/room",
    name: "Room",
    icon: "design_app",
    component: <Room/> ,
    layout: "/account",
    num: 1
  },{
    path: "/rooms/:id",
    name: "Rooms",
    icon: "design_app",
    component: <Rooms/> ,
    layout: "/account",
    num: 1
  },{
    path: "/roomhistory/:id/:cat",
    name: "Room History",
    icon: "design_app",
    component: <RoomHistory/> ,
    layout: "/account",
    num: 1
  },{
    path: "/roomreport/:id/:cat",
    name: "Room Report",
    icon: "design_app",
    component: <RoomReport /> ,
    layout: "/account",
    num: 1
  },
   {
    path: "/users",
    name: "Staffs",
    icon: "design_app",
    component: <Users /> ,
    layout: "/account",
    num: 1
  },{
    path: "/userm",
    name: "Profile",
    icon: "design_app",
    component: <UserUser/> ,
    layout: "/account",
    num: 1
  },
  {
    path: "/userx/:id",
    name: "Emplyee Profile",
    icon: "design_app",
    component: UserProfile ,
    layout: "/account",
    num: 1
  }, {
    path: "/userlog/:id",
    name: "Emplyee Log",
    icon: "design_app",
    component: <UserLog /> ,
    layout: "/account",
    num: 1
  },{
    path: "/userlogs",
    name: "Emplyee Log",
    icon: "design_app",
    component: <UserLogs /> ,
    layout: "/account",
    num: 1
  }, {
    path: "/userleave/:id",
    name: "Emplyee Log",
    icon: "design_app",
    component: <UserLeave /> ,
    layout: "/account",
    num: 1
  },{
    path: "/userleaves",
    name: "Emplyee Log",
    icon: "design_app",
    component: <UserLeaves /> ,
    layout: "/account",
    num: 1
  }, {
    path: "/userjob",
    name: "Emplyee Log",
    icon: "design_app",
    component: <UserJob /> ,
    layout: "/account",
    num: 1
  }, {
    path: "/userjobs/:id",
    name: "Emplyee Log",
    icon: "design_app",
    component: <UserJobs /> ,
    layout: "/account",
    num: 1
  },
  {
    path: "/user",
    name: "Staff",
    icon: "design_app",
    component: <User /> ,
    layout: "/account",
    num: 1
  },
  
];
export default dashRoutes;
