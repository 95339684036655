export const USERJOBTRANSACTION_GET_MULTIPLE =  "USERJOBTRANSACTION_GET_MULTIPLE"
export const USERJOBTRANSACTION_GET_ONE =  "USERJOBTRANSACTION_GET_ONE"
export const USERJOBTRANSACTION_REGISTER_SUCCESS =  "USERJOBTRANSACTION_REGISTER_SUCCESS"
export const USERJOBTRANSACTION_REGISTER_FAIL =  "USERJOBTRANSACTION_REGISTER_FAIL"
export const USERJOBTRANSACTION_LOADING =  "USERJOBTRANSACTION_LOADING"
export const USERJOBTRANSACTION_LOADING_ERROR =  "USERJOBTRANSACTION_LOADING_ERROR"
export const USERJOBTRANSACTION_ACTIVATE_FAIL =  "USERJOBTRANSACTION_ACTIVATE_FAIL"
export const USERJOBTRANSACTION_ACTIVATE_SUCCESS =  "USERJOBTRANSACTION_ACTIVATE_SUCCESS"
export const USERJOBTRANSACTION_UPDATE_SUCCESS =  "USERJOBTRANSACTION_UPDATE_SUCCESS"
export const USERJOBTRANSACTION_UPDATE_FAIL =  "USERJOBTRANSACTION_UPDATE_FAIL"
export const USERJOBTRANSACTION_DELETE_SUCCESS =  "USERJOBTRANSACTION_DELETE_SUCCESS"
export const USERJOBTRANSACTION_DELETE_FAIL =  "USERJOBTRANSACTION_DELETE_FAIL"
export const USERJOBTRANSACTION_EDIT =  "USERJOBTRANSACTION_EDIT"