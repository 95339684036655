
import { callError, axiosConfig, SERVER_URL } from 'actions/common'
import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import DataTable from 'react-data-table-component';
import { Button, Container } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBed, faEnvelope, faPencil, faRecycle, faTrash, faUserPen, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import AddGuest from './AddGuest';
import AddGuestCategory from './AddGuestCategory';
import { tableCustomStyles } from 'variables/styles';

const Add = <FontAwesomeIcon icon={faUserPlus} />
const Trash = <FontAwesomeIcon icon={faTrash} />
const Edit = <FontAwesomeIcon icon={faUserPen} />
const Assign = <FontAwesomeIcon icon={faBed} />
const Mail = <FontAwesomeIcon icon={faEnvelope} />
const Reload = <FontAwesomeIcon icon={faRecycle} />




export default function GuestApplied(props) {
    const {staffid, is_admin} = props || null

    const [data, setdata] = useState([])
    const [loading, setloading] = useState(false)
    const [loadingMail, setloadingMail] = useState(false)
    const [row, setrow] = useState({})
    const [isEdit, setisEdit] = useState(false)
    const [modal, setmodal] = useState(false)
    const [isEditCategory, setisEditCategory] = useState(false)
    const [modalCategory, setmodalCategory] = useState(false)

    useEffect(() => load(), [])
    const toggleModal = ()=>setmodal(prev=>!prev)
    const toggleModalCategory = ()=>setmodalCategory(prev=>!prev)

    const load = ()=>{
        setloading(true)
        let params = {
            data:JSON.stringify({

            }),
            cat:'bookings1',
            table:'bookings1',
            token:''
        }
        Axios.get(SERVER_URL+"/index.php", {params}, axiosConfig)
        .then(res=>{
            return res.data
        })
        .then(res=>{
            setdata(res)
            setloading(false)
        })
        .catch(err=>{
            setloading(false)
            callError('Please check your network.')
        })
        
    }
    const send = ()=>{
        setloadingMail(true)
        let params = {
            data:JSON.stringify({

            }),
            cat:'send_booking_mail',
            table:'bookings',
            token:''
        }
        Axios.get(SERVER_URL+"/index.php", {params}, axiosConfig)
        .then(res=>{
            setloadingMail(false)
        })
        .catch(err=>{
            setloadingMail(false)
            callError('Mail Failed')
        })
        
    }
    const add = ()=>{
        setisEdit(false)
        setrow({})
        toggleModal()
    }
    const edit = (rw)=>{
        setisEdit(true)
        setrow(rw)
        toggleModal()
    }
    const set_category = (rw)=>{
        setisEditCategory(true)
        setrow(rw)
        toggleModalCategory()
    }
    const remove = ()=>{
        
    }
  
    const columns = [
        {
            name: 'Date',
            selector: row => row.date_created,
        },
        {
            name: 'fullname',
            selector: row => row.fullname,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Phone',
            selector: row => row.phone,
        },
        {
            name: 'Location',
            selector: row => row.location,
            sort:true
        },
        {
            name: 'Action',
            width:'15%',
            grow:true,
            cell: (row) =>{
                let { dates } = row || '[]'
                let dt = typeof dates == 'string' && Array.isArray(JSON.parse(dates)) ? JSON.parse(dates): []
                return <>
                    <Button className="btn btn-sm" onClick={()=>set_category(row)}>{Assign}{" "}{dt.length}</Button>
                    <span onClick={() => edit(row)} className='btn btn-sm  btn-primary'>{Edit}</span>
                    <span onClick={() => remove(row)} className='btn btn-sm btn-danger'>{Trash}</span>
                </>
            },
        },
        
    ];

    const downloadCSV = ()=>{
    }

    const datas =[
        {fullname:'Bala', email:'emme' }
    ]

    const actionsMemo = React.useMemo(() =>{
        return <>
        
        <Button className="btn btn-sm" onClick={()=>add()}>{Add}</Button>
        <Button className="btn btn-sm" onClick={()=>load()}>{Reload}</Button>
        </>
        }
    , []);
    
  return (
      <>
         {modal ?
        <AddGuest 
            st={modal}
            data={row}
            staffid={staffid}
            isEdit={isEdit}
            setModal={()=>setmodal(prev=>!prev)}
            load={()=>load()}
        />:null}
        <AddGuestCategory 
            st={modalCategory}
            data={row}
            isEdit={isEditCategory}
            load={()=>load()}
        />
        {!loading ?
        <DataTable 
            title="Active Request"  
            columns={columns} 
            data={Array.isArray(data) ? data.filter(rw=>rw.is_approved == 0) : []} 
            actions={actionsMemo}
            loading={loading} 
            sortable
            dense
            filterable
            customStyles={tableCustomStyles}
        />:
        <Container>
            <div className='row'>
                <div className='col'>
                        loading..
                </div>
            </div>
        </Container>
        }
    </>
  )
}
