import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import { validatejson } from "actions/common";

function GuestNavbar(props) {
  const nav = useNavigate()
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [user, setuser] = useState({})
 
  const logOut = async()=>{
      await localStorage.removeItem('userx');
      await localStorage.removeItem('auths');
      nav(process.env.PUBLIC_URL+"/guestmultiple")
  }

  useEffect(() => {
    if(localStorage.getItem('auths')  == 1)
    {
        let f = localStorage.getItem('userx') || '{}'; 
        f = validatejson(f) ? JSON.parse(f) : {}
        if(Array.isArray(Object.keys(f)) && f.hasOwnProperty('id'))
        {
          setuser(f)
        }
        
    }
  },[])
  
  
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar  style={{width:'100%', position:'fixed'}}  className={"fixed-top navbar-info" } expand="lg" color="info">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              href=""
              target="_blank"
              id="navbar-brand"
              className='mainname'
              style={{fontFamily:'Kaushan Script', color:'yellow', fontSize:'1.5em', textTransform:'none'}}
            >
              
              <img
                alt="silhms"
                className="n-logo shadowlogo"
                style={{backgroundColor: 'white', padding:'4px', borderRadius:'50%', border:'1px solid gold'}}
                height='60px'
                 src={process.env.PUBLIC_URL + "/logo.png"} /> {' '}silhms
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              Powered By {process.env.REACT_APP_WEBSITE_NAME}
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                  href={process.env.PUBLIC_URL}
                  
                >
                  <i className="now-ui-icons files_paper"></i>
                  <p>HOME</p>
                </NavLink>
              </NavItem>
              
             {user && user.id > 0 ? 
              <NavItem>
                <NavLink
                  href="/"
                >
                  <i className="now-ui-icons users_single-02"></i>
                  <p>Welcome {user?.fullname} !</p>
                </NavLink>
              </NavItem>:
              <NavItem> 
                <NavLink
                  href="#"
                 
                >
                  <i className="now-ui-icons ui-1_lock-circle-open"></i>
                  <p>Staff Login</p>
                </NavLink>
              </NavItem>}
               {user && user.id > 0  ? 
              <NavItem>
                <NavLink
                  onClick={()=>logOut()}
                >
                  <i className="now-ui-icons ui-1_lock-circle-open"></i>
                  <p>Logout</p>
                </NavLink>
              </NavItem>:''}
             
             
              
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({ 
})
  
export default connect(mapStateToProps, {})(GuestNavbar)