import React from "react";
import { connect } from "react-redux";
import moment from 'moment';
import Swal from "sweetalert2";
import { getUseranalysis } from "./../../actions/usertype";
import $ from 'jquery';
import Chart1 from "./Chart1";
import Chart2 from "./Chart2";
const genders ={
    1:'Male',
    2:'Female'
}
class UserWidget extends React.Component {
    constructor(props){
        super(props);
        this.state ={
          fid:false,
          title:'',
          data:{},
          grp:''
        }
      }

    componentDidMount(){
        let params = {
            data:JSON.stringify({
              'locationid': this.props.user.location
            }),
            cat:'usersta',
            table:'user_types'
        }
        this.props.getUseranalysis(params);
        
      }


      componentDidUpdate(prevProps)
      {
        if(prevProps.user.location !== this.props.user.location){
        let bk = new Date();
        let td = new Date(moment().subtract(3, 'months').calendar());
        let params = {
            data:JSON.stringify({
              'locationid': this.props.user.location
            }),
            cat:'usersta',
            table:'user_types'
        }
        this.props.getUseranalysis(params);
       }
      }

      lunchDate = (func, func1) =>{
        Swal.fire({
            title: 'Pick a date from and to:',
            type: 'question',
            html: '<input id="datepicker" type="date"  class="swal2-input"><br/><input id="datepicker1" type="date"  class="swal2-input">',
            customClass: 'swal2-overflow',
            
          }).then(function(result) {
              if(result.value){
                  let v = $('#datepicker').val();
                  let v1 = $('#datepicker1').val();
                  let params = {
                    data:JSON.stringify({'startdate':moment(new Date(v)).format('YYYY-MM-DD'), 'enddate':moment(new Date(v1)).format('YYYY-MM-DD')}),
                    cat:'mainana',
                    table:'user_types'
                }
                func(params);
                func1(v, v1);
              }
          });
        }
      retState = (dt, dt1) =>{
            this.setState({startdate:new Date(dt), enddate:new Date(dt1)});
      }
        convertdate = (time) =>{
            let d = parseInt(time)/(60 * 60 * 24);
          
            return Number(d).toFixed(6);
        }
    showCategory = (data, start, end) =>{
        this.setState({
            fid:true,
            title:'User Category',
            data:data,
            start:start,
            end:end,
            grp:1
        })
    }
    showPiority = (data, start, end) =>{
        this.setState({
            fid:true,
            title:'User Piority',
            data:data,
            start:start,
            end:end,
            grp:2
        })
    }
    showState = (data, start, end) =>{
        this.setState({
            fid:true,
            title:'User State',
            data:data,
            start:start,
            end:end,
            grp:3
        })
    }

    render() {
        let odata1 = this.props.useranalysis && Array.isArray(this.props.useranalysis) && this.props.useranalysis[0] ? this.props.useranalysis[0] : [] ;
        let odata2 = this.props.useranalysis && Array.isArray(this.props.useranalysis) && this.props.useranalysis[1] ? this.props.useranalysis[1] : [] ;
       

        let er1 = {};
        odata1.forEach(prop=>{
                er1[prop.categoryname] = prop.num;
                return er1;
        });
        let okeyz1 = Object.keys(er1);
        let ovalz1 = Object.values(er1);

        let er2 = {};
        odata2.forEach(prop=>{
                er2[genders[prop.gender]] = prop.num;
                return er2;
        });
        let okeyz2 = Object.keys(er2);
        let ovalz2 = Object.values(er2);

       
        return (
            <>
            
            <div class="row">
                <div class="col-md-4">
                    <div class="card card-stats">
                    <div class="card-header">
                        <h5 class="card-category" ><b>Departments</b></h5>
                    </div>
                        <div class="card-body">
                            <div id="chart" class="chart">
                                <Chart1 label={okeyz1} data={ovalz1} />
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card card-stats">
                    <div class="card-header">
                        <h5 class="card-category"  ><b><i className='fa fa-ellipsis-v'></i>{' '} Gender</b></h5>
                    </div>
                        <div class="card-body">
                            <div id="chart" class="chart">
                                <Chart2 label={okeyz2} data={ovalz2} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card card-stats">
                    <div class="card-header">
                        <h5 class="card-category"  ><b><i className='fa fa-ellipsis-v'></i>{' '} Gender</b></h5>
                    </div>
                        <div class="card-body">
                            <div id="chart" class="chart">
                                <Chart2 label={okeyz2} data={ovalz2} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             </>
        )
    }
}
const mapStateToProps = (state, ownProps) => ({ 
    user:state.userReducer,
    useranalysis:state.usertypeReducer.useranalysis,
  })
  export default connect(mapStateToProps, {getUseranalysis})(UserWidget);