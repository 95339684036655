import React from "react";
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import {getRoomtransactionguest, getRoomtransaction, registerRoomtransaction, updateRoomtransaction, deleteRoomtransaction } from '../../actions/roomtransaction';
import Select  from 'react-select';

import $ from "jquery";
import axios from "axios";
import { callError, API_PATHS, axiosConfig1 } from "actions/common";
import { Col, Row } from "reactstrap";

let path = API_PATHS;
class BottomCard extends React.Component {
   constructor(props){
       super(props);
       this.state ={
           id:null,
           cat:null,
           room:null,
            grp:null,
            afid:false,
            tfid:false,
            cfid:false,
            mfid:false,
            ifid:false,
            mid:null,
            fid:false,
            data:{},
            subtopic:'All Categories',
            started:'',
            ended:'',
            daterange:'',
            isshown:{}

       }
   }

    componentDidMount(){
        //GET PROPS
        let id = this.props.id;
        let dts = new Date();
        let started = new Date(dts.getFullYear(), dts.getMonth(), 1);
        let ended = new Date(dts.getFullYear(), dts.getMonth() + 1, 0);
        
    
        let daterange = ' '+moment(started).format('MMMM Do YYYY') + ' - ' + moment(ended).format('MMMM Do YYYY');
        
        this.setState({
            id:id, 
            cat:id,
            started:started,
            ended:ended,
            daterange:daterange
        });
            
       let dt = {
            'starts':started,
            'ends':ended,
            'locationid':this.props.user.location
        }
        let params = {
            data:JSON.stringify(dt),
            cat:'roomtransactionalpha',
            table:'room_transactions'
        }
         let ctn = daterange;
         this.setState({subtopic:ctn});
         this.props.getRoomtransactionguest(params);
    }
    
    componentDidUpdate(prevProps, prevState){
        //GET PROPS
        if(prevProps.id !== this.props.id || prevProps.user.location !== this.props.user.location ){
        let id = this.props.id;
       let started = this.state.started;
        let ended = this.state.ended;
        
        
        let daterange = ' '+moment(started).format('MMMM Do YYYY') + ' - ' + moment(ended).format('MMMM Do YYYY');
        
        this.setState({
            id:id, 
            cat:id,
            started:started,
            ended:ended,
            daterange:daterange
        });
        let dt = {
            'starts':started,
            'ends':ended,
            'locationid':this.props.user.location
        }
        let params = {
            data:JSON.stringify(dt),
            cat:'roomtransactionalpha',
            table:'room_transactions'
        }
     
     this.setState({subtopic:daterange});
     this.props.getRoomtransactionguest(params);

        $('#myTablex').DataTable();
         
        }
    }
    
   
    componentWillUnmount(){
        $('#myTablex').DataTable().destroy();
    }

    loadShown = (id, cat) =>{
          let ar = {};
          if(cat === 0)
          {
              ar[id] = true;
          }else{
              ar[id] = false;
          }
          this.setState({isshown:ar});
    }
    lunchDate = (func, func1, locs) =>{
        Swal.fire({
            title: 'Pick a date from and to:',
            type: 'question',
            html: '<input id="datepicker" type="date"  class="swal2-input"><br/><input id="datepicker1" type="date"  class="swal2-input">',
            customClass: 'swal2-overflow',
            
          }).then(function(result) {
              if(result.value)
              {
                  let v = $('#datepicker').val();
                  let v1 = $('#datepicker1').val();
                  let params = {
                    data:JSON.stringify(
                    {
                        'starts':moment(new Date(v)).format('YYYY-MM-DD'), 
                        'ends':moment(new Date(v1)).format('YYYY-MM-DD'), 
                        'locationid':locs
                    }),
                    cat:'roomtransactionalpha',
                    table:'room_transaction'
                }
                func(params);
                func1(v, v1);
              }
          });
    }
    retState = (dt, dt1) =>{
        let daterange = ' '+moment(new Date(dt)).format('MMMM Do YYYY') + ' - ' + moment(new Date(dt1)).format('MMMM Do YYYY');
        this.setState({started:new Date(dt), ended:new Date(dt1), daterange:daterange});
        
    }
    sendEmail = (tid, data) =>{
        let fd = new FormData();
        fd.append('id', tid);
        fd.append('fullname', data.fullname);
        fd.append('email', data.email);
        fd.append('locationid', data.locationid);
        fd.append('cat', 'send');
        fd.append('table', 'room_comments');
        fd.append('rowzid', 'send');
        fd.append('locate', this.props.user.locate);
        this.props.registerRoomtransaction(fd);
    }
    sendWhats = (tid, data) =>{
        let fd = new FormData();
        fd.append('tid', tid);
        fd.append('fullname', data.fullname);
        fd.append('channel', data.phone);
        fd.append('locationid', data.locationid);
        fd.append('cat', 'insert');
        fd.append('table', 'room_comments');
        fd.append('rowzid', 'send');
        fd.append('locate', this.props.user.locate);
        axios.post(path, fd, axiosConfig1)
        .then(res => {
            //send whatsapp
            let mm = res.data.data.id;
        })
        .catch(err => {
            //none
            callError(err);
        })
        
    }
    changeRoom = (e) =>{
        this.setState({room:e.value});
    }
    render() {
        let { subtopic, room } = this.state  || '';
        let datax = this.props.roomtransactions.roomtransactionguest;
        let data = [];
        if(room !== null && parseInt(room) > 0)
        {
            data = datax && Array.isArray(datax) ? datax.filter(rw=>rw !== null && rw !== undefined && parseInt(rw.roomid) === parseInt(room)):[];
        }else{
            data = datax;
        }
       
        let tabl = data && Array.isArray(data) ? data.filter(rw=>rw !== null && rw !== undefined).map((prop, ind)=>{
            let arrd = [];
            if(prop !== null || prop !== undefined)
            {
                let rm = prop.endbook !== undefined && prop.endbook !== '' ? prop.endbook.split(',').map((pp, ii)=>{
                let rmm = pp && pp !== undefined && pp !== null && pp !== '' && pp.length > 0 ? pp.split(':::'): [null, null];
                return parseInt(rmm[1]) === 1 ? <b key={ii} className='text-success'>{arrd.push(1)}{moment(rmm[0]).format('DD MMMM YYYY')}{', '}</b>: <i key={ii}  className='text-primary'><b>{moment(rmm[0]).format('DD MMMM YYYY')}</b>{', '}</i>;
                }):'';
            return <tr 
                        key={ind}
                        style={{padding:'1px', margin:'0px'}}
                        >
                        <td className='text-left' style={{textTransform:'capitalize'}}>
                            <Row>
                                <Col xs='1' className='m-auto align-items-center'>
                                    <i className='fa fa-user-circle fa-3x'></i>
                                </Col>
                            <Col xs='11' >
                            <h6>{prop.fullname} </h6>
                            #{prop.trackid}
                           <br/><i className='fa fa-map'></i> {prop.idtype}{" "}({prop.idnumber})
                           <br/><i className='fa fa-bed'></i> {prop.roomname}
                           <br/><i className='fa fa-calendar'></i> {rm}
                           </Col>
                           </Row>
                        </td>
                        <td className='text-left' style={{textTransform:'capitalize'}}>
                            {prop.phone}
                        </td>
                        <td className='text-left' >
                            {prop.email}
                        </td>
                        <td className='text-center' style={{textTransform:'capitalize'}}>
                           <b> {prop.num}/{arrd.length}</b>
                        </td>
                        <td className='text-left' style={{textTransform:'capitalize'}} >
                        <div class="dropdown">
                            <button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-toggle="dropdown">
                                <i className='fa fa-gear'></i> Actions
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" href="#" onClick={()=>this.sendEmail(prop.trackid, prop)}><i className='fa fa-envelope'></i> Send Email</a>
                                <a class="dropdown-item" href="#" onClick={()=>this.SendWhats(prop.trackid)}><i className='fa fab-whatsapp'></i> Send Whatsapp</a>
                                 </div>
                            </div>
                        </td>
                    </tr>}
                }): '';
        
        let options = [];
        if(Array.isArray(this.props.roomtypes.roomtypes)){
            this.props.roomtypes.roomtypes.forEach(pt=>{
                let ar = {};
                ar['value'] = pt.id;
                ar['label'] = pt.categoryname+" "+pt.name;
                return options.push(ar);
            })
        }
        return (
            <>
            
              <div className="content">
                  <div className='card'>
                  <div className='card-header tablecardheader'>
                  <h5>Guest Information</h5>
                      <Row>
                      <Col>{subtopic}</Col>
                      <Col xs='3'>
                      <Select
                            value={room}
                            onChange={this.changeRoom}
                            options={options}
                            autoFocus={true}
                            />
                      </Col>
                      <Col xs='1'>
                      <a class="btn btn-outline-info btn-sm pull-right" onClick={()=>this.lunchDate(this.props.getRoomtransactionguest, this.retState, this.props.user.location)} href="#">
                                <i className="now-ui-icons ui-1_calendar-60"></i>
                      </a>
                     </Col>
                      </Row>
                  </div>
                 
                  <div className='card-body table-responsive'>
                    <table ref={el=>this.el = el} className='table-bordered display wrap table-striped mytables table-condensed table-hover'  id='myTableZ' width='100%' >
                        <thead>
                            <tr>
                                <th>Fullname</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Booking/Lodge</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tabl}
                        </tbody>
                    </table>
                  </div>
                  </div>
              </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({ 
    user:state.userReducer,
    roomcategorys:state.roomcategoryReducer,
    roomtypes:state.roomtypeReducer,
    roomtransactions:state.roomtransactionReducer
  })
  
export default connect(mapStateToProps, 
    { 
        getRoomtransactionguest,
        getRoomtransaction,
        updateRoomtransaction,
        registerRoomtransaction,
        deleteRoomtransaction
    })(BottomCard)
