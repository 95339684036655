
import { callError, axiosConfig, SERVER_URL } from 'actions/common'
import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import DataTable from 'react-data-table-component';
import { Button, Container } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBed, faEnvelope, faPencil, faRecycle, faTrash, faUserPen, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import AddGuest from './AddGuest';
import AddGuestCategory from './AddGuestCategory';
import { tableCustomStyles } from 'variables/styles';
import { validatejson } from 'actions/common';

const Add = <FontAwesomeIcon icon={faUserPlus} />
const Trash = <FontAwesomeIcon icon={faTrash} />
const Edit = <FontAwesomeIcon icon={faUserPen} />
const Assign = <FontAwesomeIcon icon={faBed} />
const Mail = <FontAwesomeIcon icon={faEnvelope} />
const Reload = <FontAwesomeIcon icon={faRecycle} />




export default function GuestApplied(props) {

    const {hist,params: staffid} = props

    const [data, setdata] = useState([])
    const [loading, setloading] = useState(false)
    const [loadingMail, setloadingMail] = useState(false)
    const [row, setrow] = useState({})
    const [isEdit, setisEdit] = useState(false)
    const [modal, setmodal] = useState(false)
    const [isEditCategory, setisEditCategory] = useState(false)
    const [modalCategory, setmodalCategory] = useState(false)
    const [user, setuser] = useState({})
    const {is_admin} = user
    console.log(is_admin)
    useEffect(() => load(), [])
    const toggleModal = ()=>setmodal(prev=>!prev)
   
    const load = ()=>{
        setloading(true)
        let params = {
            data:JSON.stringify({
                    staffid:staffid
            }),
            cat:'bookings5',
            table:'bookings5',
            token:''
        }
        Axios.get(SERVER_URL+"/index.php", {params}, axiosConfig)
        .then(res=>{
            return res.data
        })
        .then(res=>{
            setdata(res)
            setloading(false)
        })
        .catch(err=>{
            setloading(false)
            callError('Please check your network.')
        })
        
    }
    useEffect(() => {
        
        if(localStorage.getItem('auths')  == 1)
        {
            let f = localStorage.getItem('userx') || '{}'; 
            f = validatejson(f) ? JSON.parse(f) : {}
            if(Array.isArray(Object.keys(f)) && f.hasOwnProperty('id'))
            {
                
              setuser(f)
            }
        }
      },[])
   
    const add = ()=>{
        setisEdit(false)
        setrow({})
        toggleModal()
    }
    const edit = (rw)=>{
        setisEdit(true)
        setrow(rw)
        toggleModal()
    }
 
    const remove = ()=>{
        
    }
  
    const columns = [
        {
            name: 'Date',
            selector: row => row.date_created,
        },
        {
            name: 'fullname',
            selector: row => row.fullname,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Phone',
            selector: row => row.phone,
        },
        {
            name: 'Location',
            selector: row => row.location,
            sort:true
        },
        {
            name: 'Action',
            width:'15%',
            grow:true,
            cell: (row) =>{
                let { dates, is_approved } = row || '[]'
                let dt = typeof dates == 'string' && Array.isArray(JSON.parse(dates)) ? JSON.parse(dates): []
                return <>
                    {is_approved == 1 ?
                    <span className='btn btn-sm  btn-success'>Approved</span>:
                    <>
                    <Button className="btn btn-sm">{Assign}{" "}{dt.length}</Button>
                    <span onClick={() => edit(row)} className='btn btn-sm  btn-primary'>{Edit}</span>
                    <span onClick={() => remove(row)} className='btn btn-sm btn-danger'>{Trash}</span>
                    </>}
                </>
            },
        },
        
    ];

    const actionsMemo = React.useMemo(() =>{
        return <>
        <a href="./../guest" className="btn btn-sm btn-danger">{Add} Admin</a>
        <Button className="btn btn-sm" onClick={()=>add()}>{Add}{is_admin} Add</Button>
        <Button className="btn btn-sm" onClick={()=>load()}>{Reload} Refresh</Button>
        </>
        }
    , []);
    
  return (
      <>
      {modal ?
        <AddGuest 
            st={modal}
            data={row}
            staffid={staffid}
            isEdit={isEdit}
            setModal={()=>setmodal(prev=>!prev)}
            load={()=>load()}
        />:null}
        <AddGuestCategory 
            st={modalCategory}
            data={row}
            staffid={staffid}
            isEdit={isEditCategory}
            load={()=>load()}
        />
        {!loading ?
        <DataTable 
            title="Active Request"  
            columns={columns} 
            data={Array.isArray(data) ? data : []} 
            actions={actionsMemo}
            loading={loading} 
            sortable
            dense
            filterable
            customStyles={tableCustomStyles}
        />:
        <Container>
            <div className='row'>
                <div className='col'>
                        loading..
                </div>
            </div>
        </Container>
        }
    </>
  )
}
